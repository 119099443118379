import React, { useState, useEffect } from 'react';
import { Transition } from '@headlessui/react';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/outline';
import { FaTools } from "react-icons/fa";
import { classNames } from '../../../../helpers/classNames';
import Markdown from 'markdown-to-jsx';

// Tool type configuration with friendly names
const TOOL_CONFIG = {
    'internet_search': {
        displayName: 'Web Search',
        has_result: true
    },
    'create_note': {
        displayName: 'Creating Note',
        has_result: false
    },
    'create_memory': {
        displayName: 'Storing Memory',
        has_result: false
    },
    // Default for any other tools
    'default': {
        displayName: 'Using Tool'
    }
};

/**
 * Formats a tool name into a proper display name
 * @param {string} toolName - The raw tool name
 * @returns {string} - The formatted display name
 */
const formatToolName = (toolName) => {
    if (!toolName) return 'Using Tool';

    // Check if we have a predefined name
    if (TOOL_CONFIG[toolName]?.displayName) {
        return TOOL_CONFIG[toolName].displayName;
    }

    // Format the name for better display
    return toolName
        // Split by underscores and remove any 'tool' suffix
        .replace(/_/g, ' ')
        .replace(/([A-Z])/g, ' $1') // Add space before capital letters
        .replace(/\btool\b/i, '')   // Remove "tool" word
        .replace(/\s+/g, ' ')       // Remove extra spaces
        .trim()
        // Capitalize words
        .split(' ')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');
};

/**
 * Component for displaying JSON data in a structured way
 * Supports nested objects, arrays, and renders markdown text
 */
const JSONDisplay = ({ data, level = 0 }) => {
    if (data === null) return <span className="text-gray-500">Empty</span>;
    if (data === undefined) return <span className="text-gray-500">Not provided</span>;

    // Handle primitive types
    if (typeof data !== 'object') {
        // Render strings as markdown if they contain markdown patterns
        if (typeof data === 'string' &&
            (data.includes('**') || data.includes('##') || data.includes('```') ||
                data.includes('*') || data.includes('_') || data.includes('- ') || data.includes('[') ||
                data.includes('|'))) {
            return (
                <div className="markdown-chat">
                    <Markdown options={{ wrapper: "" }}>
                        {data}
                    </Markdown>
                </div>
            );
        }

        // Other primitive types
        if (typeof data === 'string') {
            // For longer strings, add word breaking
            if (data.length > 50) {
                return <span className="text-gray-700 break-all">{data}</span>;
            }
            return <span className="text-gray-700">{data}</span>;
        }
        if (typeof data === 'number') return <span className="text-blue-600 font-medium">{data}</span>;
        if (typeof data === 'boolean') return <span className="text-purple-600 font-medium">{data ? 'Yes' : 'No'}</span>;

        return <span>{String(data)}</span>;
    }

    // Handle arrays and objects
    const isArray = Array.isArray(data);
    const isEmpty = isArray ? data.length === 0 : Object.keys(data).length === 0;

    if (isEmpty) {
        return <span className="text-gray-500 italic">{isArray ? 'Empty list' : 'Empty details'}</span>;
    }

    // Padding/indentation for nested levels (more consistent indentation)
    const indentationWidth = 14; // pixels of indentation per level

    // Format label for better readability
    const formatLabel = (key) => {
        // Convert snake_case to spaces and camelCase to spaces
        return key
            .replace(/_/g, ' ')
            .replace(/([A-Z])/g, ' $1')
            .trim()
            // Capitalize first letter
            .replace(/^\w/, (c) => c.toUpperCase());
    };

    return (
        <div className="relative">
            {/* Type indicator - cleaner and more modern, only at the root level */}
            {level === 0 && (
                <div className="text-xs uppercase tracking-wide text-gray-500 mb-1.5">
                    {isArray ? `List · ${data.length} item${data.length === 1 ? '' : 's'}` :
                        `Object · ${Object.keys(data).length} field${Object.keys(data).length === 1 ? '' : 's'}`}
                </div>
            )}

            <div className={level === 0 ? "max-w-full overflow-x-auto" : ""}>
                {isArray ? (
                    <div className={level > 0 ? "border-l-2 border-gray-100" : ""}>
                        {data.map((item, index) => (
                            <div
                                key={index}
                                className="hover:bg-gray-50/50 rounded"
                                style={{
                                    paddingLeft: level > 0 ? indentationWidth : 0,
                                    marginBottom: 4
                                }}
                            >
                                {level === 0 ? (
                                    <div className="flex items-start">
                                        <div className="text-xs bg-blue-50 text-blue-500 px-1.5 py-0.5 rounded mr-2 font-medium min-w-[24px] text-center">
                                            {index + 1}
                                        </div>
                                        <div className="flex-1">
                                            <JSONDisplay data={item} level={level + 1} />
                                        </div>
                                    </div>
                                ) : (
                                    <JSONDisplay data={item} level={level + 1} />
                                )}
                            </div>
                        ))}
                    </div>
                ) : (
                    <div className={level > 0 ? "border-l-2 border-gray-100" : ""}>
                        {Object.entries(data).map(([key, value]) => (
                            <div
                                key={key}
                                className="hover:bg-gray-50/50 rounded compact-json-row"
                                style={{
                                    paddingLeft: level > 0 ? indentationWidth : 0,
                                    marginBottom: 4
                                }}
                            >
                                <div className="flex flex-col">
                                    <div className="flex items-baseline">
                                        <div className="text-gray-700 font-medium">
                                            {formatLabel(key)}:
                                        </div>
                                        {/* If value is primitive, show inline */}
                                        {typeof value !== 'object' || value === null ? (
                                            <div className="ml-2">
                                                <JSONDisplay data={value} level={level + 1} />
                                            </div>
                                        ) : (
                                            <div className="ml-2 opacity-60 text-xs">
                                                {Array.isArray(value)
                                                    ? `${value.length} item${value.length === 1 ? '' : 's'}`
                                                    : `${Object.keys(value).length} field${Object.keys(value).length === 1 ? '' : 's'}`
                                                }
                                            </div>
                                        )}
                                    </div>
                                    {/* If value is object, show on next line */}
                                    {typeof value === 'object' && value !== null && (
                                        <div className="ml-1 mt-1">
                                            <JSONDisplay data={value} level={level + 1} />
                                        </div>
                                    )}
                                </div>
                            </div>
                        ))}
                    </div>
                )}
            </div>
        </div>
    );
};

/**
 * Renders a tool's input data in a formatted way based on tool type
 */
const ToolInputDisplay = ({ toolType, input, result }) => {
    // Add debugging logs when props change
    useEffect(() => {
        console.log('[ToolInputDisplay] Received props:');
        console.log('  toolType:', toolType);
        console.log('  input:', input);
        console.log('  result:', result);
    }, [toolType, input, result]);

    if (!input) return null;

    // Check if this tool type is configured to display results
    const shouldShowResult = result && (TOOL_CONFIG[toolType]?.has_result !== false);
    console.log('[ToolInputDisplay] shouldShowResult:', shouldShowResult);

    // Helper function to determine if a value is JSON-like (object or array)
    const isJsonLike = (value) => {
        // Try to parse string as JSON if it's a string
        if (typeof value === 'string') {
            try {
                const parsed = JSON.parse(value);
                return parsed !== null &&
                    typeof parsed === 'object' &&
                    (Array.isArray(parsed) || Object.keys(parsed).length > 0);
            } catch (e) {
                return false;
            }
        }

        // Handle direct object values
        return value !== null &&
            typeof value === 'object' &&
            (Array.isArray(value) || Object.keys(value).length > 0);
    };

    // Helper to render result content
    const renderResultContent = (resultData) => {
        console.log('[ToolInputDisplay] renderResultContent called with:', resultData, 'type:', typeof resultData);

        // If undefined or null, return a placeholder
        if (resultData === undefined || resultData === null) {
            console.log('[ToolInputDisplay] Result is null or undefined');
            return <span className="text-gray-500">No result data</span>;
        }

        // If it's already an object, render as JSON directly
        if (typeof resultData === 'object') {
            console.log('[ToolInputDisplay] Rendering direct object as JSONDisplay');
            return <JSONDisplay data={resultData} />;
        }

        // If it's a JSON string, try to parse it
        if (typeof resultData === 'string' && isJsonLike(resultData)) {
            try {
                console.log('[ToolInputDisplay] Attempting to parse JSON string');
                const parsedData = JSON.parse(resultData);
                console.log('[ToolInputDisplay] Successfully parsed JSON:', parsedData);
                return <JSONDisplay data={parsedData} />;
            } catch (e) {
                console.log('[ToolInputDisplay] JSON parsing failed:', e);
                // Fall through to string rendering if parsing fails
            }
        }

        // If it's a string, render as markdown
        if (typeof resultData === 'string') {
            console.log('[ToolInputDisplay] Rendering as markdown string');
            return (
                <div className="markdown-chat">
                    <Markdown options={{ wrapper: "" }}>
                        {resultData}
                    </Markdown>
                </div>
            );
        }

        // For numbers, booleans, etc.
        console.log('[ToolInputDisplay] Rendering as string via String()');
        return String(resultData);
    };

    switch (toolType) {
        case 'internet_search':
            // Extract sources from the result if they exist
            let resultContent = '';
            let sources = [];

            if (shouldShowResult) {
                if (typeof result === 'string') {
                    // Try to extract sources from the text using regex
                    const resultText = result;
                    const sourcesMatch = resultText.match(/Sources:(.+)$/s);

                    if (sourcesMatch) {
                        // Split the result into content and sources
                        resultContent = resultText.substring(0, resultText.indexOf('Sources:'));
                        // Extract sources from the formatted text
                        const sourcesText = sourcesMatch[1].trim();
                        // Extract URLs from sources section - looking for numbered format like [1] https://...
                        sources = sourcesText.match(/\[\d+\]\s+https?:\/\/[^\s\n]+/g) || [];
                    } else {
                        resultContent = resultText;
                    }
                } else if (typeof result === 'object' && result.content) {
                    // Same extraction for object with content property
                    const resultText = result.content;
                    const sourcesMatch = resultText.match(/Sources:(.+)$/s);

                    if (sourcesMatch) {
                        resultContent = resultText.substring(0, resultText.indexOf('Sources:'));
                        const sourcesText = sourcesMatch[1].trim();
                        sources = sourcesText.match(/\[\d+\]\s+https?:\/\/[^\s\n]+/g) || [];
                    } else {
                        resultContent = resultText;
                    }
                }
            }

            return (
                <div className="text-gray-800">
                    <div className="rounded-md bg-white p-4 border border-gray-200 shadow-sm max-w-full overflow-x-auto">
                        <div className="text-sm font-medium text-gray-500 mb-3">Search query</div>
                        <div className="pl-1 font-medium">
                            {input?.queries ? (
                                input.queries.map(query => query.query).join(', ')
                            ) : (
                                typeof input === 'object' ?
                                    <JSONDisplay data={input} /> :
                                    input
                            )}
                        </div>
                    </div>

                    {shouldShowResult && (
                        <div className="mt-3">
                            <div className="rounded-md bg-white p-4 border border-gray-200 shadow-sm max-w-full">
                                <div className="text-sm font-medium text-gray-500 mb-3">Search results</div>
                                <div className="max-h-96 overflow-y-auto custom-scrollbar-thick custom-scrollbar-gray pl-3 border-l-2 border-gray-100 markdown-chat overflow-x-auto">
                                    {resultContent ? (
                                        // Use renderResultContent to properly handle different result formats
                                        renderResultContent(resultContent)
                                    ) : (
                                        // Fallback for older format
                                        <Markdown options={{ wrapper: "" }}>
                                            {typeof result === 'string' ? result :
                                                typeof result === 'object' && result.content ? result.content :
                                                    JSON.stringify(result, null, 2)}
                                        </Markdown>
                                    )}

                                    {sources.length > 0 && (
                                        <div className="mt-4 pt-4 border-t border-gray-100">
                                            <div className="text-xs uppercase tracking-wide text-gray-500 mb-2">Sources</div>
                                            <div className="grid grid-cols-1 md:grid-cols-2 gap-2">
                                                {sources.map((source, index) => {
                                                    // Extract just the URL from the source text
                                                    const url = source.replace(/\[\d+\]\s+/, '').trim();
                                                    // Extract domain for display
                                                    let domain = url;
                                                    try {
                                                        domain = new URL(url).hostname.replace(/^www\./, '');
                                                    } catch (e) {
                                                        // Use the full URL if parsing fails
                                                    }

                                                    return (
                                                        <a
                                                            key={index}
                                                            href={url}
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                            className="flex items-center p-2 rounded hover:bg-gray-50 border border-gray-200 text-blue-600 hover:text-blue-700 transition-colors"
                                                        >
                                                            <span className="text-xs bg-blue-50 text-blue-500 px-1.5 py-0.5 rounded mr-2 font-medium min-w-[24px] text-center">
                                                                {index + 1}
                                                            </span>
                                                            <span className="truncate">{domain}</span>
                                                        </a>
                                                    );
                                                })}
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            );

        case 'create_note':
            return (
                <div className="text-gray-800">
                    <div className="rounded-md bg-white p-4 border border-gray-200 shadow-sm max-w-full overflow-x-auto">
                        <div className="text-sm font-medium text-gray-500 mb-3">Added note</div>
                        <div className="max-h-96 overflow-y-auto custom-scrollbar-thick custom-scrollbar-gray pl-3 border-l-2 border-gray-100 markdown-chat">
                            {input && input.content ? (
                                typeof input.content === 'object' ?
                                    <JSONDisplay data={input.content} /> :
                                    <Markdown options={{ wrapper: "" }}>
                                        {input.content}
                                    </Markdown>
                            ) : (
                                <span className="text-gray-500">No content provided</span>
                            )}
                            <div className="flex items-center mt-3 text-green-600">
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mr-1.5" viewBox="0 0 20 20" fill="currentColor">
                                    <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd" />
                                </svg>
                                <span className="font-medium">Note saved</span>
                            </div>
                        </div>
                    </div>
                </div>
            );

        case 'create_memory':
            return (
                <div className="text-gray-800">
                    <div className="rounded-md bg-white p-4 border border-gray-200 shadow-sm max-w-full overflow-x-auto">
                        <div className="text-sm font-medium text-gray-500 mb-3">Stored in memory</div>
                        <div className="max-h-96 overflow-y-auto custom-scrollbar-thick custom-scrollbar-gray pl-3 border-l-2 border-gray-100 markdown-chat">
                            {input && input.content ? (
                                typeof input.content === 'object' ?
                                    <JSONDisplay data={input.content} /> :
                                    <Markdown options={{ wrapper: "" }}>
                                        {input.content}
                                    </Markdown>
                            ) : (
                                <span className="text-gray-500">No content provided</span>
                            )}
                            <div className="flex items-center mt-3 text-green-600">
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mr-1.5" viewBox="0 0 20 20" fill="currentColor">
                                    <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd" />
                                </svg>
                                <span className="font-medium">Memory saved</span>
                            </div>
                        </div>
                    </div>
                </div>
            );

        default:
            // Format label for field to display the tool name in a standard way
            const toolLabel = toolType === 'default' ? 'Information' : formatToolName(toolType);

            return (
                <div className="text-gray-800">
                    <div className="rounded-md bg-white p-4 border border-gray-200 shadow-sm max-w-full overflow-x-auto">
                        <div className="text-sm font-medium text-gray-500 mb-3">{toolLabel}</div>
                        <div className="pl-0">
                            <JSONDisplay data={input} />
                        </div>
                    </div>
                    {shouldShowResult && (
                        <div className="mt-3">
                            <div className="rounded-md bg-white p-4 border border-gray-200 shadow-sm max-w-full overflow-x-auto">
                                <div className="text-sm font-medium text-gray-500 mb-3">Result</div>
                                <div className="pl-0">
                                    {renderResultContent(result)}
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            );
    }
};

/**
 * Component to display AI tool usage in a collapsible panel
 * @param {Object} props 
 * @param {Object} props.toolUse - Tool use data object with name, input, and optional result
 * @param {Function} props.onToggle - Optional callback for when panel is expanded/collapsed
 */
const ToolUseDisplay = ({ toolUse, onToggle }) => {
    const [isExpanded, setIsExpanded] = useState(false);


    // Trigger scroll adjustment when expanded state changes
    useEffect(() => {
        if (onToggle && typeof onToggle === 'function') {
            onToggle(isExpanded);
        }

        // Force a scroll adjustment when display changes visibility
        if (isExpanded) {
            setTimeout(() => {
                try {
                    const scrollable = document.querySelector('[data-scroll-container]');
                    if (scrollable) {
                        const { scrollTop, scrollHeight, clientHeight } = scrollable;
                        const nearBottom = scrollHeight - scrollTop - clientHeight < 200;

                        if (nearBottom) {
                            scrollable.scrollTop = scrollable.scrollHeight;
                        }
                    }
                } catch (error) {
                    console.log('Scroll error in ToolUseDisplay:', error);
                }
            }, 100);
        }
    }, [isExpanded, onToggle]);

    // Only show the tool use UI when there is actual tool usage data
    if (!toolUse || !toolUse.name) {
        console.log('[ToolUseDisplay] No toolUse or toolUse.name, not rendering');
        return null;
    }

    const toggleExpanded = () => {
        setIsExpanded(!isExpanded);
    };

    // Get display name from the toolUse object or from config
    const displayName = toolUse.display_name ||
        formatToolName(toolUse.name);

    // Extract the actual result content for display, considering the has_result configuration
    const hasResultConfig = TOOL_CONFIG[toolUse.name]?.has_result !== false; // Default to true if not specified

    // Check if we have a result in the toolUse object - handle different result formats
    const hasResult = !!(
        // Check if result exists in one of the possible formats
        (toolUse.result && (
            // Direct content property on result
            toolUse.result.content ||
            // Or as result property (nested structure)
            (toolUse.result.result && (typeof toolUse.result.result === 'object' || typeof toolUse.result.result === 'string')) ||
            // Or as a string/primitive
            (typeof toolUse.result === 'string' || typeof toolUse.result === 'number')
        ))
    );


    // Extract the actual result content for display, considering the has_result configuration
    const resultContent = (hasResult && hasResultConfig) ? (
        // Handle different result formats
        (toolUse.result && typeof toolUse.result.content === 'string') ? toolUse.result.content :
            (toolUse.result && toolUse.result.result) ? toolUse.result.result :
                // Direct access to result object if it's a string or number
                (typeof toolUse.result === 'string' || typeof toolUse.result === 'number') ? toolUse.result :
                    // Direct access result as an object if it's not a complex structure
                    (toolUse.result && typeof toolUse.result === 'object' && !toolUse.result.type) ?
                        toolUse.result : null
    ) : null;


    return (
        <div className="flex justify-start">
            <div className="my-3 rounded-lg border border-gray-200 bg-white shadow-sm overflow-auto max-w-lg ">
                <div
                    className={classNames(
                        "flex items-center justify-start cursor-pointer p-2.5 hover:bg-gray-50"
                    )}
                    onClick={toggleExpanded}
                >
                    <div className="flex items-center">
                        <div className="bg-blue-50 p-1.5 rounded-md mr-2">
                            <FaTools className="h-4 w-4 text-blue-500" />
                        </div>
                        <div className={classNames(
                            "text-base text-gray-800 font-medium",
                            !hasResult && "text-blue-500"
                        )}>
                            {displayName}

                        </div>
                    </div>
                    <div className={classNames(
                        "flex items-center", isExpanded ? 'ml-auto' : 'ml-3'

                    )}>
                        {isExpanded ? (
                            <ChevronUpIcon className="h-5 w-5 text-gray-400" />
                        ) : (
                            <ChevronDownIcon className="h-5 w-5 text-gray-400" />
                        )}
                    </div>
                </div>

                <Transition
                    show={isExpanded}
                    enter="transition duration-100 ease-out"
                    enterFrom="transform scale-98 opacity-0"
                    enterTo="transform scale-100 opacity-100"
                    leave="transition duration-75 ease-out"
                    leaveFrom="transform scale-100 opacity-100"
                    leaveTo="transform scale-98 opacity-0"
                >
                    <div className="p-3 bg-gray-50 border-t border-gray-200 w-full">
                        <ToolInputDisplay
                            toolType={toolUse.name}
                            input={toolUse.input}
                            result={resultContent}
                        />
                    </div>
                </Transition>
            </div>
        </div>
    );
};

export default ToolUseDisplay; 