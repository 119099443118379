import React, { useState } from 'react'
import { IoMdSend } from 'react-icons/io';
import { useMutation, useQueryClient } from 'react-query';
import { TrashIcon, DuplicateIcon, LockClosedIcon } from '@heroicons/react/outline';
import { API_AUTH_TYPES, API_ROUTES, ApiRequest } from '../../../../../../../api';
import { classNames } from '../../../../../../../helpers/classNames';
import ItemMenu from '../../../../../../../components/menus/ItemMenu';
import FadeIn from '../../../../../../../components/transitions/FadeIn';
import RichTextDisplay from '../../../../../../../components/textEditor/RichTextDisplay';

const TemplateItem = ({ template, emailId, toggleAcceptChange }) => {
    const [isOpen, setIsOpen] = useState(false);

    const queryClient = useQueryClient()

    const handleOpenClick = () => {
        setIsOpen(!isOpen);
    }

    const bunmailApi = new ApiRequest(API_ROUTES.BUNMAIL, API_AUTH_TYPES.ADVISOR)

    const { mutate: archiveTemplate, isLoading: deleteLoading } = useMutation(async () => bunmailApi.patch({
        endpoint: 'archive_template',
        params: [template.id]
    }),
        {
            onSuccess: () => {
                setIsOpen(false);
                queryClient.invalidateQueries('email-templates')
            },
            onError: (error) => {
                console.error('Error archiving template:', error)
            },
        })

    const { mutate: duplicateAsPrivate } = useMutation(async () => bunmailApi.post({
        endpoint: 'template',
        body: {
            title: `${template.title} (Private Copy)`,
            content: template.content,
            subject: template.subject,
            isPrivate: true
        },
    }), {
        onSuccess: () => {
            queryClient.invalidateQueries('email-templates')
        },
        onError: (error) => {
            console.error('Error duplicating template:', error)
        },
    })

    const useTemplateMutation = useMutation(async () => bunmailApi.patch({
        endpoint: 'use_template',
        body: {
            template_id: template.id,
            email_id: emailId
        }
    }),
        {
            onMutate: () => {
                toggleAcceptChange()
                queryClient.setQueryData(['email', emailId], (oldData) => {
                    return {
                        ...oldData,
                        content: template.content,
                        subject: template.subject
                    }
                }
                )
                queryClient.setQueryData(['email-quill-editor', emailId], (oldData) => {
                    return {
                        id: emailId, content: template.content,
                    }
                })
            },

            onSuccess: () => {
                queryClient.invalidateQueries('email', emailId);
                queryClient.invalidateQueries('email-quill-editor', emailId);
            }
        }
    )

    const options = [
        // Only show duplicate as private for public templates
        ...(!template.user_id ? [{
            title: 'Duplicate as Private',
            icon: DuplicateIcon,
            action: duplicateAsPrivate,
        }] : []),
        {
            title: 'Delete',
            action: archiveTemplate,
            icon: TrashIcon
        },
    ]

    return (
        <li
            className={classNames(isOpen ? 'text-gray-900 bg-gray-100' : 'text-gray-700 hover:bg-gray-50 hover:text-gray-900')}
        >
            <div
                className={classNames(
                    '',
                    'text-sm w-full text-left flex px-1',
                )} >

                <button
                    onClick={handleOpenClick}
                    className="w-full text-left py-2 text-base font-semibold items-center space-x-2 flex">
                    <div className="flex items-center space-x-2">
                        <p>{template.title}</p>
                      
                    </div>
                </button>
                <div className='mr-4 my-auto'><ItemMenu options={options} /></div>

                <div className="flex items-center">
                    <button onClick={() => useTemplateMutation.mutate()} className='btn-sm btn-primary'>
                        Insert
                    </button>
                </div>
            </div>

            <FadeIn isShowing={isOpen}>
                <div className="p-1 max-h-[250px] overflow-y-auto">
                    <p className='flex text-base font-semibold'>
                        Subject: {template.subject}
                    </p>
                    <p className='flex text-base font-semibold'>
                        Body:
                    </p>
                    <RichTextDisplay
                        content={template.content}
                        thisClassName="notes"
                    />
                </div>
            </FadeIn>
        </li>
    )
}

export default TemplateItem