import React from "react";

import QuillTextEditor from "../../../../../components/textEditor/Quill-Text-Editor/index";


const NewNote = ({ householdId, listQueryKey, handleSubmitOnSuccess = () => { } }) => {

  const options = {
    is_draft: true,
    route: "household_notes",
    search_params: {
      household_id: householdId,
    },
  };
  const queryKey = `note-draft-${householdId}`;

  return (
    <div>
      <QuillTextEditor
        placeholder="Create New Note"
        options={options}
        queryKey={queryKey}
        listQueryKey={listQueryKey}
        handleSubmitOnSuccess={handleSubmitOnSuccess}
      />
    </div>
  );
};

export default NewNote;
