import { useEffect, useRef } from 'react';

const useScrollToBottom = (monitoredArray, tolerance = 75) => {
    const scrollRef = useRef(null); // Ref for the scrollable container
    const observerRef = useRef(null); // Ref for the MutationObserver

    // Check if the user is at the bottom of the scrollable container
    const isUserAtBottom = () => {
        if (!scrollRef.current) {
            return false;
        }

        const { scrollTop, scrollHeight, clientHeight } = scrollRef.current;
        const bottomOffset = scrollHeight - scrollTop - clientHeight;
        return bottomOffset <= tolerance;
    };

    // A simple direct scroll to bottom function
    const hardScrollToBottom = () => {
        if (!scrollRef.current) return;

        // Immediate scroll
        scrollRef.current.scrollTop = scrollRef.current.scrollHeight;

        // Delayed backup scroll for safety
        setTimeout(() => {
            if (scrollRef.current) {
                scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
            }
        }, 100);
    };

    // Clean up the observer when the component unmounts
    useEffect(() => {
        return () => {
            if (observerRef.current) {
                observerRef.current.disconnect();
                observerRef.current = null;
            }
        };
    }, []);

    // Return a simplified interface that just provides the ref and direct scroll function
    return { scrollRef, hardScrollToBottom };
};

export default useScrollToBottom;
