import React from 'react'
import { AiOutlineLoading3Quarters } from 'react-icons/ai'

const DefaultLoading = ({
  size = 'md',
  showText = true,
  textColor = 'text-gray-600',
  className = ''
}) => {
  const sizeClasses = {
    sm: 'h-4 w-4',
    md: 'h-5 w-5',
    lg: 'h-6 w-6',
    xl: 'h-8 w-8'
  }

  return (
    <div className={`flex flex-col items-center justify-center space-y-2 ${className}`}>
      <AiOutlineLoading3Quarters
        className={`${sizeClasses[size]} ${textColor} animate-spin`}
      />
      {showText && (
        <span className={`text-sm font-medium ${textColor}`}>
          Loading...
        </span>
      )}
    </div>
  )
}

export default DefaultLoading