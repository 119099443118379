import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { useQuery } from "react-query";
import { useState } from "react";
import { CalendarIcon } from "@heroicons/react/outline";
import BasicPanel from "../../../../components/panels/BasicPanel";
import { API_AUTH_TYPES, API_ROUTES, ApiRequest } from "../../../../api";
import Row from "./Row";
import MeetingDetails from "./MeetingDetails";

const meetingsApi = new ApiRequest(API_ROUTES.CLIENT_MEETINGS, API_AUTH_TYPES.ADVISOR);

export default function HHMeetings() {
    // Hooks, state, and functions
    const { household_id: householdId } = useParams();
    const [selectedMeeting, setSelectedMeeting] = useState(null);
    const [showMeetingDetails, setShowMeetingDetails] = useState(false);

    const { data: meetings, isLoading, isError } = useQuery({
        queryKey: `meetings-${householdId}`,
        queryFn: meetingsApi.getFn({ endpoint: 'index_household', params: [householdId] })
    });

    if (isLoading) return (<div>Loading...</div>);

    // Add meeting_type if not present
    const processedMeetings = meetings?.map(meeting => {
        if (!meeting.meeting_type) {
            // Try to extract meeting type from the name if possible
            const meetingType = meeting.name?.includes("Check In") ? "Check In" :
                meeting.name?.includes("Follow Up") ? "Follow Up" :
                    "Consultation";
            return { ...meeting, meeting_type: meetingType };
        }
        return meeting;
    });

    // Find the next upcoming meeting (if any)
    const nextMeeting = processedMeetings && processedMeetings.length > 0
        ? processedMeetings
            .filter(m => m.status === 'active')
            .sort((a, b) => new Date(a.start_time) - new Date(b.start_time))
            .find(m => new Date(m.start_time) >= new Date())
        : null;

    const handleOpenMeetingDetails = (meeting) => {
        setSelectedMeeting(meeting);
        setShowMeetingDetails(true);
    };

    return (
        // Rendering the basic panel component
        <BasicPanel>
            <div className="mb-5">
                <h5 className="crm-title">Meetings</h5>
                {nextMeeting && (
                    <button
                        onClick={() => handleOpenMeetingDetails(nextMeeting)}
                        className="inline-flex items-center mt-4 px-3 py-2 border border-blue-300 text-sm leading-4 font-medium rounded-md text-blue-700 bg-blue-50 hover:bg-blue-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                    >
                        <CalendarIcon className="h-5 w-5 mr-2 text-blue-500" />
                     Upcoming Meeting: {nextMeeting?.name || 'Next Meeting'}
                    </button>
                )}
            </div>
            <div className="mt-8 flow-root">
                <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                        <table className="min-w-full divide-y divide-gray-300">
                            <thead>
                                <tr>
                                    {/* Table headers */}
                                    <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">
                                        Date
                                    </th>
                                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 hidden md:table-cell">
                                        Type
                                    </th>
                                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 hidden md:table-cell">
                                        Meeting Reason
                                    </th>
                                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                        Status
                                    </th>
                                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                        Prep Completed
                                    </th>
                                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"></th>
                                </tr>
                            </thead>
                            <tbody className="divide-y divide-gray-200">
                                {/* Rendering each meeting */}
                                {processedMeetings && processedMeetings.map((meeting) => (
                                    <tr key={meeting.meeting_id}>
                                        {meeting.status && <Row meeting={meeting} />}
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

            {/* Meeting details modal */}
            {showMeetingDetails && selectedMeeting && (
                <MeetingDetails
                    meeting={selectedMeeting}
                    onClose={() => setShowMeetingDetails(false)}
                  
                />
            )}
        </BasicPanel>
    );
} 