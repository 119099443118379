import React, { useState, useRef, useCallback } from "react";

import { useEffect } from "react";
import socketIoConnection from "../../../socketIoConnect";

import { SiChatbot } from "react-icons/si";
import { v4 as uuidv4 } from "uuid";
import { classNames } from "../../../helpers/classNames";
import AlertError from "../../alerts/AlertError";
import { useParams, useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { LightningBoltIcon, AcademicCapIcon } from "@heroicons/react/outline";
import useScrollToBottom from "../../../hooks/useScrollToBottom";

import Modal from "./components/Modal";

import { SocketContext } from "./components/Message";
import Conversations from "./components/Conversations";
import { useQuery, useQueryClient } from "react-query";
import TextBox from "./components/TextBox";
import ExpandedImage from "./components/ExpandedImage";
import Memory from "./components/Memory";
import MessageGroup from './components/MessageGroup';
import LoadingIndicator from './components/LoadingIndicator';
import Unread from './components/Unread';
import { API_AUTH_TYPES, API_ROUTES, ApiRequest } from "../../../api";

// Tool type configuration with friendly names
const TOOL_CONFIG = {
  'internet_search': {
    displayName: 'Web Search'
  },
  'create_note': {
    displayName: 'Creating Note'
  },
  'create_memory': {
    displayName: 'Storing Memory'
  },
  // Default for any other tools
  'default': {
    displayName: 'Using Tool'
  }
};

const models = [
  { id: 'quicker', name: "Quicker", icon: LightningBoltIcon },
  { id: 'smarter', name: "Smarter", icon: AcademicCapIcon },
];

// Add a function to group messages by role
const groupMessagesByRole = (messageIds, initialMessageData) => {
  if (!messageIds || messageIds.length === 0) return [];

  const groups = [];
  let currentGroup = [];
  let currentRole = initialMessageData[messageIds[0]]?.role;

  messageIds.forEach(messageId => {
    const message = initialMessageData[messageId];
    if (!message) return;

    if (message.role !== currentRole) {
      // Role changed, start a new group
      if (currentGroup.length > 0) {
        groups.push(currentGroup);
      }
      currentGroup = [messageId];
      currentRole = message.role;
    } else {
      // Same role, add to current group
      currentGroup.push(messageId);
    }
  });

  // Add the last group
  if (currentGroup.length > 0) {
    groups.push(currentGroup);
  }

  return groups;
};

const ParabotInterface = () => {

  const [info, setInfo] = useState({ location: window.location.pathname, user_time_zone: Intl.DateTimeFormat().resolvedOptions().timeZone })
  // Update location whenever the URL changes
  useEffect(() => {
    setInfo({ ...info, location: window.location.pathname })
  }, [window.location.pathname]); // This will re-run whenever the URL changes

  const [files, setFiles] = useState([]);
  const [query, setQuery] = useState("");
  const [runType, setRunType] = useState(models[0].id)
  const [thinkingMode, setThinkingMode] = useState(() => {
    // Initialize from localStorage or default to 'auto'
    return localStorage.getItem('parabotThinkingMode') || 'auto';
  });
  const [loading, setLoading] = useState(false);
  const [conversationId, setConversationId] = useState(null);
  const [unreadCount, setUnreadCount] = useState(0); // New state for tracking unread messages

  // Use refs to persist message data across rerenders and prevent data loss
  const messageIdsRef = useRef([]);
  const initialMessageDataRef = useRef({});

  // State for rendering, will be updated from refs
  const [messageIds, setMessageIds] = useState([]);
  const [initialMessageData, setInitialMessageData] = useState({});

  // Use a ref for current assistant message ID to avoid re-renders
  const currentAssistantMessageIdRef = useRef(null);
  const socketRef = useRef(null); // Use a ref for the socket to prevent recreating it
  const [isOpen, setIsOpen] = useState(false);
  const [error, setError] = useState(null);
  const queryClient = useQueryClient();
  const textAreaRef = useRef(null);
  const scrollContainerRef = useRef(null); // Direct reference to the scroll container
  const { scrollRef, hardScrollToBottom } = useScrollToBottom(messageIds, 35);
  const [expandedImageUrl, setExpandedImageUrl] = useState(null)

  // Add a new ref at the top of your component
  const pendingToolUsesRef = useRef(new Set());

  // Function to directly force scroll to bottom - backup approach
  const forceScrollToBottom = useCallback(() => {
    // First try using the scrollContainerRef (direct access)
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollTop = scrollContainerRef.current.scrollHeight;
    }

    // Then try the scrollRef from the hook as backup
    if (scrollRef.current) {
      scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
    }

    // As a final backup, find the element by a valid selector
    const scrollElement = document.querySelector('[data-scroll-container]');
    if (scrollElement) {
      scrollElement.scrollTop = scrollElement.scrollHeight;
    }
  }, [scrollRef]);

  // Schedule multiple scroll attempts with increasing delays
  const scheduleMultipleScrolls = useCallback(() => {
    // Immediate scroll
    forceScrollToBottom();

    // Schedule delayed scrolls to catch any dynamic content
    // More frequent early checks plus later checks for slow-loading content
    [20, 50, 80, 150, 250, 400, 700, 1000].forEach(delay => {
      setTimeout(forceScrollToBottom, delay);
    });
  }, [forceScrollToBottom]);

  // Safe updater functions that update both refs and state
  const updateMessageIds = useCallback((newIds) => {
    // Update ref first
    messageIdsRef.current = newIds;
    // Then update state for rendering
    setMessageIds(newIds);
    console.log("Message IDs updated, count:", newIds.length);
  }, []);

  const updateInitialMessageData = useCallback((newData) => {
    // Update ref first
    initialMessageDataRef.current = newData;
    // Then update state for rendering 
    setInitialMessageData(newData);
    console.log("Message data updated, keys:", Object.keys(newData).length);
  }, []);

  // Clear conversation function that clears both refs and states
  const clearConversation = useCallback(() => {
    messageIdsRef.current = [];
    initialMessageDataRef.current = {};
    setMessageIds([]);
    setInitialMessageData({});
    console.log("Conversation cleared");
  }, []);

  // Prevent unintended message loss when the conversation ID invalidation happens
  // This function safely updates the conversation ID without losing message data
  const safeSetConversationId = useCallback((id) => {
    // Only clear messages if this is a completely new conversation
    if (id !== conversationId) {
      console.log(`Conversation ID changing from ${conversationId} to ${id}`);
      if (id) {
        // If there's a new conversation ID, we'll fetch its messages
        console.log("Will fetch new conversation data");
      } else {
        // If setting to null, clear the conversation
        clearConversation();
      }
    }
    setConversationId(id);
  }, [conversationId, clearConversation]);

  const parabotApi = new ApiRequest(API_ROUTES.PARABOT, API_AUTH_TYPES.ADVISOR)
  // Fetch conversation IDs
  const { data } = useQuery({
    queryFn: parabotApi.getFn({
      endpoint: 'index_conversation_ids',
    }),
    queryKey: 'parabot_conversation_ids',
  })
  const conversationIds = data?.ids || []
  const unreadCountFromServer = data?.unreadCount || 0
  const unreadConversations = data?.unreadConversations || [] // Get unread conversation IDs from API

  // Update unread count from server data
  useEffect(() => {
    if (unreadCountFromServer) {
      setUnreadCount(unreadCountFromServer);
    }
  }, [unreadCountFromServer])

  // Auto-open interface to the first unread conversation when there are unread messages
  useEffect(() => {
    // Check if we have unread conversations and the interface is not already open
    if (unreadConversations.length > 0 && !isOpen && unreadCount > 0) {
      console.log("Opening to unread conversation", unreadConversations[0]);

      // Select the first unread conversation
      safeSetConversationId(unreadConversations[0]);
    }
  }, [unreadConversations, isOpen, unreadCount, safeSetConversationId]);

  // Create socket connection only once and persist it
  useEffect(() => {
    if (socketRef.current) return; // Only create the socket once

    console.log("Creating new socket connection");
    const newSocket = socketIoConnection("parabot");
    socketRef.current = newSocket;

    // Make sure the socket is accessible for rendering
    setSocket(newSocket);

    // Clean up on component unmount
    return () => {
      if (socketRef.current) {
        console.log("Disconnecting socket");
        socketRef.current.disconnect();
        socketRef.current = null;
      }
    };
  }, []);

  // Use socket from ref for state
  const [socket, setSocket] = useState(null);

  // Create callback handlers for socket events that need safeSetConversationId
  const handleNewMessage = useCallback((data) => {
    console.log("Received new message notification", data);

    // Only increment if the interface is closed
    if (!isOpen) {
      setUnreadCount(prev => prev + 1);

      // Invalidate the conversation IDs query to get updated unread data
      queryClient.invalidateQueries('parabot_conversation_ids');

      // The conversation_id in the data is the one with the new message
      if (data && data.conversation_id) {

        safeSetConversationId(data.conversation_id);
      }
    }
  }, [isOpen, queryClient, setUnreadCount, safeSetConversationId]);

  const handleNewName = useCallback(({ id }) => {
    if (id) {
      console.log("Received new conversation name, id:", id);
      // Safely invalidate query without losing messages
      queryClient.invalidateQueries('parabot_conversation_ids');
      // Use our safe function to update conversation ID
      safeSetConversationId(id);
    }
  }, [queryClient, safeSetConversationId]);

  // Set up all socket event handlers in a single place
  useEffect(() => {
    if (!socketRef.current) return;

    const socket = socketRef.current;
    console.log("Setting up socket event handlers");

    // Handle file download events
    socket.on("file_download", (fileDownloadContent) => {
      if (!fileDownloadContent) {
        console.error("Invalid file download content");
        return;
      }

      const newMessageId = uuidv4();
      console.log("Received file download, creating message:", newMessageId);

      // Get current data from refs to ensure we're using the latest
      const currentIds = [...messageIdsRef.current];
      const currentData = { ...initialMessageDataRef.current };

      // Update with new message
      const updatedIds = [...currentIds, newMessageId];
      const updatedData = {
        ...currentData,
        [newMessageId]: {
          id: newMessageId,
          role: "assistant",
          text: "",
          thinking: "",
          file_downloads: fileDownloadContent
        }
      };

      // Update both refs and state
      updateMessageIds(updatedIds);
      updateInitialMessageData(updatedData);
    });

    // Handle conversation data events
    socket.on("return-active-conversation", (data) => {
      if (!data || !data.messages || !Array.isArray(data.messages)) {
        console.error("Invalid messages data:", data);
        return;
      }

      try {
        console.log("Received conversation data, messages count:", data.messages.length);

        const messagesWithIds = data.messages.map(message => {
          // Ensure message has proper structure for new format
          const formattedMessage = { ...message, id: message.id || uuidv4() };

          // No need to convert format - server should provide proper format already
          return formattedMessage;
        });

        // Build initial data first
        const initialData = {};
        messagesWithIds.forEach(msg => {
          initialData[msg.id] = msg;
        });

        // Extract just the IDs
        const ids = messagesWithIds.map(msg => msg.id);

        console.log("Processed conversation data with new message format", {
          messageCount: ids.length,
          firstMessageSample: ids.length > 0 ? initialData[ids[0]] : null
        });

        // Update both refs and state
        updateInitialMessageData(initialData);
        updateMessageIds(ids);
        setLoading(false);
      } catch (error) {
        console.error("Error processing conversation data:", error);
        setError("Error loading conversation data. Please try again.");
      }
    });

    // Add listeners for socket events related to message streaming
    // These will update the assistant message content as it streams in
    socket.on("completion_data", (data) => {
      if (!currentAssistantMessageIdRef.current) {
        console.log("No current assistant message, ignoring completion data");
        return;
      }

      const messageId = currentAssistantMessageIdRef.current;

      // Update the message data for streaming
      const currentData = { ...initialMessageDataRef.current };
      const targetMessage = currentData[messageId];

      if (!targetMessage) {
        console.error(`Target message ${messageId} not found in data`);
        return;
      }

      try {
        // In new format, text is directly on the message
        const currentText = targetMessage.text || '';
        const updatedText = currentText + data;

        // Create updated message with new structure
        const updatedMessage = {
          ...targetMessage,
          text: updatedText
        };

        // Update data ref and state
        const updatedData = { ...currentData, [messageId]: updatedMessage };
        updateInitialMessageData(updatedData);
      } catch (error) {
        console.error("Error updating message with completion data:", error);
      }
    });

    socket.on("thinking_delta", (data) => {
      if (!currentAssistantMessageIdRef.current) {
        console.log("No current assistant message, ignoring thinking data");
        return;
      }

      const messageId = currentAssistantMessageIdRef.current;
      console.log(`Received thinking data for message ${messageId}`, {
        dataPreview: data.length > 20 ? data.substring(0, 20) + '...' : data
      });

      // Update the message data for thinking
      const currentData = { ...initialMessageDataRef.current };
      const targetMessage = currentData[messageId];

      if (!targetMessage) {
        console.error(`Target message ${messageId} not found in data`);
        return;
      }

      try {
        // Merge existing thinking data with new data
        const existingThinking = targetMessage.thinking || '';
        const updatedThinking = existingThinking + data.thinking;

        // Create updated message with new structure
        const updatedMessage = {
          ...targetMessage,
          thinking: updatedThinking
        };

        // Update data ref and state
        const updatedData = { ...currentData, [messageId]: updatedMessage };
        updateInitialMessageData(updatedData);
      } catch (error) {
        console.error("Error updating message with thinking data:", error);
      }
    });

    // When a tool is called, add it to the pending set
    socket.on("tool_call", (data) => {
      if (!currentAssistantMessageIdRef.current) {
        console.log("No current assistant message, ignoring tool use data");
        return;
      }

      const messageId = currentAssistantMessageIdRef.current;
      console.log(`Received tool use data for message ${messageId}`, {
        tool: data.tool,
        argsPreview: JSON.stringify(data.arguments).substring(0, 50) + (JSON.stringify(data.arguments).length > 50 ? '...' : '')
      });

      // Update the message data for tool use
      const currentData = { ...initialMessageDataRef.current };
      const targetMessage = currentData[messageId];

      if (!targetMessage) {
        console.error(`Target message ${messageId} not found in data`);
        return;
      }

      try {
        // Create tool use object
        const toolUseId = data.id || `tool_${Date.now()}`;

        // Track this tool use as pending a result
        pendingToolUsesRef.current.add(toolUseId);

        // Get existing tool uses array or create a new one
        const existingToolUses = targetMessage.tool_uses || [];

        // Create updated message with new structure - with array of tool uses
        const updatedMessage = {
          ...targetMessage,
          // Store the single tool_use for backward compatibility
          tool_use: {
            id: toolUseId,
            tool_use_id: toolUseId, // Added explicit tool_use_id for matching
            name: data.tool,
            input: data.arguments,
            type: 'tool_use',
            result: null, // Initialize with null result that will be updated later
            display_name: data.display_name || TOOL_CONFIG[data.tool]?.displayName || 'Using Tool'
          },
          // Store an array of tool uses for sequential display
          tool_uses: [...existingToolUses, {
            id: toolUseId,
            tool_use_id: toolUseId,
            name: data.tool,
            input: data.arguments,
            type: 'tool_use',
            result: null,
            display_name: data.display_name || TOOL_CONFIG[data.tool]?.displayName || 'Using Tool'
          }]
        };

        // Update data ref and state
        const updatedData = { ...currentData, [messageId]: updatedMessage };
        updateInitialMessageData(updatedData);

        // Create a new assistant message after tool call
        const newAssistantMessageId = uuidv4();

        // Get current data from refs to ensure we have the latest
        const currentIds = [...messageIdsRef.current];

        // Create updated data with new assistant message
        const newMessageIds = [...currentIds, newAssistantMessageId];
        const newInitialData = {
          ...updatedData,
          [newAssistantMessageId]: {
            id: newAssistantMessageId,
            role: "assistant",
            text: "",
            thinking: "",
            modelId: runType
          }
        };

        // Update both refs and state
        updateInitialMessageData(newInitialData);
        updateMessageIds(newMessageIds);

        // Set the new message as current assistant message
        currentAssistantMessageIdRef.current = newAssistantMessageId;
      } catch (error) {
        console.error("Error updating message with tool use data:", error);
      }
    });

    // When a tool result is received, remove it from pending
    socket.on("tool_result", (data) => {
      if (!currentAssistantMessageIdRef.current) {
        console.log("No current assistant message, ignoring tool result data");
        return;
      }
      console.log("tool_result", data);
      if (data.tool === 'create_email_draft') {
        queryClient.invalidateQueries('drafts_being_composed')
      }
      if (data.tool === 'update_email_labels') {
        queryClient.invalidateQueries('index_threads')
      }
      if (data.tool === 'update_email_labels') {
        queryClient.invalidateQueries('email')
        console.log("  drafts_being_composed");
      }
      if (data.tool === 'edit_parabot_prompt' || data.tool === 'create_parabot_prompt') {
        queryClient.invalidateQueries('parabot-prompt-templates')
      }

      const messageId = currentAssistantMessageIdRef.current;
      console.log(`Received tool result data for message ${messageId}`, data);

      // Update the message data for tool result
      const currentData = { ...initialMessageDataRef.current };
      const targetMessage = currentData[messageId];

      if (!targetMessage) {
        console.error(`Target message ${messageId} not found in data`);
        return;
      }

      try {
        // Get the tool use ID
        const resultToolUseId = data.tool_use_id || data.id;

        // Remove from pending set
        pendingToolUsesRef.current.delete(resultToolUseId);

        // Create a properly formatted tool_result object
        const toolResult = {
          content: data.result || data.content,
          type: "tool_result",
          tool_use_id: resultToolUseId
        };

        // If using the new array format for tool uses
        if (targetMessage.tool_uses && Array.isArray(targetMessage.tool_uses)) {
          // Find the matching tool use by ID in the array
          const toolUseIndex = targetMessage.tool_uses.findIndex(
            tu => (tu.id === resultToolUseId || tu.tool_use_id === resultToolUseId)
          );

          if (toolUseIndex !== -1) {
            // Deep clone the tool uses array
            const updatedToolUses = [...targetMessage.tool_uses];

            // Update the specific tool use with the result
            updatedToolUses[toolUseIndex] = {
              ...updatedToolUses[toolUseIndex],
              result: toolResult
            };

            // Create updated message with the updated tool uses array
            const updatedMessage = {
              ...targetMessage,
              tool_uses: updatedToolUses,
              // Also update the single tool_use for backward compatibility
              tool_use: targetMessage.tool_use?.id === resultToolUseId
                ? { ...targetMessage.tool_use, result: toolResult }
                : targetMessage.tool_use,
              // Keep the tool_result separately as well for backward compatibility
              tool_result: targetMessage.tool_use?.id === resultToolUseId
                ? toolResult
                : targetMessage.tool_result
            };

            // Update data ref and state
            const updatedData = { ...currentData, [messageId]: updatedMessage };
            updateInitialMessageData(updatedData);
            return;
          }
        }

        // Fallback to the old single tool_use approach if no matching tool use found in array
        // or if array doesn't exist yet
        // Only proceed if there is a tool_use already on the message
        if (!targetMessage.tool_use) {
          console.error("Cannot add tool result, no tool_use found on message");
          return;
        }

        const toolUseId = targetMessage.tool_use.tool_use_id || targetMessage.tool_use.id;

        // Ensure we're matching the correct tool use 
        if (resultToolUseId && toolUseId !== resultToolUseId) {
          console.log(`Tool result ID ${resultToolUseId} doesn't match tool use ID ${toolUseId}, might belong to another message`);
          // Consider storing this result separately or in a shared store to match later
          return;
        }

        // Update the message with both properties
        const updatedMessage = {
          ...targetMessage,
          // Keep the original tool_use
          tool_use: { ...targetMessage.tool_use, result: toolResult },
          // Add the separate tool_result property 
          tool_result: toolResult
        };

        // Update data ref and state
        const updatedData = { ...currentData, [messageId]: updatedMessage };
        updateInitialMessageData(updatedData);
      } catch (error) {
        console.error("Error updating message with tool result data:", error);
      }
    });

    // Modify the completion-done handler to only clear currentAssistantMessageId if no pending tool uses
    socket.on("completion-done", (data) => {
      console.log("Completion done, preserving conversation");

      // Just mark loading as done, but DON'T erase messages
      setTimeout(() => {
        setLoading(false);
        // DON'T invalidate parabot_conversation_ids here as it might trigger unwanted behavior
        // Instead, only update the current assistant message ID
        currentAssistantMessageIdRef.current = null;
      }, 100);
    });

    // Handle errors
    socket.on("error", (newData) => {
      console.error("Socket error:", newData);
      setError("Sorry, something went wrong. Please refresh the page and try again.");
    });

    // Use our useCallback handlers for events that need safeSetConversationId
    socket.on("new_name", handleNewName);
    socket.on("new-message", handleNewMessage);

    // Add listener for invalidating unread count
    socket.on('invalidate-unread-count', () => {
      // Invalidate the query to update the UI
      queryClient.invalidateQueries('parabot_conversation_ids');
    });

    // Clean up all event listeners when component unmounts
    return () => {
      console.log("Cleaning up socket event handlers");
      socket.off("file_download");
      socket.off("return-active-conversation");
      socket.off("error");
      socket.off("completion-done");
      socket.off("new_name");
      // Clean up new event handlers
      socket.off("completion_data");
      socket.off("thinking_delta");
      socket.off("tool_call");
      socket.off("tool_result");
      socket.off("new-message");
      socket.off("invalidate-unread-count");
    };
  }, [queryClient, updateMessageIds, updateInitialMessageData, handleNewName, handleNewMessage]);

  // Separate effect to handle conversation ID changes
  useEffect(() => {
    if (!socketRef.current) return;
    if (!conversationId) {
      // Only clear if we aren't already in a conversation
      if (messageIdsRef.current.length > 0) {
        clearConversation();
      }
      return;
    }

    console.log("Fetching conversation:", conversationId);
    // Fetch conversation when ID changes
    socketRef.current.emit("fetch-active-conversation", { id: conversationId });

    // Mark all messages in this conversation as read
    socketRef.current.emit("update-message-status", {
      conversation_id: conversationId,
      status: 'read'
    });
  }, [conversationId, clearConversation]);

  // Add an effect to mark messages as read when opening the interface
  useEffect(() => {
    if (isOpen && conversationId && socketRef.current) {
      console.log("Interface opened, marking messages as read");
      socketRef.current.emit("update-message-status", {
        conversation_id: conversationId,
        status: 'read'
      });
    }
  }, [isOpen, conversationId]);

  // Update useEffect to ensure scroll happens on message changes
  useEffect(() => {
    if (messageIds.length > 0) {
      console.log("Messages updated, scheduling scrolls");
      scheduleMultipleScrolls();
    }
  }, [messageIds.length, scheduleMultipleScrolls]);

  // Add a second effect to handle when conversation is opened
  useEffect(() => {
    if (isOpen) {
      console.log("Conversation opened, scheduling scrolls");
      // Delay first scroll to ensure content is rendered
      setTimeout(() => {
        scheduleMultipleScrolls();
      }, 300);
    }
  }, [isOpen, scheduleMultipleScrolls]);

  // Reset unread count when opening the interface
  useEffect(() => {
    if (isOpen && unreadCount > 0) {
      setUnreadCount(0);
    }
  }, [isOpen, unreadCount]);

  // Add effect to save thinkingMode to localStorage when it changes
  useEffect(() => {
    localStorage.setItem('parabotThinkingMode', thinkingMode);
  }, [thinkingMode]);

  const handleSubmit = (message) => {
    if (!message && (!files || files.length === 0)) return;
    if (!socketRef.current) {
      console.error("Socket not available");
      return;
    }

    console.log("Submitting message:", { message, fileCount: files.length });
    setLoading(true);

    let content = [];
    if (message) content.push({ type: "text", text: message.trim() });
    if (files.length > 0) {
      console.log('files', files)
      files.filter(f => f.type === 'image' || f.type === 'document').forEach((image) => {
        content.push({ type: image.type, source: { type: 'url', url: image.url } });
      });
    }

    const userMessageId = uuidv4();
    const assistantMessageId = uuidv4();
    console.log("Creating new message pair:", userMessageId, assistantMessageId);

    // Set current assistant message ID first
    currentAssistantMessageIdRef.current = assistantMessageId;

    // Get current data from refs to ensure we have the latest
    const currentIds = [...messageIdsRef.current];
    const currentData = { ...initialMessageDataRef.current };

    // Create updated data according to new structure
    const newMessageIds = [...currentIds, userMessageId, assistantMessageId];
    const newInitialData = {
      ...currentData,
      [userMessageId]: {
        id: userMessageId,
        role: "user",
        text: message,
        content // Keep content array for backward compatibility
      },
      [assistantMessageId]: {
        id: assistantMessageId,
        role: "assistant",
        text: "",
        thinking: "",
        modelId: runType
      }
    };

    console.log("Created new message structures:", {
      userMessage: newInitialData[userMessageId],
      assistantMessage: newInitialData[assistantMessageId]
    });

    // Update both refs and state
    updateInitialMessageData(newInitialData);
    updateMessageIds(newMessageIds);

    // Now emit the socket event
    socketRef.current.emit("continue-conversation", {
      message: content,
      info,
      run_type: runType,
      thinking_mode: thinkingMode,
      conversation_id: conversationId,
      context_enabled: true,
      local_time: `${new Date().toLocaleDateString()} ${new Date().toLocaleTimeString()} - ${Intl.DateTimeFormat().resolvedOptions().timeZone}`,
      files: files.filter(f => f.type === 'spreadsheet' || f.type === 'document' || f.type === 'powerpoint' || f.type === 'image')
    });

    setFiles([]);
    setQuery('');

    // Force scroll to bottom after sending a message
    console.log("Message sent, scheduling scrolls");
    scheduleMultipleScrolls();
  };

  const openExpandedImage = (imageUrl) => {
    // Handle different URL formats - prioritize signed_url from document
    let url = imageUrl;

    // If it's an object with a document property
    if (typeof imageUrl === 'object') {
      if (imageUrl.document && imageUrl.document.signed_url) {
        url = imageUrl.document.signed_url;
      } else if (imageUrl.url) {
        url = imageUrl.url;
      }
    }

    setExpandedImageUrl(url);
  };

  const handleStopGenerating = async () => {
    if (socketRef.current) {
      socketRef.current.emit('stop-streaming');
    }
  }

  // Create a function to handle clicking the Parabot button
  const handleParabotClick = () => {
    // If there are unread conversations and we're opening the interface
    if (!isOpen && unreadConversations.length > 0) {
      // Open to the first unread conversation
      safeSetConversationId(unreadConversations[0]);
    }
    // Toggle the interface open/closed
    setIsOpen(!isOpen);
  };

  return (
    <div className={classNames("fixed right-6 bottom-6 z-50")}>
      <Modal open={isOpen} setOpen={setIsOpen}>
        <div>
          <div className="bg-gradient-to-r rounded-t-xl from-blue-600 to-blue-700 shadow-md">
            <div className="flex justify-between px-6 py-3.5 items-center">
              <div className="flex items-center gap-2.5">
                <SiChatbot className="h-5 w-5 text-white opacity-90" />
                <h5 className="text-white font-medium text-lg">Parabot</h5>
              </div>
              <Memory />
            </div>
          </div>
          <div className="flex h-[80vh]">
            <Conversations
              conversationId={conversationId}
              setConversationId={safeSetConversationId}
              handleClearConversation={clearConversation}
              conversationIds={conversationIds}
              unreadConversations={unreadConversations}
            />
            <div className="bg-white w-full rounded-br-xl flex flex-col flex-1 overflow-hidden">
              <div className="w-full h-full flex flex-col" ref={scrollRef}>
                <div className="relative flex-1 overflow-hidden">
                  <ExpandedImage imgUrl={expandedImageUrl} setImgUrl={setExpandedImageUrl} />
                  <div
                    ref={el => {
                      // Set both refs to the same element
                      scrollRef.current = el;
                      scrollContainerRef.current = el;
                    }}
                    data-scroll-container
                    className="h-full overflow-y-auto scroll-smooth custom-scrollbar-thick custom-scrollbar-gray relative"
                  >
                    <ul className="py-5 space-y-6 mx-auto max-w-4xl px-10 mb-36">
                      {socket && messageIds.length > 0 && (
                        <SocketContext.Provider value={socket}>
                          {groupMessagesByRole(messageIds, initialMessageData).map((groupIds, groupIndex) => (
                            <MessageGroup
                              key={`group-${groupIndex}-${groupIds[0]}`}
                              messageIds={groupIds}
                              initialMessageData={initialMessageData}
                              currentAssistantMessageIdRef={currentAssistantMessageIdRef}
                              openExpandedImage={openExpandedImage}
                            />
                          ))}
                        </SocketContext.Provider>
                      )}
                      {/* Display loading indicator when waiting for a response */}

                    </ul>
                  </div>
                  <div className="w-full max-w-4xl mx-auto absolute bottom-0 left-0 right-0 bg-white rounded-t-2xl pb-4 z-10 bg-opacity-95 backdrop-blur-sm shadow-lg">
                    <TextBox
                      openExpandedImage={openExpandedImage}
                      query={query}
                      setQuery={setQuery}
                      handleSubmit={handleSubmit}
                      textAreaRef={textAreaRef}
                      files={files}
                      setFiles={setFiles}
                      loading={loading}
                      handleStopGenerating={handleStopGenerating}
                      thinkingMode={thinkingMode}
                      setThinkingMode={setThinkingMode}
                    />
                  </div>
                </div>
                {error && (
                  <div className="p-5">
                    <AlertError>{error}</AlertError>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </Modal>
      <button
        onClick={handleParabotClick}
        className="bg-blue-400 shadow-2xl rounded-full sm:w-16 sm:h-16 w-14 h-14 absolute right-0 bottom-0 hover:brightness-110"
      >
        <SiChatbot className="w-7 h-7 sm:h-8 sm:w-8 text-white my-auto mx-auto" />
        <Unread count={unreadCount} />
      </button>
    </div>
  );
};

export default ParabotInterface;
