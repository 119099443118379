import React, { useState } from 'react'
import FadeIn from '../../../../transitions/FadeIn';
import { IoMdSend } from 'react-icons/io';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { API_AUTH_TYPES, API_ROUTES, ApiRequest } from '../../../../../api';
import SmallLoadingSpinner from '../../../../loading/SmallLoadingSpinner';
import ItemMenu from '../../../../menus/ItemMenu';
import { TrashIcon, PencilIcon, PlusCircleIcon, DuplicateIcon } from '@heroicons/react/outline';
import { Switch } from '@headlessui/react'
import { classNames } from '../../../../misc/styling';

const PromptItem = ({ template, handleSendPrompt }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [isAddAnimating, setIsAddAnimating] = useState(false);

    const [title, setTitle] = useState(template.title)
    const [prompt, setPrompt] = useState(template.prompt)
    const [isPrivate, setIsPrivate] = useState(!!template.user_id)

    const queryClient = useQueryClient()
    const handleEdit = () => {
        setIsEditing(true);
        setIsOpen(true);
    }

    const handleOpenClick = () => {
        if (isEditing) return;
        setIsOpen(!isOpen);
    }

    const handleCancelEdit = () => {
        setIsEditing(false);
        setIsOpen(false);
        setPrompt(template.prompt);
        setTitle(template.title);
        setIsPrivate(!!template.user_id);
    }
    const parabotApi = new ApiRequest(API_ROUTES.PARABOT, API_AUTH_TYPES.ADVISOR)

    const { mutate: updatePromptTemplate, isLoading } = useMutation(async () => parabotApi.patch({
        endpoint: 'update_prompt_template',
        body: {
            title,
            prompt,
            isPrivate
        },
        params: [template.id]
    }),
        {
            onSuccess: () => {
                setIsEditing(false);
                setIsOpen(false);
                queryClient.invalidateQueries('parabot-prompt-templates')
            },
            onError: (error) => {
                console.error('Error updating prompt:', error)
            },
        })

    const { mutate: archivePromptTemplate, isLoading: deleteLoading } = useMutation(async () => parabotApi.patch({
        endpoint: 'archive_prompt_template',
        params: [template.id]
    }),
        {
            onSuccess: () => {
                setIsEditing(false);
                setIsOpen(false);
                queryClient.invalidateQueries('parabot-prompt-templates')
            },
            onError: (error) => {
                console.error('Error updating prompt:', error)
            },
        })

    const { mutate: duplicateAsPrivate, isLoading: duplicateLoading } = useMutation(async () => parabotApi.post({
        endpoint: 'create_prompt_template',
        body: {
            title: `${template.title} (Private Copy)`,
            prompt: template.prompt,
            isPrivate: true
        },
    }), {
        onSuccess: () => {
            queryClient.invalidateQueries('parabot-prompt-templates')
        },
        onError: (error) => {
            console.error('Error duplicating prompt:', error)
        },
    })

    const options = [
        {
            title: 'Edit',
            icon: PencilIcon,
            action: handleEdit,
        },
        // Only show duplicate as private for public templates
        ...(!template.user_id ? [{
            title: 'Duplicate as Private',
            icon: DuplicateIcon,
            action: duplicateAsPrivate,
        }] : []),
        {
            title: 'Delete',
            action: archivePromptTemplate,
            icon: TrashIcon
        },
    ]

    const handleAddPrompt = () => {
        setIsAddAnimating(true);
        handleSendPrompt(prompt);
        // Reset animation after a delay
        setTimeout(() => setIsAddAnimating(false), 500);
    };

    return (
        <li
            className={classNames(
                isOpen ? 'text-gray-900 bg-gray-100' : 'text-gray-700 hover:bg-gray-50 hover:text-gray-900',
                isAddAnimating ? 'bg-blue-50 border-blue-100' : ''
            )}
        >
            <div
                className={classNames(
                    '',
                    '  text-sm w-full text-left flex px-1',
                )}  >

                <button
                    onClick={handleOpenClick}
                    className=" w-full text-left  py-2 text-base font-semibold items-center space-x-2 ">
                    {isEditing ?
                        <input type="text"
                            value={title}
                            onChange={(e) => setTitle(e.target.value)}
                            className="input-primary mt-1"
                            defaultValue='Title'
                        /> :
                        <div className="flex items-center space-x-2">
                            <p>{title}</p>

                        </div>
                    }
                </button>
                {!isEditing && <div className='mr-4 my-auto'><ItemMenu options={options} /></div>}

                {!isEditing && <button
                    onClick={handleAddPrompt}
                    className={classNames(
                        "group transition-all duration-200 hover:scale-110 flex items-center justify-center rounded-full p-1",
                        isAddAnimating ? "bg-blue-100" : "hover:bg-blue-50"
                    )}
                >
                    <PlusCircleIcon className={classNames(
                        "h-7 w-7 transition-all duration-300",
                        isAddAnimating ? "text-blue-600 scale-110" : "text-blue-500 group-hover:text-blue-600"
                    )} />
                </button>}

            </div>
            <FadeIn isShowing={isOpen}>
                <div className="px-1 py-2">
                    {isEditing ?
                        <>
                            <textarea
                                className="input-primary mt-1"
                                rows="6"
                                value={prompt}
                                placeholder='Your prompt here...'
                                onChange={(e) => setPrompt(e.target.value)}
                            />
                            <div className="flex items-center justify-between mt-4">
                                <span className="text-sm text-gray-700">Make this prompt private</span>
                                <Switch
                                    checked={isPrivate}
                                    onChange={setIsPrivate}
                                    className={classNames(
                                        isPrivate ? 'bg-blue-600' : 'bg-gray-200',
                                        'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-offset-2'
                                    )}
                                >
                                    <span
                                        aria-hidden="true"
                                        className={classNames(
                                            isPrivate ? 'translate-x-5' : 'translate-x-0',
                                            'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
                                        )}
                                    />
                                </Switch>
                            </div>
                        </> :
                        <p className='max-h-[400px] overflow-auto custom-scrollbar-thick custom-scrollbar-gray'>
                            {prompt}
                        </p>}
                </div>

            </FadeIn>

            <FadeIn isShowing={isEditing}> <div className="flex   justify-evenly items-center py-2">
                <button className="btn-lg btn-primary "
                    onClick={updatePromptTemplate}
                >
                    <p className='pr-1'> Update Prompt </p>  {isLoading ? <SmallLoadingSpinner textColor='text-white' /> : null}
                </button>
                <button className=" text-gray-500 btn-lg btn-gray-outline" onClick={handleCancelEdit}>
                    Cancel
                </button>
            </div>
            </FadeIn>

        </li>)
}

export default PromptItem