import { useState, useCallback } from 'react';
import { useMutation } from 'react-query';
import { API_AUTH_TYPES, API_ROUTES, ApiRequest } from '../../../api';

/**
 * Custom hook for handling file uploads with optional AI processing
 * @param {Object} options Configuration options for the file upload
 * @param {string} options.documentType Type of document being uploaded (e.g., 'investments_screenshot')
 * @param {Function} options.onUploadComplete Callback function called after successful upload
 * @param {Function} options.processFiles Optional function to process files after upload (e.g., AI processing)
 * @param {Object} options.api Optional API instance for additional processing
 * @param {string} options.authType Auth type for the documents API (defaults to USER)
 * @param {string} options.customFilePath Optional custom file path for S3 storage (overrides default path structure)
 * @returns {Object} Hook methods and state
 */
const useFileUpload = ({
    documentType,
    onUploadComplete,
    processFiles,
    api,
    authType = API_AUTH_TYPES.USER,
    customFilePath
}) => {
    const [submittedFiles, setSubmittedFiles] = useState([]);
    const [newFiles, setNewFiles] = useState([]);
    const [uploading, setUploading] = useState(false);

    const documentsApi = new ApiRequest(API_ROUTES.DOCUMENTS, authType);

    // Upload a single file to document storage
    const uploadDocument = async (file) => {
        const formData = new FormData();
        formData.append('file', file);
        formData.append('documentType', documentType);

        // Add customFilePath to form data if provided
        if (customFilePath) {
            formData.append('customFilePath', customFilePath);
        }

        const response = await documentsApi.post({
            endpoint: 'upload',
            body: formData,
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });

        return response.document;
    };

    // Mutation for processing files (e.g., AI analysis)
    const processMutation = useMutation(
        async (filesToProcess) => {
            if (!processFiles) {
                return filesToProcess;
            }

            const result = await processFiles(filesToProcess, api);

            // Move files from new to submitted
            setSubmittedFiles(prev => [...prev, ...filesToProcess]);
            setNewFiles([]);

            return result;
        },
        {
            onSuccess: (data) => {
                onUploadComplete?.(data);
            }
        }
    );

    // Handle file selection and upload
    const handleFiles = useCallback(async (fileList) => {
        if (fileList.length === 0) return;

        setUploading(true);

        try {
            const allowedFiles = fileList.filter(file =>
                file.type.startsWith('image/') || file.type === 'application/pdf'
            );

            if (allowedFiles.length > 0) {
                const uploadPromises = allowedFiles.map(async (file) => {
                    const document = await uploadDocument(file);
                    const type = file.type === 'application/pdf' ? 'application/pdf' : 'image';
                    return {
                        url: document.signed_url,
                        type,
                        document: document
                    };
                });

                const uploadedFiles = await Promise.all(uploadPromises);
                setNewFiles(prev => [...prev, ...uploadedFiles]);

                if (processFiles) {
                    await processMutation.mutateAsync(uploadedFiles);
                } else {
                    setSubmittedFiles(prev => [...prev, ...uploadedFiles]);
                    setNewFiles([]);
                    onUploadComplete?.(uploadedFiles);
                }
            }
        } catch (error) {
            console.error('Upload Error:', error);
            throw error;
        } finally {
            setUploading(false);
        }
    }, [documentsApi, processFiles, onUploadComplete]);

    // Remove a file
    const removeFile = useCallback(async (fileToRemove) => {
        console.log('fileToRemove', fileToRemove);
        try {
            // Call the delete API endpoint
            await documentsApi.delete({
                endpoint: 'delete',
                params: [fileToRemove.document.id]
            })

            // Only update state if the API call was successful
            setSubmittedFiles(prev => prev.filter(file => file.url !== fileToRemove.url));
            setNewFiles(prev => prev.filter(file => file.url !== fileToRemove.url));
        } catch (error) {
            console.error('Delete document error:', error);
            throw new Error('Failed to delete document');
        }
    }, [documentsApi]);

    // Reset all state
    const reset = useCallback(() => {
        setSubmittedFiles([]);
        setNewFiles([]);
        setUploading(false);
    }, []);

    return {
        submittedFiles,
        newFiles,
        allFiles: [...submittedFiles, ...newFiles],
        uploading,
        isProcessing: processMutation.isLoading,
        handleFiles,
        removeFile,
        reset
    };
};

export default useFileUpload;