import React from 'react';
import { AiOutlineLoading3Quarters } from 'react-icons/ai';
import { classNames } from '../../../../helpers/classNames';

/**
 * LoadingIndicator component displays a spinning icon with a message
 * when the AI is processing a user's message
 * 
 * @param {boolean} isVisible - Whether the indicator should be visible
 * @param {Object} assistantMessage - The current assistant message data (if any)
 */
const LoadingIndicator = ({ isVisible }) => {
    // Modified to always show the indicator if it's explicitly set to be visible, 
    // regardless of whether the assistant message has content or not

    if (!isVisible) return null;

    return (

            <div className="flex items-start justify-start w-full ">
                <div className="flex items-center  px-1 text-gray-500">
                    <AiOutlineLoading3Quarters className="h-4 w-4 text-blue-400 animate-spin mr-2" />
                    <span className="text-sm font-normal">
                        Parabot is working...
                    </span>
                </div>
            </div>
    );
};

export default LoadingIndicator; 