import React, { useMemo } from 'react';
import Message, { SocketContext } from './Message';
import { classNames } from '../../../../helpers/classNames';

const MessageGroup = ({ messageIds, initialMessageData, currentAssistantMessageIdRef, openExpandedImage }) => {
    // Get the role from the first message in the group (all messages in a group have the same role)
    const groupRole = useMemo(() => {
        const firstMessageId = messageIds[0];
        if (!firstMessageId || !initialMessageData[firstMessageId]) return null;
        return initialMessageData[firstMessageId].role;
    }, [messageIds, initialMessageData]);

    if (!groupRole) return null;

    return (
        <div
            className={classNames(
                "flex flex-col space-y-1 mb-6",
                groupRole === "user" ? "items-end" : "items-start"
            )}
        >
            {messageIds.map((messageId, index) => (
                <Message
                    key={messageId}
                    messageId={messageId}
                    index={index}
                    openExpandedImage={openExpandedImage}
                    initialData={initialMessageData[messageId]}
                    currentAssistantMessageIdRef={currentAssistantMessageIdRef}
                    isGrouped={true} // Tell the Message component it's part of a group
                    isLastInGroup={index === messageIds.length - 1} // Is this the last message in the group
                />
            ))}
        </div>
    );
};

export default React.memo(MessageGroup, (prevProps, nextProps) => {
    // Only re-render if messageIds or initialMessageData for those ids changed
    const prevMessageIdSet = new Set(prevProps.messageIds);
    const nextMessageIdSet = new Set(nextProps.messageIds);

    // Check if the sets of IDs are the same
    if (prevMessageIdSet.size !== nextMessageIdSet.size) return false;
    for (const id of prevMessageIdSet) {
        if (!nextMessageIdSet.has(id)) return false;
    }

    // Check if any initialData for these messages changed
    for (const id of prevMessageIdSet) {
        if (
            JSON.stringify(prevProps.initialMessageData[id]) !==
            JSON.stringify(nextProps.initialMessageData[id])
        ) {
            return false;
        }
    }

    return true;
}); 