import React, { useState, useEffect } from 'react';
import { Transition } from '@headlessui/react';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/outline';
import Markdown from 'markdown-to-jsx';
import { LuBrain } from "react-icons/lu";
import { classNames } from '../../../../helpers/classNames';

/**
 * Component to display AI thinking tokens in a collapsible panel
 * @param {Object} props 
 * @param {Object} props.thinking - Thinking data object with raw thinking content
 * @param {Function} props.onToggle - Optional callback for when thinking is expanded/collapsed
 */
const ThinkingDisplay = ({ thinking, onToggle }) => {
    const [isExpanded, setIsExpanded] = useState(false);
    const [formattedContent, setFormattedContent] = useState("");
    // Track if thinking is complete


    // Trigger scroll adjustment when expanded state changes
    useEffect(() => {
        if (onToggle && typeof onToggle === 'function') {
            onToggle(isExpanded);
        }

        // Force a scroll adjustment when thinking display changes visibility
        if (isExpanded) {
            setTimeout(() => {
                try {
                    const scrollable = document.querySelector('[data-scroll-container]');
                    if (scrollable) {
                        // Check if user is already near bottom
                        const { scrollTop, scrollHeight, clientHeight } = scrollable;
                        const nearBottom = scrollHeight - scrollTop - clientHeight < 200;

                        if (nearBottom) {
                            // User is near bottom, scroll to bottom
                            scrollable.scrollTop = scrollable.scrollHeight;
                        }
                    }
                } catch (error) {
                    console.log('Scroll error in ThinkingDisplay:', error);
                }
            }, 100);
        }
    }, [isExpanded, onToggle]);

    useEffect(() => {
        if (thinking?.raw) {
            try {
                // Simple formatting to ensure proper markdown rendering
                let content = thinking.raw
                    // Ensure code blocks are properly formatted
                    .replace(/```([\s\S]*?)```/g, (match) => {
                        // Make sure code blocks have newlines before and after
                        return match.startsWith('\n') ? match : '\n' + match;
                    })
                    // Ensure headers have space after #
                    .replace(/^(#+)([^\s#])/gm, '$1 $2');

                setFormattedContent(content);

                // Log that thinking content is being processed (development only)
                if (process.env.NODE_ENV === 'development') {
                    console.log(`Processing thinking content: ${content.length} chars`);
                }
            } catch (error) {
                console.error("Error formatting thinking content:", error);
                setFormattedContent(thinking.raw);
            }
        }
    }, [thinking]);

    // Only show the thinking UI when there are actual thinking tokens
    if (!thinking || ((!thinking.raw || thinking.raw.trim() === '') && (!thinking.chunkCount || thinking.chunkCount <= 0))) {
        // Don't show anything if there are no actual thinking tokens
        return null;
    }

    const toggleExpanded = () => {
        setIsExpanded(!isExpanded);
    };

    return (
        <div className="mt-2 items-center mb-2">
            <div
                className={classNames(
                    "flex items-center justify-start cursor-pointer"
                )}
                onClick={toggleExpanded}
            >
                <div className="flex items-center">
                    <LuBrain className="h-4 w-4 text-gray-500 mr-2" />
                    <div className={classNames(
                        "text-base text-gray-600 font-bold"
                    )}>
                        Thought process
                    </div>
                </div>
                <div className="flex items-center ml-3">
                    {isExpanded ? (
                        <ChevronUpIcon className="h-4 w-4 text-gray-500" />
                    ) : (
                        <ChevronDownIcon className="h-4 w-4 text-gray-500" />
                    )}
                </div>
            </div>

            <Transition
                show={isExpanded}
                enter="transition duration-100 ease-out"
                enterFrom="transform scale-95 opacity-0"
                enterTo="transform scale-100 opacity-100"
                leave="transition duration-75 ease-out"
                leaveFrom="transform scale-100 opacity-100"
                leaveTo="transform scale-95 opacity-0"
            >
                <div className="mt-2 pl-2">
                    <div className="max-h-96 overflow-y-auto bg-gray-900 text-gray-100 rounded-md font-mono text-sm p-4">
                        <div className="prose prose-sm prose-invert max-w-none">
                            <Markdown options={{
                                wrapper: "",
                                overrides: {
                                    pre: {
                                        props: {
                                            className: 'bg-gray-800 p-2 rounded my-2 overflow-x-auto'
                                        }
                                    },
                                    code: {
                                        props: {
                                            className: 'bg-gray-800 px-1 rounded'
                                        }
                                    }
                                }
                            }}>
                                {formattedContent || thinking.raw || (thinking.chunkCount ? `Thinking data received (${thinking.chunkCount} chunks) but content is empty.` : 'No thinking data available.')}
                            </Markdown>
                        </div>
                    </div>
                </div>
            </Transition>
        </div>
    );
};

export default ThinkingDisplay; 