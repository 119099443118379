import React, { useState } from 'react'
import { useMutation, useQueryClient } from 'react-query'
import { API_AUTH_TYPES, API_ROUTES, ApiRequest } from '../../../../../../../api'
import SmallLoadingSpinner from '../../../../../../../components/loading/SmallLoadingSpinner'
import FadeIn from '../../../../../../../components/transitions/FadeIn'
import { Switch } from '@headlessui/react'
import { classNames } from '../../../../../../../helpers/classNames'

const parabotApi = new ApiRequest(API_ROUTES.BUNMAIL, API_AUTH_TYPES.ADVISOR)

const CreateTemplate = ({ open, setOpen, emailId }) => {
    const [title, setTitle] = useState('')
    const [isPrivate, setIsPrivate] = useState(false)
    const queryClient = useQueryClient()
    const { mutate: postPrompt, isLoading } = useMutation(async () => parabotApi.post({
        endpoint: 'template',
        body: {
            title,
            email_id: emailId,
            isPrivate
        },
    }), {
        onSuccess: () => {
            setOpen(false)
            // reset form inputs
            setTitle('')
            setIsPrivate(false)
            queryClient.invalidateQueries('email-templates')
        },
        onError: (error) => {
            console.error('Error creating template:', error)
        },
    })
    return (
        <FadeIn isShowing={open}>
            <div className="border-b pb-2">
                <p className="label-primary">
                    Template Label
                </p>
                <p className="text-sm text-gray-600 mb-2">
                    Create a reusable template from your current email content
                </p>
                <input
                    autoComplete='off'
                    type="text"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                    className="input-primary mt-1"
                />

                <div className="flex items-center justify-between mt-4">
                    <span className="text-sm text-gray-700">Make this template private</span>
                    <Switch
                        checked={isPrivate}
                        onChange={setIsPrivate}
                        className={classNames(
                            isPrivate ? 'bg-blue-600' : 'bg-gray-200',
                            'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-offset-2'
                        )}
                    >
                        <span
                            aria-hidden="true"
                            className={classNames(
                                isPrivate ? 'translate-x-5' : 'translate-x-0',
                                'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
                            )}
                        />
                    </Switch>
                </div>

                <div className="flex justify-evenly items-center mt-3">
                    <button className="btn-lg btn-primary"
                        onClick={postPrompt}
                        disabled={title.trim() === ''}
                    >
                        <p className='pr-1'> Create Template </p>  {isLoading ? <SmallLoadingSpinner textColor='text-white' /> : null}
                    </button>
                    <button className="text-gray-500 btn-lg btn-gray-outline" onClick={() => setOpen(false)}>
                        Cancel
                    </button>
                </div>
            </div>
        </FadeIn>
    )
}

export default CreateTemplate