import { useState, useMemo, useRef, useEffect } from "react";
import { XIcon, SearchIcon, ChevronDownIcon, ChevronUpIcon, PlusIcon } from "@heroicons/react/outline";
import { dateFormaterWithTime } from "../../../../helpers/formatters";
import { classNames } from "../../../../helpers/classNames";
import Toggle from "../../../../components/toggle";
import RichTextDisplay from "../../../../components/textEditor/RichTextDisplay";
import ClipboardCopy from "../../../../components/copy-to-clipboard/ClipboardCopy";
import NewNote from "../Notes/components/NewNote";
import { useQueryClient, useQuery, useMutation } from "react-query";
import ApiRequest from "../../../../api/ApiRequest";
import { API_AUTH_TYPES, API_ROUTES } from "../../../../api/apiConstants";
import SmallLoadingSpinner from "../../../../components/loading/SmallLoadingSpinner";

export default function MeetingDetails({ meetingId, onClose }) {
    const [searchQuery, setSearchQuery] = useState("");
    const [isTranscriptExpanded, setIsTranscriptExpanded] = useState(false);
    const [expandedNoteId, setExpandedNoteId] = useState(null);
    const [searchResults, setSearchResults] = useState({ count: 0, currentIndex: -1 });
    const [showNewNote, setShowNewNote] = useState(false);
    const [isAddingNote, setIsAddingNote] = useState(false);
    const transcriptRef = useRef(null);
    const queryClient = useQueryClient();

    const meetingsApi = new ApiRequest(API_ROUTES.CLIENT_MEETINGS, API_AUTH_TYPES.ADVISOR);
    const householdNotesApi = new ApiRequest(API_ROUTES.HOUSEHOLD_NOTES, API_AUTH_TYPES.ADVISOR);

    const { data: meeting, isLoading, error } = useQuery(
        [`meeting-${meetingId}`],
        () => meetingsApi.getFn({ endpoint: 'get_meeting_details', params: [meetingId] })()
    );

    const [localPrepCompleted, setLocalPrepCompleted] = useState(false);
    const [selectedStatus, setSelectedStatus] = useState("active");

    const updatePrepStatus = useMutation(
        (isCompleted) => {
            return meetingsApi.patch({
                endpoint: "update_prep_completed",
                params: [meetingId],
                body: { prep_completed: isCompleted },
            });
        },
        {
            onSuccess: () => {
                queryClient.invalidateQueries(`meeting-${meetingId}`);
                if (meeting?.household_id) {
                    queryClient.invalidateQueries(`meetings-${meeting.household_id}`);
                }
            },
        }
    );

    const updateMeetingStatus = useMutation(
        (newStatus) => {
            return meetingsApi.patch({
                endpoint: "update_meeting_status",
                params: [meetingId],
                body: { status: newStatus },
            });
        },
        {
            onSuccess: () => {
                queryClient.invalidateQueries(`meeting-${meetingId}`);
                if (meeting?.household_id) {
                    queryClient.invalidateQueries(`meetings-${meeting.household_id}`);
                }
            },
        }
    );

    useEffect(() => {
        if (meeting) {
            setLocalPrepCompleted(meeting.prep_completed || false);
            setSelectedStatus(meeting.status || "active");
        }
    }, [meeting]);

    const meetingTypeColors = {
        'active': 'bg-green-100 text-green-700 ring-green-400',
        'no-show': 'bg-yellow-100 text-yellow-700 ring-yellow-400',
        'canceled': 'bg-red-100 text-red-700 ring-red-400',
    };

    // Get notes from meeting object
    const meetingNotes = meeting?.notes || [];

    // Filter transcript if search query exists
    const transcriptContent = useMemo(() => {
        if (!meeting?.transcript || !searchQuery.trim()) {
            return meeting?.transcript;
        }

        // Basic search highlighting with regex
        const regex = new RegExp(`(${searchQuery.trim()})`, 'gi');
        return meeting.transcript.replace(
            regex,
            '<span class="bg-yellow-200 search-highlight">$1</span>'
        );
    }, [meeting?.transcript, searchQuery]);

    // Count and navigate through search results
    useEffect(() => {
        if (searchQuery.trim() && meeting?.transcript && transcriptRef.current) {
            const highlights = transcriptRef.current.querySelectorAll('.search-highlight');
            setSearchResults({
                count: highlights.length,
                currentIndex: highlights.length > 0 ? 0 : -1
            });

            // Scroll to first result
            if (highlights.length > 0) {
                highlights[0].scrollIntoView({ behavior: 'smooth', block: 'center' });
                highlights[0].classList.add('current-highlight');
            }
        } else {
            setSearchResults({ count: 0, currentIndex: -1 });
        }
    }, [transcriptContent, searchQuery]);

    const navigateSearchResults = (direction) => {
        if (searchResults.count === 0) return;

        const highlights = transcriptRef.current.querySelectorAll('.search-highlight');

        // Remove current highlight indicator
        if (searchResults.currentIndex >= 0 && searchResults.currentIndex < highlights.length) {
            highlights[searchResults.currentIndex].classList.remove('current-highlight');
        }

        // Calculate new index
        let newIndex;
        if (direction === 'next') {
            newIndex = (searchResults.currentIndex + 1) % searchResults.count;
        } else {
            newIndex = (searchResults.currentIndex - 1 + searchResults.count) % searchResults.count;
        }

        // Apply new highlight and scroll
        highlights[newIndex].classList.add('current-highlight');
        highlights[newIndex].scrollIntoView({ behavior: 'smooth', block: 'center' });

        setSearchResults({ ...searchResults, currentIndex: newIndex });
    };

    if (isLoading) {
        return <div className="fixed inset-0 z-50 flex items-center justify-center">
            <div className="animate-spin rounded-full h-32 w-32 border-b-2 border-gray-900"></div>
        </div>;
    }

    if (error || !meeting) {
        return <div className="fixed inset-0 z-50 flex items-center justify-center">
            <div className="text-red-600">Error loading meeting details</div>
        </div>;
    }

    const handlePrepToggle = (isCompleted) => {
        setLocalPrepCompleted(isCompleted);
        updatePrepStatus.mutate(isCompleted);
    };

    const handleStatusChange = (newStatus) => {
        setSelectedStatus(newStatus);
        updateMeetingStatus.mutate(newStatus);
    };

    const toggleTranscriptExpanded = () => {
        setIsTranscriptExpanded(!isTranscriptExpanded);
    };

    const openNoteInModal = (noteId) => {
        setExpandedNoteId(noteId);
    };

    const closeNoteModal = () => {
        setExpandedNoteId(null);
    };

    return (
        <div className="fixed inset-0 z-50 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
            <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true" onClick={onClose}></div>

                <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>

                {/* No separate note modal - we'll expand within the main modal */}

                {/* Main Meeting Details Modal */}
                <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-4xl sm:w-full">
                    <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                        <div className="flex justify-between items-start mb-4">
                            <div>
                                <h2 className="text-xl font-bold text-gray-900">{meeting.name}</h2>
                                <div className="flex flex-wrap items-center mt-2 gap-4">
                                    <div className="flex items-center space-x-2">
                                        <span className="text-sm text-gray-600">Status:</span>
                                        <div className="flex items-center space-x-2">
                                            <span className={classNames(
                                                "text-xs font-medium px-2 py-1 rounded-full",
                                                selectedStatus === "active" ? meetingTypeColors["active"] : meetingTypeColors["no-show"]
                                            )}>
                                                {selectedStatus === "active" ? "Active" : "No Show"}
                                            </span>
                                            {selectedStatus === "active" ? (
                                                <button
                                                    onClick={() => handleStatusChange("no-show")}
                                                    className="inline-flex items-center rounded-md border border-yellow-300 bg-transparent text-xs font-medium text-yellow-600 px-1.5 py-0.5 hover:bg-yellow-50 focus:outline-none focus:ring-1 focus:ring-yellow-500 focus:ring-offset-1"
                                                >
                                                    Mark No Show
                                                </button>
                                            ) : (
                                                <button
                                                    onClick={() => handleStatusChange("active")}
                                                    className="inline-flex items-center rounded-md border border-green-300 bg-transparent text-xs font-medium text-green-600 px-1.5 py-0.5 hover:bg-green-50 focus:outline-none focus:ring-1 focus:ring-green-500 focus:ring-offset-1"
                                                >
                                                    Mark Active
                                                </button>
                                            )}
                                        </div>
                                    </div>
                                    <div className="flex items-center space-x-2">
                                        <span className="text-sm text-gray-600">Preparation Complete</span>
                                        <Toggle
                                            enabled={localPrepCompleted}
                                            setEnabled={handlePrepToggle}
                                            color={localPrepCompleted ? "green" : "gray"}
                                        />
                                    </div>
                                </div>
                            </div>
                            <button
                                type="button"
                                className="rounded-md text-gray-400 hover:text-gray-500"
                                onClick={onClose}
                            >
                                <span className="sr-only">Close</span>
                                <XIcon className="h-6 w-6" aria-hidden="true" />
                            </button>
                        </div>

                        <div className="space-y-5">
                            {/* Meeting Schedule - reduced visual hierarchy */}
                            <div className="mb-2">
                                <h3 className="text-xs font-medium text-gray-500 uppercase tracking-wider mb-2">Meeting Schedule</h3>
                                <div className="flex flex-wrap gap-4">
                                    <div>
                                        <dt className="text-xs font-medium text-gray-500">Scheduled At</dt>
                                        <dd className="text-sm text-gray-900">{dateFormaterWithTime(new Date(meeting.created_at))}</dd>
                                    </div>
                                    <div>
                                        <dt className="text-xs font-medium text-gray-500">Start Time</dt>
                                        <dd className="text-sm text-gray-900">{dateFormaterWithTime(new Date(meeting.start_time))}</dd>
                                    </div>
                                    <div>
                                        <dt className="text-xs font-medium text-gray-500">Duration</dt>
                                        <dd className="text-sm text-gray-900">{meeting.duration_min} minutes</dd>
                                    </div>
                                </div>
                            </div>

                            {/* Meeting Type - reduced visual hierarchy */}
                            <div className="mb-2">
                                <h3 className="text-xs font-medium text-gray-500 uppercase tracking-wider mb-2">Meeting Type</h3>
                                <div className="flex flex-wrap gap-4">
                                    <div>
                                        <dt className="text-xs font-medium text-gray-500">Type</dt>
                                        <dd className="text-sm text-gray-900">{meeting.meeting_type || 'Not specified'}</dd>
                                    </div>
                                    {meeting.zoom_meeting_id && (
                                        <div>
                                            <dt className="text-xs font-medium text-gray-500">Zoom Meeting</dt>
                                            <dd className="text-sm text-gray-900">{meeting.zoom_meeting_id}</dd>
                                        </div>
                                    )}
                                </div>
                            </div>

                            {/* Meeting Reason */}
                            <div>
                                <h3 className="text-sm font-medium text-gray-700 mb-2">Meeting Reason</h3>
                                {meeting.questions_and_answers && meeting.questions_and_answers.length > 0 ? (
                                    <div className="space-y-4">
                                        {meeting.questions_and_answers.map((qa, index) => (
                                            <div key={index} className="bg-gray-50 rounded-lg p-4">
                                                <p className="text-xs text-gray-500">{qa.question}</p>
                                                <p className="text-sm font-medium text-gray-700 mt-1">{qa.answer}</p>
                                            </div>
                                        ))}
                                    </div>
                                ) : (
                                    <p className="text-sm text-gray-500 italic">No meeting reason provided</p>
                                )}
                            </div>

                            {/* Meeting Notes */}
                            <div className="border border-gray-200 rounded-lg">
                                <div className="flex items-center justify-between p-4">
                                    <h3 className="text-sm font-medium text-gray-700">
                                        Linked Notes {meetingNotes.length > 1 ? `(${meetingNotes.length})` : ''}
                                    </h3>
                                    <button
                                        type="button"
                                        onClick={() => setShowNewNote(!showNewNote)}
                                        className="inline-flex items-center rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                                        disabled={isAddingNote}
                                    >
                                        {isAddingNote ? (
                                            <SmallLoadingSpinner className="h-4 w-4 mr-1" />
                                        ) : (
                                            <PlusIcon className="h-4 w-4 mr-1" />
                                        )}
                                        {isAddingNote ? "Adding Note..." : "Add Note"}
                                    </button>
                                </div>

                                {/* Add New Note Section */}
                                {showNewNote && (
                                    <div className="px-4 pb-4">
                                        <NewNote
                                            householdId={meeting.household_id}
                                            listQueryKey={["household_notes", meeting.household_id]}
                                            handleSubmitOnSuccess={async (noteId) => {
                                                setIsAddingNote(true);
                                                try {
                                                    await householdNotesApi.patch({
                                                        endpoint: "attach_to_meeting",
                                                        body: {
                                                            note_id: noteId,
                                                            meeting_id: meeting.meeting_id
                                                        }
                                                    });
                                                    queryClient.invalidateQueries(["household_notes", meeting.household_id]);
                                                    queryClient.invalidateQueries([`meeting-${meeting.meeting_id}`]);
                                                    setShowNewNote(false);
                                                } catch (error) {
                                                    console.error("Error attaching note to meeting:", error);
                                                } finally {
                                                    setIsAddingNote(false);
                                                }
                                            }}
                                        />
                                    </div>
                                )}

                                {meetingNotes.length > 0 && (
                                    <div className="px-4 pb-4">
                                        <div className="space-y-3">
                                            {meetingNotes.map((note) => (
                                                <div key={note.household_note_id} className="border border-gray-200 rounded-lg">
                                                    {/* Note header/preview (always visible) */}
                                                    <div
                                                        className="cursor-pointer rounded-t-lg p-2 hover:bg-gray-50 transition-colors"
                                                        onClick={() => expandedNoteId === note.household_note_id ? closeNoteModal() : openNoteInModal(note.household_note_id)}
                                                    >
                                                        <div className="flex justify-between items-center">
                                                            <button
                                                                type="button"
                                                                className="text-blue-600 hover:text-blue-800 text-xs"
                                                                onClick={(e) => {
                                                                    e.stopPropagation();
                                                                    expandedNoteId === note.household_note_id ? closeNoteModal() : openNoteInModal(note.household_note_id);
                                                                }}
                                                            >
                                                                {expandedNoteId === note.household_note_id ? "Collapse Note" : "View Full Note"}
                                                            </button>
                                                        </div>

                                                        {/* Preview content (only visible when collapsed) */}
                                                        {expandedNoteId !== note.household_note_id && (
                                                            <div className="">
                                                                {note.content ? (
                                                                    <div className="max-h-16 overflow-hidden relative">
                                                                        <RichTextDisplay
                                                                            content={note.content}
                                                                            thisClassName="markdown-chat text-sm text-gray-600"
                                                                        />
                                                                        <div className="absolute bottom-0 left-0 right-0 h-8 bg-gradient-to-t from-white to-transparent"></div>
                                                                    </div>
                                                                ) : (
                                                                    <p className="text-sm text-gray-500 italic">
                                                                        No content available for this note
                                                                    </p>
                                                                )}
                                                            </div>
                                                        )}
                                                    </div>

                                                    {/* Expanded note content */}
                                                    {expandedNoteId === note.household_note_id && (
                                                        <div className="border-t border-gray-200 bg-white rounded-b-lg p-4 overflow-y-auto max-h-[50px] xl:max-h-[700px] custom-scrollbar-thick custom-scrollbar-gray">
                                                            {note.content ? (
                                                                <RichTextDisplay
                                                                    content={note.content}
                                                                    thisClassName="markdown-chat"
                                                                />
                                                            ) : (
                                                                <p className="text-sm text-gray-500 italic">No content available</p>
                                                            )}
                                                        </div>
                                                    )}
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                )}
                            </div>

                            {/* Transcript area is handled in the section below */}

                            {/* Meeting Transcript */}
                            <div className={classNames(
                                "border border-gray-200 rounded-lg",
                                isTranscriptExpanded ? "fixed inset-0 z-50 bg-white overflow-hidden flex flex-col" : ""
                            )}>
                                <div className={classNames(
                                    "flex items-center justify-between",
                                    isTranscriptExpanded ? "p-4 border-b border-gray-200" : "p-4"
                                )}>
                                    <div className="flex items-center">
                                        <h3 className="text-sm font-medium text-gray-700">Meeting Transcript</h3>
                                    </div>

                                    <div className="flex items-center space-x-2">
                                        {meeting.transcript && (
                                            <div className="mr-2" title="Copy transcript to clipboard">
                                                <ClipboardCopy
                                                    text={meeting.transcript}
                                                    removeMarkdown={false}
                                                />
                                            </div>
                                        )}
                                        {isTranscriptExpanded ? (
                                            <button
                                                type="button"
                                                className="inline-flex items-center rounded-md border border-gray-300 bg-white px-3 py-1.5 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                                                onClick={() => setIsTranscriptExpanded(false)}
                                            >
                                                <ChevronDownIcon className="h-4 w-4 mr-1" />
                                                <span>Collapse</span>
                                            </button>
                                        ) : (
                                            <button
                                                type="button"
                                                className="inline-flex items-center rounded-md border border-gray-300 bg-white px-3 py-1.5 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                                                onClick={toggleTranscriptExpanded}
                                            >
                                                <ChevronUpIcon className="h-4 w-4 mr-1" />
                                                <span>Expand</span>
                                            </button>
                                        )}
                                    </div>
                                </div>

                                {/* Search Bar - Always at the top when transcript is expanded */}
                                {isTranscriptExpanded && (
                                    <div className="px-4 py-3 bg-white border-b border-gray-200">
                                        <div className="flex items-center space-x-4">
                                            <div className="relative flex-grow max-w-md">
                                                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                                    <SearchIcon className="h-4 w-4 text-gray-400" />
                                                </div>
                                                <input
                                                    type="text"
                                                    placeholder="Search transcript"
                                                    value={searchQuery}
                                                    onChange={(e) => setSearchQuery(e.target.value)}
                                                    className="block w-full pl-10 pr-3 py-1.5 border border-gray-300 text-sm rounded-md focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                                                />
                                            </div>

                                            {searchResults.count > 0 && (
                                                <div className="flex items-center space-x-2">
                                                    <span className="text-sm text-gray-500">
                                                        {searchResults.currentIndex + 1} of {searchResults.count} results
                                                    </span>
                                                    <div className="flex border border-gray-300 rounded-md">
                                                        <button
                                                            onClick={() => navigateSearchResults('prev')}
                                                            className="px-2 py-1 border-r border-gray-300 hover:bg-gray-100"
                                                        >
                                                            ↑
                                                        </button>
                                                        <button
                                                            onClick={() => navigateSearchResults('next')}
                                                            className="px-2 py-1 hover:bg-gray-100"
                                                        >
                                                            ↓
                                                        </button>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                )}

                                {isTranscriptExpanded ? (
                                    <div className="flex-1 overflow-y-auto p-4 bg-gray-50 rounded-lg custom-scrollbar-thick custom-scrollbar-gray">
                                        {(meeting.labeled_transcript || meeting.transcript) ? (
                                            <div
                                                ref={transcriptRef}
                                                className="text-sm text-gray-800 whitespace-pre-wrap"
                                                dangerouslySetInnerHTML={{ __html: transcriptContent }}
                                            />
                                        ) : (
                                            <p className="text-sm text-gray-500 italic">No transcript available for this meeting</p>
                                        )}
                                    </div>
                                ) : (
                                    <div className="px-4 pb-4">
                                        <p className="text-sm text-gray-500">
                                            {meeting.transcript
                                                ? "Click to view the full meeting transcript"
                                                : "No transcript available for this meeting"
                                            }
                                        </p>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>

                    {!isTranscriptExpanded && (
                        <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                            <button
                                type="button"
                                className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                                onClick={onClose}
                            >
                                Close
                            </button>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}