import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useAuth } from "../../context/auth-context";
import { ErrorBoundary } from "react-error-boundary";
import ErrorFallback from "../errorHandling/error-fallback-page";
import { useAuthCheck } from "../../context/auth-check-context";
import RouteLoadingSpinner from "../loading/RouteLoadingSpinner";

export default function ManagerRoute({ component: Component, ...rest }) {
    const { currentUser } = useAuth();
    const { isManager, loading } = useAuthCheck();

    if (loading) {
        return <RouteLoadingSpinner />;
    }

    return (
        <ErrorBoundary FallbackComponent={ErrorFallback}>
            <Route
                {...rest}
                render={(props) => (
                    <>
                        {currentUser && isManager ? (
                            <Component {...props} />
                        ) : (
                            <Redirect to="/login" />
                        )}
                    </>
                )}
            />
        </ErrorBoundary>
    );
}
