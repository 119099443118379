import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react';
import { SearchIcon } from '@heroicons/react/solid';
import { useSelector } from 'react-redux';
import { Transition } from '@headlessui/react';
import { API_AUTH_TYPES, API_ROUTES, ApiRequest } from '../../../../../api';
import { useQuery } from 'react-query';
import {
    CATEGORY_INFO,
} from './mentionCategories';

const AtMentionSystem = ({ query, setQuery, textAreaRef, mentionedResources }) => {
    const [showMentions, setShowMentions] = useState(false);
    const [mentionQuery, setMentionQuery] = useState('');
    const [mentionResults, setMentionResults] = useState([]);
    const [selectedCategoryType, setSelectedCategoryType] = useState(null);
    const [loading, setLoading] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState(0);
    const mentionRef = useRef(null);
    const [lastQueryLength, setLastQueryLength] = useState(0);

    // Store the full dataset for client-side filtering
    const [allMentionData, setAllMentionData] = useState({});
    const [initialDataLoaded, setInitialDataLoaded] = useState(false);

    // Track the position where we started typing an @ mention
    const [mentionStartPosition, setMentionStartPosition] = useState(null);

    // Helper to determine if we're currently typing a mention
    const isTypingMention = mentionStartPosition !== null;
    const parabotApi = new ApiRequest(API_ROUTES.PARABOT, API_AUTH_TYPES.ADVISOR);
    const { data: atComponentData, isLoading } = useQuery({
        queryKey: ['atComponentData'],
        queryFn: parabotApi.getFn({ endpoint: 'get_at_component' })
    });

    // Set the data directly from the API response
    useEffect(() => {
        if (atComponentData) {
            setAllMentionData(atComponentData);
            setInitialDataLoaded(true);
            setLoading(false);
        } else {
            setLoading(isLoading);
        }
    }, [atComponentData, isLoading]);

    // Process query text to detect if we're typing a mention
    const processMentionDetection = useCallback(() => {
        if (!textAreaRef.current) return;

        const cursorPosition = textAreaRef.current.selectionStart;
        const textBeforeCursor = query.substring(0, cursorPosition);

        // Only process if the query has changed and the user is actually typing
        // This prevents showing the dropdown on initial render
        if (query.length === lastQueryLength) return;
        setLastQueryLength(query.length);

        // Find the last @ character before cursor that isn't part of a completed mention
        const lastAtIndex = textBeforeCursor.lastIndexOf('@');

        // Check if we just typed @ (current position is right after @)
        if (lastAtIndex === cursorPosition - 1 && query.length > lastQueryLength) {
            // Only show mention when the user actively types @, not when loading existing text
            startMention(lastAtIndex);
            return;
        }

        // If no @ symbol is found or we're not in a current mention, don't proceed
        if (lastAtIndex === -1) {
            if (isTypingMention) {
                endMention();
            }
            return;
        }

        // Check if we have a space after the last @ before cursor
        // If we do, and it's not immediately after @, we're not in a mention
        const textAfterAt = textBeforeCursor.substring(lastAtIndex + 1);
        if (textAfterAt.includes(' ') || lastAtIndex === -1) {
            if (isTypingMention) {
                // We've exited a mention
                endMention();
            }
            return;
        }

        // We're continuing to type a mention that was already started
        if (isTypingMention) {
            // Update query for filtering
            setMentionQuery(textAfterAt);
        }
    }, [query, textAreaRef, lastQueryLength, isTypingMention]);

    // Start a mention sequence
    const startMention = (atPos) => {
        setMentionStartPosition(atPos);
        setShowMentions(true);
        setMentionQuery('');
        setSelectedIndex(0);
        setSelectedCategoryType(null);
    };

    // End a mention sequence
    const endMention = () => {
        setMentionStartPosition(null);
        setShowMentions(false);
        setMentionQuery('');
        setSelectedIndex(0);
    };

    // Client-side filtering for mentions
    useEffect(() => {
        if (!isTypingMention || !initialDataLoaded) return;

        // Filter the data based on mentionQuery and selectedCategoryType
        const filterData = () => {
            // If no query, show either category list or items from selected category
            if (!mentionQuery.trim()) {
                if (selectedCategoryType === null) {
                    // Show categories if no category is selected
                    setMentionResults([]);
                } else {
                    // Show all items from selected category
                    const categoryData = allMentionData[selectedCategoryType] || [];
                    setMentionResults(categoryData.slice(0, 50));
                }
                return;
            }

            // When searching, always search through ALL categories regardless of selection
            let results = [];

            // Search across all categories
            Object.values(allMentionData).forEach(categoryItems => {
                const filtered = categoryItems.filter(item =>
                    item.name.toLowerCase().includes(mentionQuery.trim().toLowerCase())
                );
                results = [...results, ...filtered];
            });

            // Sort results by relevance
            const sortedResults = results.sort((a, b) => {
                // Items that start with the query come first
                const aStartsWithQuery = a.name.toLowerCase().startsWith(mentionQuery.trim().toLowerCase()) ? -1 : 0;
                const bStartsWithQuery = b.name.toLowerCase().startsWith(mentionQuery.trim().toLowerCase()) ? -1 : 0;
                return aStartsWithQuery - bStartsWithQuery || a.name.localeCompare(b.name);
            });

            setMentionResults(sortedResults);
        };

        filterData();
    }, [isTypingMention, mentionQuery, selectedCategoryType, allMentionData, initialDataLoaded]);

    // Handle selecting a mention item
    const handleSelectMention = (item) => {
        if (!isTypingMention || !textAreaRef.current) return;

        // Calculate the part of the text before the @ symbol
        const beforeAtText = query.substring(0, mentionStartPosition);

        // Calculate the part of the text after the current mention query
        const cursorPosition = textAreaRef.current.selectionStart;
        const afterMentionText = query.substring(cursorPosition);

        // Create a reference to the selected item
        // Format: @[name](type:id)
        const mentionText = `@${item.name}`;

        // Combine all parts
        const newText = beforeAtText + mentionText + afterMentionText;

        // Update the query
        setQuery(newText);

        // Add item to mentionedResources if the hook is provided
        if (mentionedResources) {
            // Check if this item already exists in mentioned resources
            const existingIndex = mentionedResources.array.findIndex(
                res => res.id === item.id && res.type === item.type
            );

            // Only add if it doesn't already exist in the array
            if (existingIndex === -1) {
                mentionedResources.push(item);
            }
        }

        // End the mention sequence
        endMention();

        // Set focus back to textarea and place cursor after the mention
        setTimeout(() => {
            if (textAreaRef.current) {
                textAreaRef.current.focus();
                const newCursorPosition = beforeAtText.length + mentionText.length;
                textAreaRef.current.setSelectionRange(newCursorPosition, newCursorPosition);
            }
        }, 0);
    };

    // Keyboard navigation for mentions
    const handleMentionKeyDown = (e) => {
        if (!showMentions) return;

        // Get the current list of items (either categories or mention results)
        const isCategoryList = mentionResults.length === 0 && mentionQuery.trim() === '' && selectedCategoryType === null;
        const currentList = isCategoryList ? Object.keys(CATEGORY_INFO) : mentionResults;

        switch (e.key) {
            case 'ArrowDown':
                e.preventDefault();
                setSelectedIndex(prev =>
                    prev < currentList.length - 1 ? prev + 1 : prev
                );
                break;
            case 'ArrowUp':
                e.preventDefault();
                setSelectedIndex(prev => prev > 0 ? prev - 1 : 0);
                break;
            case 'Enter':
            case 'Tab':
                e.preventDefault();
                if (isCategoryList) {
                    // Select the category
                    const categoryKeys = Object.keys(CATEGORY_INFO);
                    if (selectedIndex >= 0 && selectedIndex < categoryKeys.length) {
                        handleSelectCategory(categoryKeys[selectedIndex]);
                    }
                } else if (mentionResults[selectedIndex]) {
                    // Select the mention item
                    handleSelectMention(mentionResults[selectedIndex]);
                }
                break;
            case 'Escape':
                e.preventDefault();
                endMention();
                break;
            default:
                break;
        }
    };

    // Filter category types
    const handleSelectCategory = (categoryType) => {
        // If selecting the same category again, clear it (toggle behavior)
        if (selectedCategoryType === categoryType) {
            setSelectedCategoryType(null);
        } else {
            setSelectedCategoryType(categoryType);
        }
        setSelectedIndex(0);

        // Reset the search query when changing categories
        setMentionQuery('');
    };

    // Listen for changes to query and detect @ mentions
    useEffect(() => {
        processMentionDetection();
    }, [query, processMentionDetection]);

    // Listen for keydown events for keyboard navigation
    useEffect(() => {
        const handleKeyDown = (e) => {
            if (isTypingMention) {
                handleMentionKeyDown(e);
            }
        };

        document.addEventListener('keydown', handleKeyDown);
        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [isTypingMention, mentionResults, selectedIndex]);

    // Close mentions dropdown when clicking outside
    useEffect(() => {
        const handleClickOutside = (e) => {
            if (mentionRef.current && !mentionRef.current.contains(e.target) &&
                textAreaRef.current && !textAreaRef.current.contains(e.target)) {
                endMention();
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <>
            {!showMentions && 
            <button
                onClick={() =>  setShowMentions(!showMentions)}
                className="flex items-center space-x-1 rounded-full bg-white py-1 px-2.5 text-sm font-semibold w-max text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
                @
            </button>
            }
            {showMentions &&
            <div
                ref={mentionRef}
                className=" z-[50] absolute bottom-0 left-24  bg-white rounded-md shadow-md overflow-hidden w-[300px] border border-gray-200"
            >
                <div className="p-2 border-b">
                    <div className="relative">
                        {selectedCategoryType !== null && (
                            <button
                                onClick={() => setSelectedCategoryType(null)}
                                className="absolute inset-y-0 left-0 pl-3 flex items-center text-gray-400 hover:text-gray-600"
                                aria-label="Back to categories"
                            >
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
                                </svg>
                            </button>
                        )}
                        <div className={`absolute inset-y-0 ${selectedCategoryType !== null ? 'left-8' : 'left-0'} pl-3 flex items-center pointer-events-none`}>
                            <SearchIcon className="h-4 w-4 text-gray-400" />
                        </div>
                        <input
                            type="text"
                            value={mentionQuery}
                            onChange={(e) => setMentionQuery(e.target.value)}
                            className={`block w-full ${selectedCategoryType !== null ? 'pl-16' : 'pl-10'} pr-3 py-2 text-sm border border-gray-300 rounded-md leading-5 bg-white placeholder-gray-500 focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500`}
                            placeholder="Search..."
                            autoFocus
                        />
                    </div>
                </div>

                <div className="h-[200px] overflow-y-auto">
                    {loading ? (
                        <div className="flex items-center justify-center h-full text-sm text-gray-500">
                            Loading...
                        </div>
                    ) : mentionResults.length === 0 && mentionQuery.trim() === '' && selectedCategoryType === null ? (
                        // Show only categories if no search query and no selected category
                        <ul role="listbox" className="py-1">
                            {Object.entries(CATEGORY_INFO).map(([categoryType, info], index) => (
                                <li
                                    key={categoryType}
                                    role="option"
                                    aria-selected={selectedIndex === index}
                                    className={`cursor-pointer px-4 py-2 text-sm ${selectedIndex === index ? 'bg-blue-100' : 'hover:bg-gray-100'}`}
                                    onClick={() => handleSelectCategory(categoryType)}
                                    onMouseEnter={() => setSelectedIndex(index)}
                                >
                                    <div className="flex items-center">
                                        <span className={`inline-flex items-center justify-center h-6 w-6 rounded-full ${info.iconBgColor} ${info.iconTextColor} mr-2`}>
                                            {info.icon}
                                        </span>
                                        <div className="font-medium">{info.label}</div>
                                    </div>
                                </li>
                            ))}
                        </ul>
                    ) : mentionResults.length === 0 ? (
                        <div className="flex items-center justify-center h-full text-sm text-gray-500">
                            No results found
                        </div>
                    ) : (
                        <ul role="listbox" className="py-1">
                            {mentionResults.map((item, index) => {
                                const categoryInfo = CATEGORY_INFO[item.type] || {
                                    icon: '?',
                                    iconBgColor: 'bg-gray-100',
                                    iconTextColor: 'text-gray-800',
                                    label: item.type || 'Unknown'
                                };

                                return (
                                    <li
                                        key={`${item.type}-${item.id}`}
                                        role="option"
                                        aria-selected={selectedIndex === index}
                                        className={`cursor-pointer px-4 py-2 text-sm ${selectedIndex === index ? 'bg-blue-100' : 'hover:bg-gray-100'
                                            }`}
                                        onClick={() => handleSelectMention(item)}
                                        onMouseEnter={() => setSelectedIndex(index)}
                                    >
                                        <div className="flex items-center">
                                            <span className={`inline-flex items-center justify-center h-6 w-6 rounded-full ${categoryInfo.iconBgColor} ${categoryInfo.iconTextColor} mr-2`}>
                                                {categoryInfo.icon}
                                            </span>
                                            <div>
                                                <div className="font-medium">{item.name}</div>
                                                <div className="text-xs text-gray-500">{categoryInfo.label}</div>
                                            </div>
                                        </div>
                                    </li>
                                );
                            })}
                        </ul>
                    )}
                </div>
            </div>
            }
        </>

    );
};

export default AtMentionSystem; 