import React, { useMemo, useState } from "react";
import MarkdownNotes from "../../../../../components/content/MarkdownNotes";
import ItemMenu from "../../../../../components/menus/ItemMenu";
import { dateFormatterGmailTime } from "../../../../../helpers/formatters";
import { patchReq } from "../../../../../apis/internalApi";
import { API } from "../../../../../constants/index";
import { useSelector } from "react-redux";
import { ArchiveIcon, PencilAltIcon, LinkIcon, ExternalLinkIcon, CalendarIcon } from "@heroicons/react/outline";
import EditNote from "./EditNote";
import NoteEdits from "./NoteEdits";
import RichTextDisplay from "../../../../../components/textEditor/RichTextDisplay";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { API_AUTH_TYPES, API_ROUTES } from "../../../../../api/apiConstants";
import ApiRequest from "../../../../../api/ApiRequest";
import MeetingDetails from "../../../household/HHMeetings/MeetingDetails";

const Note = ({ note, listQueryKey, advisors, meetings }) => {
  const [showEditNote, setShowEditNote] = useState(false);
  const [openEdits, setOpenEdits] = useState(false);
  const [showMeetingSelector, setShowMeetingSelector] = useState(false);
  const [showMeetingDetails, setShowMeetingDetails] = useState(false);

  const notesApi = new ApiRequest(
    API_ROUTES.HOUSEHOLD_NOTES,
    API_AUTH_TYPES.ADVISOR
  );

  const queryClient = useQueryClient();

  const { mutate: mutateHandleArchive } = useMutation(
    () =>
      notesApi.patch({
        endpoint: "archive_note",
        body: { id: note.household_note_id },
      }),
    { onSuccess: () => queryClient.invalidateQueries(listQueryKey) }
  );

  const { mutate: mutateAttachToMeeting } = useMutation(
    (meetingId) =>
      notesApi.patch({
        endpoint: "attach_to_meeting",
        body: {
          note_id: note.household_note_id,
          meeting_id: meetingId
        },
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(listQueryKey);
        setShowMeetingSelector(false);
      }
    }
  );

  const { mutate: mutateDetachFromMeeting } = useMutation(
    () =>
      notesApi.patch({
        endpoint: "detach_from_meeting",
        body: {
          note_id: note.household_note_id
        },
      }),
    {
      onSuccess: () => queryClient.invalidateQueries(listQueryKey)
    }
  );

  // Find attached meeting info
  const attachedMeeting = useMemo(() => {
    if (!note.meeting_id || !meetings) return null;
    return meetings.find(meeting => meeting.meeting_id === note.meeting_id);
  }, [note.meeting_id, meetings]);

  const lastEditedBy = useMemo(() => {
    if (!note.last_edited_by) return "Parabot"
    const advisor = advisors.find((advisor) => advisor.user_id === note.last_edited_by);
    if (!advisor) return "Parabot";

    return `${advisor?.first_name} ${advisor?.last_name}`;
  }, [advisors, note.last_edited_by]);

  const handleEditNote = async () => {
    setShowEditNote(true);
  };

  const handleAttachToMeeting = (meetingId) => {
    mutateAttachToMeeting(meetingId);
  };

  const handleShowMeetingDetails = () => {
    if (attachedMeeting) {
      setShowMeetingDetails(true);
    }
  };

  const menuOptions = [
    {
      title: "Edit",
      action: handleEditNote,
      icon: PencilAltIcon,
    },
    {
      title: "Archive",
      action: mutateHandleArchive,
      icon: ArchiveIcon,
    },
    {
      title: attachedMeeting ? "Unlink Meeting" : "Link Meeting",
      action: attachedMeeting ? mutateDetachFromMeeting : () => setShowMeetingSelector(true),
      icon: LinkIcon,
    }
  ];

  const menuOptionsNoEdit = [
    {
      title: "Archive",
      action: mutateHandleArchive,
      icon: ArchiveIcon,
    },
    {
      title: attachedMeeting ? "Unlink Meeting" : "Link Meeting",
      action: attachedMeeting ? mutateDetachFromMeeting : () => setShowMeetingSelector(true),
      icon: LinkIcon,
    }
  ];

  return (
    <>
      {openEdits && (
        <NoteEdits open={openEdits} setOpen={setOpenEdits} edits={note.edit_history} />
      )}

      <li key={note.household_note_id} className="py-4 w-full px-5">
        {!showEditNote ? (
          <div className="w-full">
            <div>
              <div className="flex space-x-3">
                <div className="flex-1 space-y-1">
                  <div className="flex justify-between">
                    <div className="flex flex-col w-full flex-wrap">
                      {" "}
                      {attachedMeeting && (
                        <button
                          onClick={handleShowMeetingDetails}
                          className="mt-2 flex mr-auto items-center text-sm text-blue-600 hover:text-blue-800 transition-colors px-2 py-1 rounded hover:bg-blue-50 border border-transparent hover:border-blue-100"
                        >
                          <CalendarIcon className="h-4 w-4 mr-2" />
                          <span>
                            {attachedMeeting.name || 'Meeting'} - {new Date(attachedMeeting.start_time).toLocaleDateString()}
                          </span>
                        </button>
                      )}
                      <div className="text-sm text-gray-900 w-full ">
                        {note.content && (
                          <div>
                            <RichTextDisplay
                              content={note.content}
                              thisClassName="markdown-chat"
                            />
                          </div>
                        )}
                        {note.body?.delta && (
                          <div>
                            <RichTextDisplay
                              content={{ ops: note.body?.delta }}
                              thisClassName="markdown-chat"
                            />
                          </div>
                        )}

                        {note.body?.blocks && (
                          <MarkdownNotes noteRaw={note.body} />
                        )}
                      </div>

                      {/* Display attached meeting info with button to open details */}


                      {note.edit_history && (
                        <div className="w-full text-blue-500 text-xs ">
                          <button
                            onClick={() => {
                              setOpenEdits(true);
                            }}
                            className=""
                          >
                            {note.edit_history.length > 1
                              ? `${note.edit_history.length} edits`
                              : "1 edit"}
                          </button>
                        </div>
                      )}
                    </div>
                    <div className="flex flex-col space-y-1 items-end sm:pl-10">
                      {" "}

                      <ItemMenu
                        options={note.content ? menuOptions : menuOptionsNoEdit}
                        id={note.household_note_id}
                      />
                      <p className="text-sm text-gray-600 w-max">
                        {dateFormatterGmailTime(
                          new Date(note.created_at || note.body?.time)
                        )}
                      </p>
                      <p className="w-max text-sm text-gray-600">{lastEditedBy}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <EditNote
            documentId={note.household_note_id}
            listQueryKey={listQueryKey}
            setOpen={setShowEditNote}
            open={showEditNote}
          />
        )}{" "}
      </li>

      {/* Meeting selector modal */}
      {showMeetingSelector && (
        <div className="fixed inset-0 z-50 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
              aria-hidden="true"
              onClick={() => setShowMeetingSelector(false)}>
            </div>

            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>

            <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
              <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                <h3 className="text-lg font-medium text-gray-900 mb-4">Attach Note to Meeting</h3>

                {meetings && meetings.length > 0 ? (
                  <div className="max-h-60 overflow-y-auto">
                    <ul className="divide-y divide-gray-200">
                      {meetings.map(meeting => (
                        <li key={meeting.meeting_id} className="py-3">
                          <button
                            onClick={() => handleAttachToMeeting(meeting.meeting_id)}
                            className="w-full text-left hover:bg-gray-50 p-2 rounded"
                          >
                            <p className="font-medium text-gray-900">{meeting.name || 'Meeting'}</p>
                            <p className="text-sm text-gray-500">
                              {new Date(meeting.start_time).toLocaleString()}
                            </p>
                          </button>
                        </li>
                      ))}
                    </ul>
                  </div>
                ) : (
                  <p className="text-gray-500">No meetings available</p>
                )}
              </div>

              <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                <button
                  type="button"
                  className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:mt-0 sm:w-auto sm:text-sm"
                  onClick={() => setShowMeetingSelector(false)}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* MeetingDetails modal */}
      {showMeetingDetails && attachedMeeting && (
        <MeetingDetails
          meetingId={attachedMeeting.meeting_id}
          onClose={() => setShowMeetingDetails(false)}
        />
      )}
    </>
  );
};

export default Note;
