import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { useQuery } from "react-query";
import Layout from "../../../components/layout/Layout";
import { API_AUTH_TYPES, API_ROUTES, ApiRequest } from "../../../api";
import FinancialStory from "./Financial-Story";
import HHName from "./HHName";
import HHReminders from "./HHReminders";
import Notes from "./Notes";
import CommandModule from "./CommandModule";
import HHEmails from "./HHEmails";
import HHMeetings from "./HHMeetings/index";
import useNav from "../../../hooks/useNav";
import HHSearch from "./HHSearch";
import Dashboard from "./Dashboard";
import Settings from "./Settings";
import HHMetrics from "./HHMetrics";
import { ChartBarIcon, PresentationChartBarIcon } from "@heroicons/react/outline";
import RiskTolerance from "./RiskTolerance";
import { useHouseholdAccess } from "./hooks/useHouseholdAccess";
import HHDocuments from "./HHDocuments";
import RouteLoadingSpinner from "../../../components/loading/RouteLoadingSpinner";

const HouseholdHome = () => {
  const params = useParams();
  const nav = useNav();
  const [module, setModule] = useState("Dashboard");

  // Check access control
  const { isLoading: isAccessLoading } = useHouseholdAccess(params.household_id);

  // Setup API instances
  const householdsApi = new ApiRequest(API_ROUTES.HOUSEHOLDS, API_AUTH_TYPES.ADVISOR);
  const usersApi = new ApiRequest(API_ROUTES.USERS, API_AUTH_TYPES.ADVISOR);
  const timersApi = new ApiRequest(API_ROUTES.TIMERS, API_AUTH_TYPES.ADVISOR);
  const youvestApi = new ApiRequest(API_ROUTES.YOUVEST, API_AUTH_TYPES.ADVISOR);

  // Fetch household data
  const { data: householdMeta, isLoading: isHouseholdLoading } = useQuery(
    ['household', params.household_id],
    () => householdsApi.get({
      endpoint: "details_households",
      params: [params.household_id]
    })
  );

  // Fetch users data
  const { data: contactMeta, isLoading: isUsersLoading } = useQuery(
    ['users', params.household_id],
    () => usersApi.get({
      endpoint: "users_details_household_id",
      params: [params.household_id]
    })
  );

  // Fetch timers data
  const { data: timers, isLoading: isTimersLoading } = useQuery(
    ['timers', params.household_id],
    () => timersApi.get({
      endpoint: "index_timers_household",
      params: [params.household_id]
    })
  );

  // Fetch YouVest data
  const { data: youvestData, isLoading: isYouvestLoading } = useQuery(
    ['invest_page', params.household_id],
    () => youvestApi.get({
      endpoint: "invest_page",
      params: [params.household_id]
    })
  );

  if (isAccessLoading || isHouseholdLoading || isUsersLoading || isTimersLoading) {
    return <RouteLoadingSpinner />;
  }

  return (
    <Layout columns={1} bgColor="bg-gray-100">
      <MainColumn
        householdMeta={householdMeta}
        module={module}
        setModule={setModule}
        contactMeta={contactMeta}
        timers={timers}
        nav={nav}
      />
    </Layout>
  );
};

const MainColumn = ({ householdMeta, module, setModule, contactMeta, timers, nav }) => {
  return (
    <>
      <div className="bg-white px-5 py-1 border-b hidden lg:flex justify-between">
        <HHName householdMeta={householdMeta} />
        <div className="flex items-center justify-center space-x-10">
          <button
            className="btn-md btn-gray-outline flex items-center space-x-1.5"
            onClick={() => nav(`/crm/youvest/${householdMeta.household_id}/My Portfolio`)}
          >
            <ChartBarIcon className="h-[18px] w-[18px] mt-0.5" />
            <p>YouVest</p>
          </button>
          <button
            className="btn-md btn-gray-outline flex items-center space-x-1.5"
            onClick={() => nav(`/crm/meeting-mode/${householdMeta.household_id}/financial-story`)}
          >
            <PresentationChartBarIcon className="h-[18px] w-[18px] mt-0.5" />
            <p>Meeting Mode</p>
          </button>
          <HHSearch />
        </div>
      </div>

      <div className="px-5 lg:px-10 mt-7 space-y-5 flex flex-col w-full items-center">
        <div className="w-full">
          <div className="lg:hidden mb-2">
            <HHName householdMeta={householdMeta} />
          </div>

          <CommandModule module={module} setModule={setModule} />
          <div className="py-2" />

          {/* Module Components */}
          {module === "Dashboard" && (
            <Dashboard contactMeta={contactMeta} householdMeta={householdMeta} />
          )}
          {module === "Financial Story" && <FinancialStory isMeetingMode={false} />}
          {module === "Notes" && <Notes />}
          {module === "Reminders" && <HHReminders timers={timers} />}
          {module === "Emails" && <HHEmails />}
          {module === "Meetings" && <HHMeetings />}
          {module === "Settings" && <Settings householdMeta={householdMeta} />}
          {module === "Risk Tolerance" && <RiskTolerance />}
          {module === "Metrics" && <HHMetrics householdMeta={householdMeta} />}
          {module === 'Vault' && <HHDocuments />}
        </div>
      </div>
    </>
  );
};

export default HouseholdHome;