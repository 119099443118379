import React from 'react'
import { TbMessagePlus } from 'react-icons/tb'
import FadeIn from '../../../../transitions/FadeIn';
import { classNames } from '../../../../misc/styling'

const NewConversation = ({ expanded, setConversationId, handleClearConversation }) => {
  return (
    <button
      onClick={() => {
        setConversationId(null);
        handleClearConversation()
      }}
      className={classNames(
        'group w-full flex items-center justify-between gap-2 px-3 py-2.5 rounded-lg transition-all duration-300',
        'bg-gradient-to-r from-blue-500 to-blue-600 hover:from-blue-600 hover:to-blue-700 shadow-sm hover:shadow',
        'text-white font-medium'
      )}
    >
      <FadeIn durationExit='duration-100' isShowing={expanded}>
        <span className="whitespace-nowrap text-sm font-medium truncate">New Chat</span>
      </FadeIn>
      <div className={classNames(
        'flex-shrink-0 rounded-full p-1 bg-blue-400 group-hover:bg-blue-500 transition-colors duration-200',
        !expanded && 'mx-auto'
      )}>
        <TbMessagePlus className='h-4 w-4 text-white' />
      </div>
    </button>
  )
}

export default NewConversation