import React from "react";
import { ErrorBoundary } from "react-error-boundary";
import { Redirect, Route } from "react-router-dom";
import { useAuth } from "../../context/auth-context";
import ErrorFallback from './../errorHandling/error-fallback-page';
import { useAuthCheck } from "../../context/auth-check-context";
import RouteLoadingSpinner from "../loading/RouteLoadingSpinner";

export default function AdminRoute({ component: Component, ...rest }) {
  const { currentUser } = useAuth();
  const { isAdmin, loading } = useAuthCheck();

  if (loading) {
    return <RouteLoadingSpinner />;
  }

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <Route
        {...rest}
        render={(props) => (
          <>
            {currentUser && isAdmin ? (
              <Component {...props} />
            ) : (
              <Redirect to="/login" />
            )}
          </>
        )}
      />
    </ErrorBoundary>
  );
}
