/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import ApiRequest from '../../../../../api/ApiRequest'
import { API_AUTH_TYPES, API_ROUTES } from '../../../../../api'
import BlankModal from '../../../../modals/BlankModal'
import { FaRegSave } from "react-icons/fa";
import { ToolTipBottom, ToolTipHoverContainer } from '../../../../ToolTips'
import { SaveIcon, TrashIcon } from '@heroicons/react/outline'
import { LuBrain } from 'react-icons/lu'
import SmallLoadingSpinner from '../../../../loading/SmallLoadingSpinner'



const parabotApi = new ApiRequest(API_ROUTES.PARABOT, API_AUTH_TYPES.ADVISOR)

const Memory = () => {
    const queryClient = useQueryClient()
    const [open, setOpen] = useState(false)

    const { data: memories, isLoading } = useQuery({
        queryKey: ['parabot-memories'],
        queryFn: parabotApi.getFn({
            endpoint: 'index_memories',
        })
    })

    const deleteMemory = useMutation(
        (id) => parabotApi.patch({
            endpoint: 'archive_memory',
            params: [id],
        }
        ),
        {
            onSuccess: () => {
                queryClient.invalidateQueries('parabot-memories')
            }
        }
    )



    useEffect(() => {
        queryClient.invalidateQueries('parabot-memories')
    }, [open])

    if (isLoading) return <div><SmallLoadingSpinner textColor='text-white'/></div>
    return (
        <div>
            <BlankModal open={open} setOpen={setOpen} >
                <h3 className='text-left'>Parabot Memories</h3>
                <p className="text-left text-sm text-gray-600">Parabot will become more helpful as you chat, picking up on details and preferences to tailor its responses to you.

                    To understand what Parabot remembers or teach it something new, just chat with it:</p>
                <ul className='divide-y divide-gray-300 border-gray-300 mt-5 border rounded-lg py-2 max-h-[500px] overflow-y-auto  custom-scrollbar-thick custom-scrollbar-gray'>
                    {memories && memories.map(memory => {
                        return (
                            <li key={memory.id} className='text-left text-sm text-gray-800 p-2 flex items-center justify-between'>
                                <p>{memory.content}</p>
                                <button onClick={() => { deleteMemory.mutate(memory.id) }}>
                                    <TrashIcon className='h-5 w-5 text-gray-500' />
                                </button>
                            </li>
                        )
                    })
                    }
                </ul>
            </BlankModal>
            <ToolTipHoverContainer>
                <ToolTipBottom>
                    <p>Manage Parabot Memories</p>
                </ToolTipBottom>
                <button
                    onClick={() => setOpen(true)}
                    className="bg-blue-600 hover:bg-blue-700 text-white rounded-md px-3 py-2 flex items-center transition-all duration-200 transform hover:scale-105 shadow-md"
                >
                    <SaveIcon className='h-5 w-5 mr-2 text-white' />
                    <span>Memories</span>
                </button>
            </ToolTipHoverContainer>

        </div>
    )
}

export default Memory