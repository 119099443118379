// AnnualChecklist.js

import React, { useState, useEffect } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { API_AUTH_TYPES, API_ROUTES, ApiRequest } from '../../../../../api';
import { useParams } from 'react-router-dom';
import DoubleClickEdit from '../../../../../components/DoubleClickEdit';
import FadeIn from '../../../../../components/transitions/FadeIn';
import { classNames } from '../../../../../components/misc/styling';
import DashboardPanel from '../../../../../components/panels/DashboardPanel';
import { PencilAltIcon } from '@heroicons/react/outline';
import ReactDatePicker from '../../../../../components/ReactDatePicker';

const AnnualChecklist = () => {
    const { household_id: householdId } = useParams();
    const householdsApi = new ApiRequest(
        API_ROUTES.HOUSEHOLDS,
        API_AUTH_TYPES.ADVISOR
    );
    const queryClient = useQueryClient();
    const [isEditing, setIsEditing] = useState(false);
    // Default items
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const isInTaxFilingPeriod = (() => {
        const month = currentDate.getMonth();
        const date = currentDate.getDate();
        // In January through March, always in filing period
        if (month >= 0 && month <= 2) return true;
        // In April, only until the 15th
        if (month === 3 && date <= 15) return true;
        return false;
    })();

    const defaultItems = [
        {
            id: `took-rmd-${currentYear}`,
            name: 'Took RMD',
            default_reset_date: '12-31',
            tax_deadline: false,
            display_name: 'Took RMD',
            group_id: 'took-rmd'
        },
        {
            id: `took-inherited-rmd-${currentYear}`,
            name: "Took Inherited RMD",
            default_reset_date: '12-31',
            tax_deadline: false,
            display_name: 'Took Inherited RMD',
            group_id: 'took-inherited-rmd'
        },
        {
            id: `fsa-spend-${currentYear}`,
            name: 'FSA Spend',
            default_reset_date: '12-31',
            tax_deadline: false,
            display_name: 'FSA Spend',
            group_id: 'fsa-spend'
        },
        {
            id: `recertified-income-${currentYear}`,
            name: 'Recertified Income for Student Loans',
            default_reset_date: '12-31',
            tax_deadline: false,
            display_name: 'Recertified Income for Student Loans',
            group_id: 'recertified-income'
        },
        {
            id: `filed-taxes-${currentYear}`,
            name: 'Filed Taxes',
            default_reset_date: '04-15',
            tax_deadline: true,
            display_name: 'Filed Taxes',
            group_id: 'filed-taxes'
        },
        {
            id: `enrolled-benefits-${currentYear}`,
            name: 'Enrolled in Workplace Benefits',
            default_reset_date: '11-01',
            tax_deadline: false,
            display_name: 'Enrolled in Workplace Benefits',
            group_id: 'enrolled-benefits'
        },
        {
            id: `checked-beneficiaries-${currentYear}`,
            name: 'Checked Beneficiaries',
            default_reset_date: '12-31',
            tax_deadline: false,
            display_name: 'Checked Beneficiaries',
            group_id: 'checked-beneficiaries'
        },
        // Current year funding items
        {
            id: `funded-hsa-curr-${currentYear}`,
            name: 'Funded HSA',
            default_reset_date: '04-15',
            tax_deadline: true,
            is_funding_item: true,
            tax_year: currentYear,
            previous_year_item: false,
            display_name: 'Funded HSA',
            group_id: 'funded-hsa'
        },
        // Previous year funding item (only during tax filing period)
        ...(isInTaxFilingPeriod ? [{
            id: `funded-hsa-prev-${currentYear - 1}`,
            name: 'Funded HSA',
            default_reset_date: '04-15',
            tax_deadline: true,
            is_funding_item: true,
            tax_year: currentYear - 1,
            previous_year_item: true,
            display_name: 'Funded HSA',
            group_id: 'funded-hsa'
        }] : []),
        {
            id: `funded-401k-${currentYear}`,
            name: 'Funded 401(k)',
            default_reset_date: '12-31',
            tax_deadline: false,
            is_funding_item: false,
            display_name: 'Funded 401(k)',
            group_id: 'funded-401k'
        },
        // Current year Roth IRA
        {
            id: `roth-ira-curr-${currentYear}`,
            name: 'Roth IRA Funded',
            default_reset_date: '04-15',
            tax_deadline: true,
            is_funding_item: true,
            tax_year: currentYear,
            previous_year_item: false,
            display_name: 'Roth IRA Funded',
            group_id: 'roth-ira'
        },
        // Previous year Roth IRA (only during tax filing period)
        ...(isInTaxFilingPeriod ? [{
            id: `roth-ira-prev-${currentYear - 1}`,
            name: 'Roth IRA Funded',
            default_reset_date: '04-15',
            tax_deadline: true,
            is_funding_item: true,
            tax_year: currentYear - 1,
            previous_year_item: true,
            display_name: 'Roth IRA Funded',
            group_id: 'roth-ira'
        }] : []),
        // Current year Backdoor Roth IRA
        {
            id: `backdoor-roth-curr-${currentYear}`,
            name: 'Backdoor Roth IRA',
            default_reset_date: '04-15',
            tax_deadline: true,
            is_funding_item: true,
            tax_year: currentYear,
            previous_year_item: false,
            display_name: 'Backdoor Roth IRA',
            group_id: 'backdoor-roth'
        },
        // Previous year Backdoor Roth IRA (only during tax filing period)
        ...(isInTaxFilingPeriod ? [{
            id: `backdoor-roth-prev-${currentYear - 1}`,
            name: 'Backdoor Roth IRA',
            default_reset_date: '04-15',
            tax_deadline: true,
            is_funding_item: true,
            tax_year: currentYear - 1,
            previous_year_item: true,
            display_name: 'Backdoor Roth IRA',
            group_id: 'backdoor-roth'
        }] : []),
        {
            id: `solo-401k-employee-curr-${currentYear}`,
            name: 'Solo 401(k) Employee',
            default_reset_date: '04-15',
            tax_deadline: true,
            is_funding_item: true,
            tax_year: currentYear,
            previous_year_item: false,
            extension_eligible: true,
            display_name: 'Solo 401(k) Employee',
            group_id: 'solo-401k-employee'
        },
        // Previous year Solo 401(k) Employee (only during tax filing period)
        ...(isInTaxFilingPeriod ? [{
            id: `solo-401k-employee-prev-${currentYear - 1}`,
            name: 'Solo 401(k) Employee',
            default_reset_date: '04-15',
            tax_deadline: true,
            is_funding_item: true,
            tax_year: currentYear - 1,
            previous_year_item: true,
            extension_eligible: true,
            display_name: 'Solo 401(k) Employee',
            group_id: 'solo-401k-employee'
        }] : []),

        // Current year Solo 401(k) Employer
        {
            id: `solo-401k-employer-curr-${currentYear}`,
            name: 'Solo 401(k) Employer',
            default_reset_date: '04-15',
            tax_deadline: true,
            is_funding_item: true,
            tax_year: currentYear,
            previous_year_item: false,
            display_name: 'Solo 401(k) Employer',
            extension_eligible: true,
            group_id: 'solo-401k-employer'
        },
        // Previous year Solo 401(k) Employer (only during tax filing period)
        ...(isInTaxFilingPeriod ? [{
            id: `solo-401k-employer-prev-${currentYear - 1}`,
            name: 'Solo 401(k) Employer',
            default_reset_date: '04-15',
            tax_deadline: true,
            is_funding_item: true,
            tax_year: currentYear - 1,
            previous_year_item: true,
            display_name: 'Solo 401(k) Employer',
            extension_eligible: true,
            group_id: 'solo-401k-employer'
        }] : []),
        {
            id: `invest-rsus-${currentYear}`,
            name: 'Invest Vested RSUs',
            default_reset_date: '12-31',
            tax_deadline: false,
            display_name: 'Invest Vested RSUs',
            group_id: 'invest-rsus'
        },
        // Current year SEP IRA
        {
            id: `sep-ira-curr-${currentYear}`,
            name: 'SEP IRA Funded',
            default_reset_date: '04-15',
            tax_deadline: true,
            is_funding_item: true,
            tax_year: currentYear,
            previous_year_item: false,
            display_name: 'SEP IRA Funded',
            extension_eligible: true,
            group_id: 'sep-ira'
        },
        // Previous year SEP IRA (only during tax filing period)
        ...(isInTaxFilingPeriod ? [{
            id: `sep-ira-prev-${currentYear - 1}`,
            name: 'SEP IRA Funded',
            default_reset_date: '04-15',
            tax_deadline: true,
            is_funding_item: true,
            tax_year: currentYear - 1,
            previous_year_item: true,
            display_name: 'SEP IRA Funded',
            extension_eligible: true,
            group_id: 'sep-ira'
        }] : [])
    ].flat(); // Flatten the array since we used spread operators

    // Function to format the reset date into text like 'Jan 1st'
    const getOrdinalSuffix = (day) => {
        if (day > 3 && day < 21) return 'th'; // 4th - 20th
        switch (day % 10) {
            case 1:
                return 'st';
            case 2:
                return 'nd';
            case 3:
                return 'rd';
            default:
                return 'th';
        }
    };

    const formatResetDate = (dateStr) => {
        const [month, day] = dateStr.split('-').map(Number);
        const date = new Date(2020, month - 1, day); // Year doesn't matter here
        const monthName = date.toLocaleString('en-US', { month: 'short' });
        const dayWithSuffix = `${day}${getOrdinalSuffix(day)}`;
        return `${monthName} ${dayWithSuffix}`;
    };

    // Function to check if extension is filed for a specific year
    const isExtensionFiledForYear = (year) => {
        return taxDeadlineData?.extension_filed?.[year] === true;
    };

    // Update handleExtensionChange to take the specific year
    const handleExtensionChange = (e, year) => {
        const isChecked = e.target.checked;
        console.log('Extension checkbox clicked:', isChecked);
        console.log('Extension year:', year);

        // Create new extension_filed object
        const newExtensionFiled = {
            ...(taxDeadlineData?.extension_filed || {}),
            [year]: isChecked
        };

        // If unchecking, remove the year entry
        if (!isChecked) {
            delete newExtensionFiled[year];
        }

        updateTaxDeadlineMutation.mutate({
            extension_filed: newExtensionFiled
        });
    };

    // Get the appropriate deadline based on extension eligibility and if extension is filed for the tax year
    const hasValidExtension = (item) => {
        return item.extension_eligible &&
            isExtensionFiledForYear(item.tax_year);
    };

    // Function to compute the next upcoming reset date for sorting
    const computeNextResetDate = (item) => {
        const currentDate = new Date();
        console.log(`Computing next reset date for ${item.name}`);
        console.log(`Current date: ${currentDate.toISOString()}`);
        console.log(`Item reset_date: ${item.reset_date}`);
        console.log(`Is tax deadline item? ${item.tax_deadline}`);

        const [month, day] = item.reset_date.split('-').map(Number);

        if (item.tax_deadline) {
            // For tax deadline items, we need to handle them differently
            const currentYear = currentDate.getFullYear();
            console.log(`Current year: ${currentYear}`);

            // The deadline for a tax year is in the following year
            // For example, 2024 tax items are due in 2025
            const isInTaxFilingPeriod = currentDate.getMonth() >= 0 && currentDate.getMonth() <= 3 && currentDate.getDate() <= 15;
            console.log(`Is in tax filing period (Jan-Apr 15)? ${isInTaxFilingPeriod}`);

            const taxYear = isInTaxFilingPeriod
                ? currentYear - 1  // If we're in Jan-Apr 15, we're dealing with previous year's taxes
                : currentYear;     // Otherwise, we're dealing with current year's taxes

            console.log(`Tax year being considered: ${taxYear}`);

            const validExtension = hasValidExtension(item);

            const deadlineDate = new Date(taxYear + 1,
                validExtension ? 9 : 3, // Use October (9) for extended or April (3) for default
                15
            );
            console.log(`Initial deadline date: ${deadlineDate.toISOString()}`);

            // If we've passed this year's deadline, look at next year's
            if (deadlineDate < currentDate) {
                const nextDeadline = new Date(taxYear + 2,
                    validExtension ? 9 : 3,
                    15
                );
                console.log(`Passed current deadline, next deadline: ${nextDeadline.toISOString()}`);
                return nextDeadline;
            }

            console.log(`Using current deadline: ${deadlineDate.toISOString()}`);
            return deadlineDate;
        } else {
            // For non-tax deadline items, keep the original logic
            console.log('Processing non-tax deadline item');
            const resetDateThisYear = new Date(
                currentDate.getFullYear(),
                month - 1,
                day
            );
            console.log(`Reset date this year: ${resetDateThisYear.toISOString()}`);

            if (resetDateThisYear >= currentDate) {
                console.log(`Using this year's date: ${resetDateThisYear.toISOString()}`);
                return resetDateThisYear;
            } else {
                const nextYearDate = new Date(currentDate.getFullYear() + 1, month - 1, day);
                console.log(`Using next year's date: ${nextYearDate.toISOString()}`);
                return nextYearDate;
            }
        }
    };

    // Function to compute the last reset date
    const getLastResetDate = (item) => {
        const currentDate = new Date();
        const [month, day] = item.reset_date.split('-').map(Number);
        let resetDate = new Date(currentDate.getFullYear(), month - 1, day);
        if (resetDate > currentDate) {
            // Reset date is in the future; last reset date was last year
            resetDate = new Date(currentDate.getFullYear() - 1, month - 1, day);
        }
        return resetDate;
    };

    // State to manage showing hidden items
    const [showHiddenItems, setShowHiddenItems] = useState(false);

    // Fetch data from the server
    const {
        data: serverData,
        isLoading,
        isError,
    } = useQuery(['annualChecklist', householdId], () =>
        householdsApi.get({
            endpoint: 'annual_checklist',
            params: [householdId],
        })
    );

    // Local state to manage the checklist
    const [checklist, setChecklist] = useState([]);

    // Fetch tax deadline
    const {
        data: taxDeadlineData,
        isLoading: taxDeadlineLoading,
    } = useQuery(['taxDeadline', householdId], () =>
        householdsApi.get({
            endpoint: 'tax_deadline',
            params: [householdId],
        })
    );

    // Mutation for updating tax deadline
    const updateTaxDeadlineMutation = useMutation(
        (extensionData) =>
            householdsApi.patch({
                endpoint: 'tax_deadline',
                params: [householdId],
                body: {
                    extension_filed: extensionData.extension_filed,
                    extension_year: extensionData.extension_year
                },
            }),
        {
            onSuccess: () => {
                console.log('Tax deadline update successful');
                queryClient.invalidateQueries(['taxDeadline', householdId]);
            },
            onError: (error) => {
                console.error('Tax deadline update failed:', error);
            }
        }
    );

    // Function to get the appropriate reset date based on extension status
    const getAppropriateResetDate = (item) => {
        if (isUsingExtension(item)) {
            return '10-15'; // October 15th for extended items
        }
        return item.default_reset_date;
    };

    // Effect to merge default items with server data and sort by upcoming reset date
    useEffect(() => {
        if (serverData && serverData) {
            const serverItems = serverData;
            console.log('Merging server data with default items:', { serverItems });

            const mergedItems = defaultItems.map(item => {
                const serverItem = serverItems.find(si => si.id === item.id);
                console.log(`Processing item: ${item.name}`, { serverItem });

                const mergedItem = {
                    ...item,
                    ...serverItem,
                    reset_date: getAppropriateResetDate(item), // Use the appropriate reset date
                    hidden: serverItem ? serverItem.hidden : false,
                    date_last_completed: serverItem ? serverItem.date_last_completed : null,
                };
                mergedItem.nextResetDate = computeNextResetDate(mergedItem);
                return mergedItem;
            });

            // Sort the items by upcoming reset date
            mergedItems.sort((a, b) => a.nextResetDate - b.nextResetDate);
            console.log('Final sorted items:', mergedItems.map(item => ({
                name: item.display_name,
                nextResetDate: item.nextResetDate.toISOString(),
                tax_deadline: item.tax_deadline,
                tax_year: item.tax_year
            })));

            setChecklist(mergedItems);
        } else {
            console.log('No server data, initializing with default items');
            // If data is null, initialize checklist with default items
            const initialItems = defaultItems.map(item => {
                const initialItem = {
                    ...item,
                    reset_date: getAppropriateResetDate(item), // Use the appropriate reset date
                    hidden: false,
                    date_last_completed: null,
                };
                initialItem.nextResetDate = computeNextResetDate(initialItem);
                return initialItem;
            });

            // Sort the items by upcoming reset date
            initialItems.sort((a, b) => a.nextResetDate - b.nextResetDate);
            console.log('Initial sorted items:', initialItems.map(item => ({
                name: item.display_name,
                nextResetDate: item.nextResetDate.toISOString(),
                tax_deadline: item.tax_deadline,
                tax_year: item.tax_year
            })));

            setChecklist(initialItems);
        }
    }, [serverData, taxDeadlineData]);

    // Update items when extension status changes
    useEffect(() => {
        if (checklist.length > 0) {
            const updatedChecklist = checklist.map(item => ({
                ...item,
                reset_date: getAppropriateResetDate(item)
            }));
            setChecklist(updatedChecklist);
            // Update the server with the new reset dates
            updateMutation.mutate(updatedChecklist);
        }
    }, [taxDeadlineData?.extension_filed]);

    // Updated function to determine if item is completed
    const isItemCompleted = (item) => {
        // If there's no completion date, it can't be completed
        if (!item.date_last_completed) return false;

        const lastResetDate = getLastResetDate(item);
        const lastCompletedDate = new Date(item.date_last_completed);

        // For funding items or tax year items, we only care about whether it's been completed at all
        // We don't check against reset dates since these are yearly items
        if ((item.is_funding_item || item.tax_deadline) && item.tax_year) {
            return true; // If it has a completion date, consider it completed
        }

        // For regular items, keep the original logic
        return lastCompletedDate >= lastResetDate;
    };

    // Mutation to update the checklist with optimistic updates for checkbox
    const updateCheckboxMutation = useMutation(
        ({ updatedChecklist }) =>
            householdsApi.patch({
                endpoint: 'annual_checklist',
                params: [householdId],
                body: {
                    annual_checklist: updatedChecklist,
                },
            }),
        {
            onMutate: async ({ updatedChecklist }) => {
                // Cancel any outgoing refetches
                await queryClient.cancelQueries(['annualChecklist', householdId]);

                // Snapshot the previous value
                const previousChecklist = [...checklist];

                // Optimistically update local state
                setChecklist(updatedChecklist);

                // Return context with previous value
                return { previousChecklist };
            },
            onError: (err, variables, context) => {
                console.error('Error updating checklist:', err);
                // Rollback to previous value
                setChecklist(context.previousChecklist);
            },
            onSettled: () => {
                // Optionally refetch after error or success
                queryClient.invalidateQueries(['annualChecklist', householdId]);
            },
        }
    );

    // Handler for checkbox change with optimistic updating
    const handleCheckboxChange = (itemId) => {
        const index = checklist.findIndex((item) => item.id === itemId);
        if (index === -1) return;

        const isCompleted = isItemCompleted(checklist[index]);
        const currentDate = new Date().toISOString().split('T')[0];

        // Prepare updated item
        const updatedItem = {
            ...checklist[index],
            date_last_completed: isCompleted ? null : currentDate,
        };

        // Create updated checklist
        const updatedChecklist = [...checklist];
        updatedChecklist[index] = updatedItem;

        updateCheckboxMutation.mutate({ updatedChecklist });
    };

    // Mutation to update other changes (reset date, hiding items)
    const updateMutation = useMutation(
        (updatedAnnualChecklist) =>
            householdsApi.patch({
                endpoint: 'annual_checklist',
                params: [householdId],
                body: {
                    annual_checklist: updatedAnnualChecklist,
                },
            }),
        {
            onSuccess: () => {
                // Optionally refetch or invalidate queries
                queryClient.invalidateQueries(['annualChecklist', householdId]);
            },
        }
    );

    // Handler for hiding an item
    const handleHideItem = (itemId) => {
        // Get the item being toggled
        const targetItem = checklist.find(item => item.id === itemId);
        if (!targetItem) return;

        // Get the group_id
        const groupId = targetItem.group_id;

        // Update all items with the same group_id
        const updatedChecklist = checklist.map(item => {
            if (item.group_id === groupId) {
                return {
                    ...item,
                    hidden: !targetItem.hidden
                };
            }
            return item;
        });

        setChecklist(updatedChecklist);
        updateMutation.mutate(updatedChecklist);
    };

    // Handler for changing reset date
    const handleResetDateChange = (itemId, newDate) => {
        const index = checklist.findIndex((item) => item.id === itemId);
        if (index === -1) return;

        const updatedChecklist = [...checklist];
        updatedChecklist[index].reset_date = newDate;

        // Recompute the next reset date
        updatedChecklist[index].nextResetDate = computeNextResetDate(
            updatedChecklist[index]
        );

        // Re-sort the checklist after date change
        updatedChecklist.sort((a, b) => a.nextResetDate - b.nextResetDate);

        setChecklist(updatedChecklist);
        updateMutation.mutate(updatedChecklist);
    };

    // State to manage which item is being edited
    const [editingItemName, setEditingItemName] = useState(null);

    // Group checklist items by the year of their nextResetDate
    const groupedChecklist = checklist.reduce((groups, item) => {
        if (item.hidden) return groups; // Exclude hidden items from main list

        let year;
        if (item.is_funding_item && item.tax_year) {
            // For funding items, use their specific tax year
            year = item.tax_year;
        } else if (item.tax_deadline) {
            // For regular tax deadline items, use the year before the deadline
            year = item.nextResetDate.getFullYear() - 1;
        } else {
            // For non-tax deadline items, use the actual reset date year
            year = item.nextResetDate.getFullYear();
        }

        if (!groups[year]) {
            groups[year] = [];
        }
        groups[year].push(item);
        return groups;
    }, {});

    // Function to get display text for an item
    const getItemDisplayText = (item) => {
        if (item.is_funding_item) {
            return `${item.display_name} (${item.tax_year})`;
        }
        return item.display_name;
    };

    // Function to check if an item is using extension deadline
    const isUsingExtension = (item) => {
        return item.extension_eligible &&
            isExtensionFiledForYear(item.tax_year) &&
            item.tax_deadline;
    };

    if (isLoading) {
        return (
            <div className="text-center py-10">
                <span>Loading...</span>
            </div>
        );
    }

    if (isError) {
        return (
            <div className="text-center py-10 text-red-500">
                <span>Error loading data.</span>
            </div>
        );
    }

    return (
        <DashboardPanel title='Annual Checklist'
            SecondaryTitleComponent={<button
                className='btn-md btn-gray-outline flex items-center space-x-2'
                onClick={() => setIsEditing(!isEditing)}
            >
                <p>
                    {isEditing ? 'Save Changes' : 'Edit Checklist'}
                </p> <PencilAltIcon className='h-5 w-5' />
            </button>}
        >
            <div className="">
                {Object.keys(groupedChecklist)
                    .sort((a, b) => a - b) // Sort years in ascending order
                    .map((year, index) => (
                        <div key={year}>
                            {/* Year Separator with Extension Checkbox */}
                            <div className="flex items-center justify-between mb-2">
                                <h2 className="text-lg font-semibold">{year}</h2>
                                <div className="flex items-center space-x-4">

                                    <div className="flex items-center space-x-2">
                                        <input
                                            type="checkbox"
                                            id={`extension-filed-${year}`}
                                            checked={isExtensionFiledForYear(parseInt(year))}
                                            onChange={(e) => handleExtensionChange(e, parseInt(year))}
                                            className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
                                        />
                                        <label htmlFor={`extension-filed-${year}`} className="text-sm text-gray-500">
                                            Filed Extension
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div className="space-y-2">
                                {groupedChecklist[year].map((item) => (
                                    <div
                                        key={item.id}
                                        className="flex flex-col border-b pb-2"
                                    >
                                        <div className="flex items-center justify-between">
                                            <div className="flex items-center space-x-2">
                                                <button
                                                    onClick={() => handleCheckboxChange(item.id)}
                                                    className={classNames(
                                                        'btn-sm border rounded-md font-semibold',
                                                        isItemCompleted(item) && 'bg-green-100'
                                                    )}
                                                >
                                                    {getItemDisplayText(item)}
                                                </button>
                                                {isUsingExtension(item) && (
                                                    <div className=" flex items-center space-x-2">
                                                        <span className="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-blue-100 text-blue-800">
                                                            Extended
                                                        </span>

                                                    </div>
                                                )}
                                            </div>
                                            {isEditing ? (
                                                <button
                                                    className="text-sm text-gray-500 hover:text-gray-700"

                                                    onClick={() => handleHideItem(item.id)}
                                                >
                                                    {item.hidden ? 'Unhide' : 'Hide'}
                                                </button>
                                            ) : (
                                                <DoubleClickEdit
                                                    defaultComponent={
                                                        <div className="text-sm text-gray-500">
                                                            {formatResetDate(item.reset_date)}
                                                        </div>
                                                    }
                                                    editComponent={
                                                        <ReactDatePicker
                                                            selected={new Date(
                                                                new Date().getFullYear(),
                                                                parseInt(item.reset_date.split('-')[0], 10) - 1,
                                                                parseInt(item.reset_date.split('-')[1], 10)
                                                            )} onChange={(date) => {
                                                                if (date) {
                                                                    const month = String(date.getMonth() + 1).padStart(2, '0');
                                                                    const day = String(date.getDate()).padStart(2, '0');
                                                                    const newDate = `${month}-${day}`;
                                                                    handleResetDateChange(item.id, newDate);
                                                                }
                                                            }}
                                                            className="input-primary mr-auto"
                                                            dateFormat="MM-dd"
                                                            placeholderText="Select date"
                                                        />
                                                    }
                                                    handleSubmitEdit={() => {
                                                        setEditingItemName(null);
                                                    }}
                                                    componentName={`resetDate-${item.id}`}
                                                    showEdit={editingItemName === item.id}
                                                    setShowEdit={(show) => {
                                                        if (show) {
                                                            setEditingItemName(item.id);
                                                        } else {
                                                            setEditingItemName(null);
                                                        }
                                                    }}
                                                    toolTipPosition="bottom"
                                                />
                                            )}
                                        </div>

                                    </div>
                                ))}
                            </div>
                        </div>
                    ))}
            </div>

            {/* Hidden Items */}
            {checklist.some((item) => item.hidden) && (
                <div className="mt-6">
                    <button
                        className="text-sm text-gray-500 hover:text-gray-700 mb-2"
                        onClick={() => setShowHiddenItems(!showHiddenItems)}
                    >
                        {showHiddenItems ? 'Hide Hidden Items' : 'Show Hidden Items'}
                    </button>
                    <FadeIn isShowing={showHiddenItems}>
                        <div className="space-y-2">
                            {/* Group hidden items by group_id and display once */}
                            {Array.from(new Set(checklist.filter(item => item.hidden).map(item => item.group_id)))
                                .map(groupId => {
                                    const items = checklist.filter(item => item.group_id === groupId && item.hidden);
                                    if (items.length === 0) return null;

                                    // Use the display name from the first item
                                    const displayName = items[0].display_name;

                                    return (
                                        <div
                                            key={groupId}
                                            className="flex items-center justify-between border-b pb-2"
                                        >
                                            <div className="text-sm font-semibold">{displayName}</div>
                                            <button
                                                className="text-sm text-gray-500 hover:text-gray-700"
                                                onClick={() => handleHideItem(items[0].id)}
                                            >
                                                Unhide
                                            </button>
                                        </div>
                                    );
                                })}
                        </div>
                    </FadeIn>
                </div>
            )}
        </DashboardPanel>);
};

export default AnnualChecklist;
