import React, { Fragment } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { LightningBoltIcon, AcademicCapIcon, SparklesIcon, LightBulbIcon } from "@heroicons/react/outline";
import { classNames } from '../../../../misc/styling';


const thinkingModes = [
    {
        id: 'faster',
        name: "Faster",
        icon: LightningBoltIcon,
        description: "Faster responses with minimal thinking"
    },
    {
        id: 'smarter',
        name: "Thinking",
        icon: AcademicCapIcon,
        description: "More thorough analysis for complex questions"
    },
    {
        id: 'auto',
        name: "Auto",
        icon: SparklesIcon,
        description: "Let AI decide when to think deeply"
    },
];

export default function ThinkingModeMenu({ thinkingMode, setThinkingMode }) {
    // Get the current mode object
    const currentMode = thinkingModes.find(mode => mode.id === thinkingMode) || thinkingModes[2]; // Default to auto

    return (
        <Menu as="div" className="relative inline-block text-left">
            <div>
                <Menu.Button className="flex p-2 items-center border border-gray-300 justify-center rounded-full text-gray-400 hover:text-gray-500  hover:bg-gray-100 focus:outline-none">

                    <LightBulbIcon className="text-gray-500 h-6 w-6" />
                    <p className="text-base ml-2 font-medium text-gray-500">{currentMode.name}</p>
                    <span className="sr-only">Thinking mode: {currentMode.name}</span>
                </Menu.Button>
            </div>

            <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
            >
                <Menu.Items className="absolute left-0 bottom-full mb-2 w-56 origin-bottom-left rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none divide-y divide-gray-100">
                    <div className="px-4 py-2 bg-gray-50 rounded-t-md">
                        <p className="text-xs font-medium text-gray-500">Thinking Mode</p>
                    </div>
                    <div className="py-1 text-left">
                        {thinkingModes.map((mode) => (
                            <Menu.Item key={mode.id}>
                                {({ active }) => (
                                    <button
                                        onClick={() => setThinkingMode(mode.id)}
                                        className={classNames(
                                            active ? 'bg-gray-50' : '',
                                            mode.id === thinkingMode ? 'bg-blue-50' : '',
                                            'flex items-center px-4 py-3 w-full hover:bg-gray-50 transition-colors border-b border-gray-100 last:border-b-0'
                                        )}
                                    >
                                        <div className={classNames(
                                            'p-2 rounded-lg mr-3 flex-shrink-0',
                                            mode.id === thinkingMode ? 'bg-blue-100' : 'bg-gray-100'
                                        )}>
                                            <mode.icon
                                                className={classNames(
                                                    'h-5 w-5',
                                                    mode.id === thinkingMode ? 'text-blue-600' : 'text-gray-500'
                                                )}
                                                aria-hidden="true"
                                            />
                                        </div>
                                        <div className="flex flex-col items-start text-left">
                                            <span className={classNames(
                                                'font-medium text-sm',
                                                mode.id === thinkingMode ? 'text-blue-800' : 'text-gray-900'
                                            )}>
                                                {mode.name}
                                            </span>
                                            <span className="text-xs text-gray-600 mt-1">{mode.description}</span>
                                        </div>
                                    </button>
                                )}
                            </Menu.Item>
                        ))}
                    </div>
                </Menu.Items>
            </Transition>
        </Menu>

    );
} 