import React from 'react';
import { classNames } from '../../../misc/styling';
import { TableIcon } from '@heroicons/react/outline';
import { LuFileSpreadsheet } from "react-icons/lu";

function ImageGallery({ files, removeFile, removable = true, imageSize = 'h-16 w-16', openExpandedImage }) {
    // Calculate the number of images exceeding the first 5
    const extraImagesCount = files?.length > 5 ? files?.length - 5 : 0;

    // Helper function to get the correct URL from a file object
    const getFileUrl = (file) => {
        // Handle different URL formats - prioritize signed_url from document
        if (file.document && file.document.signed_url) {
            return file.document.signed_url;
        } else if (file.url) {
            return file.url;
        } else if (typeof file === 'string') {
            return file;
        }
        return '';
    };

    // Helper function to get the file name
    const getFileName = (file) => {
        if (file.document && file.document.original_name) {
            return file.document.original_name;
        } else if (file.name) {
            return file.name;
        }
        return 'File';
    };

    return (
        <div className="">
            <ul className='flex flex-row flex-wrap space-x-2'>
                {files.slice(0, 5).map((file, index) => (
                    <li key={index} className="relative group">
                        {file.type === 'image' && <button onClick={() => openExpandedImage(getFileUrl(file))}>
                            <img
                                src={getFileUrl(file)}
                                alt={`Gallery ${index}`}
                                className={classNames(imageSize, "rounded-xl shadow-md object-cover")}
                            />
                        </button>
                        }
                        {
                            file.type === 'spreadsheet' &&
                            <div className='bg-gray-600 shadow-md pl-2 pr-5 py-2 rounded-lg'>
                                <div className="flex items-center">
                                    <div className="bg-green-600 rounded-md p-2.5">
                                        <LuFileSpreadsheet className="h-7 w-7 text-white" />
                                    </div>
                                    <div className=" ml-2.5">
                                        <p className="text-base font-semibold text-white">{getFileName(file)}</p>
                                        <div className=" text-gray-200 text-sm">
                                            Spreadsheet
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                        {
                            file.type === 'powerpoint' &&
                            <div className='bg-gray-600 shadow-md pl-2 pr-5 py-2 rounded-lg'>
                                <div className="flex items-center">
                                    <div className="bg-red-600 rounded-md p-2.5">
                                        <LuFileSpreadsheet className="h-7 w-7 text-white" />
                                    </div>
                                    <div className=" ml-2.5">
                                        <p className="text-base font-semibold text-white">{getFileName(file)}</p>
                                        <div className=" text-gray-200 text-sm">
                                            Power Point
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                        {
                            file.type === 'document' &&
                            <div className='bg-gray-600 shadow-md pl-2 pr-5 py-2 rounded-lg'>
                                <div className="flex items-center">
                                    <div className="bg-blue-500 rounded-md p-2.5">
                                        <LuFileSpreadsheet className="h-7 w-7 text-white" />
                                    </div>
                                    <div className=" ml-2.5">
                                        <p className="text-base font-semibold text-white">{getFileName(file)}</p>
                                        <div className=" text-gray-200 text-sm">
                                            Document
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                        {
                            file.type === 'application/pdf' &&
                            <div className='bg-gray-600 shadow-md pl-2 pr-5 py-2 rounded-lg'>
                                <div className="flex items-center">
                                    <div className="bg-purple-600 rounded-md p-2.5">
                                        <LuFileSpreadsheet className="h-7 w-7 text-white" />
                                    </div>
                                    <div className=" ml-2.5">
                                        <p className="text-base font-semibold text-white">{getFileName(file)}</p>
                                        <div className=" text-gray-200 text-sm">
                                            PDF
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                        {removable && (
                            <button
                                onClick={() => removeFile(index)}
                                className="absolute -right-1 -top-1 bg-gray-700 text-white rounded-full h-5 w-5 hidden group-hover:flex justify-center items-center z-10"
                                style={{ fontSize: '0.75rem' }}
                            >
                                X
                            </button>
                        )}
                    </li>
                ))}

                {extraImagesCount > 0 && (
                    <li className="relative group">
                        <div
                            className={classNames(imageSize, "text-center rounded-xl shadow-md flex justify-center items-center bg-gray-300 text-xl text-gray-800")}
                        >
                            <p>+{extraImagesCount}</p>
                        </div>
                    </li>
                )}
            </ul>
        </div>
    );
}

export default ImageGallery;
