import React, { useState, useCallback, useEffect, useRef } from 'react'
import ImageGallery from '../ImageGallery'
import { HiOutlineStop } from 'react-icons/hi2'
import { IoMdSend } from 'react-icons/io'
import { classNames } from '../../../../misc/styling'
import { UploadIcon } from '@heroicons/react/outline'
import FileUploadButton from './FileUploadButton'
import PromptList from '../Prompts-List'
import ThinkingModeMenu from './ThinkingModeMenu'
import AtMentionSystem from './AtMentionSystem'
import useFileUpload from '../../../../fileManagement/hooks/useFileUpload'
import { API_AUTH_TYPES } from '../../../../../api'
import useArray from '../../../../../hooks/useArray'
import LoadingIndicator from '../LoadingIndicator'

const TextBox = ({ files, setFiles, query, setQuery, loading, handleStopGenerating, handleSubmit, textAreaRef, openExpandedImage, thinkingMode, setThinkingMode }) => {
    const [sendAnimation, setSendAnimation] = useState(false);
    const [textboxFocused, setTextboxFocused] = useState(false);
    const mentionedResources = useArray([])
    const handleInput = () => {
        const textAreaNode = textAreaRef.current;
        textAreaNode.style.height = "auto";
        textAreaNode.style.height = `${textAreaNode.scrollHeight}px`;
    };

    // Function to reset textarea height to default
    const resetTextAreaHeight = () => {
        if (textAreaRef.current) {
            textAreaRef.current.style.height = "auto";
        }
    };

    const [uploading, setUploading] = useState(false);
    const [currentFile, setCurrentFile] = useState(null);
    const [uploadQueue, setUploadQueue] = useState([]);

    // Helper function to get document type for the API
    const getDocumentType = (file) => {
        switch (file.type) {
            case 'application/pdf':
                return 'investments_screenshot';
            case 'image/jpeg':
            case 'image/png':
            case 'image/jpg':
                return 'investments_screenshot';
            default:
                return 'other';
        }
    };

    // Add image to state
    const addImage = useCallback((url) => {
        const urls = Array.isArray(url) ? url : [url];
        setFiles((currentFiles) => [...currentFiles, ...urls]);
    }, [setFiles]);

    // Handle file upload completion
    const handleUploadComplete = useCallback((uploadedFiles) => {
        uploadedFiles.forEach(file => {
            if (file.document.mime_type.startsWith('image/')) {
                // Pass the entire file object with document info
                addImage({
                    document: file.document,
                    type: 'image',
                    url: file.document.signed_url
                });
            } else if (file.document.mime_type === 'application/pdf') {
                // Handle PDFs as documents rather than images
                addImage({
                    document: file.document,
                    type: 'document',
                    url: file.document.signed_url
                });
            }
        });

        // Process next file in queue
        setUploadQueue(prev => {
            const newQueue = [...prev];
            newQueue.shift(); // Remove the file we just processed
            return newQueue;
        });
    }, [addImage]);

    // Setup useFileUpload hook with dynamic document type
    const { handleFiles: uploadFile } = useFileUpload({
        documentType: currentFile ? getDocumentType(currentFile) : 'investments_screenshot',
        onUploadComplete: handleUploadComplete,
        authType: API_AUTH_TYPES.USER,
        customFilePath: currentFile ? `parabot/${currentFile.name}` : null
    });

    // Process the upload queue
    useEffect(() => {
        const processQueue = async () => {
            if (uploadQueue.length > 0 && !currentFile) {
                const nextFile = uploadQueue[0];
                setCurrentFile(nextFile);

                try {
                    // Wait for state update to ensure customFilePath is set
                    await new Promise(resolve => setTimeout(resolve, 0));
                    await uploadFile([nextFile]);
                } catch (error) {
                    console.error('Error uploading file:', error);
                    // Remove failed file from queue and continue
                    setUploadQueue(prev => prev.slice(1));
                    setCurrentFile(null);
                }
            } else if (uploadQueue.length === 0 && uploading) {
                // All uploads complete
                setUploading(false);
            }
        };

        processQueue();
    }, [uploadQueue, currentFile, uploading, uploadFile]);

    // Reset currentFile after upload completes
    useEffect(() => {
        if (uploadQueue.length === 0) {
            setCurrentFile(null);
        }
    }, [uploadQueue]);

    // Handle file change from input or drag/drop
    const handleFileChange = async (files) => {
        if (files.length === 0) return;

        setUploading(true);

        const validFiles = Array.from(files).filter(file =>
            file.type.startsWith('image/') ||
            file.type === 'application/pdf' ||
            file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
            file.type === 'text/csv' ||
            file.type === 'application/vnd.openxmlformats-officedocument.presentationml.presentation' ||
            file.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ||
            file.type === 'text/plain'
        );

        if (validFiles.length === 0) {
            setUploading(false);
            return;
        }

        // Add files to upload queue
        setUploadQueue(prev => [...prev, ...validFiles]);
    };

    const handlePaste = (event) => {
        const items = (event.clipboardData || event.originalEvent.clipboardData).items;
        const files = [];
        for (const item of items) {
            if (item.type.indexOf("image") === 0) {
                files.push(item.getAsFile());
            }
        }
        if (files.length > 0) {
            handleFileChange(files);
        }
    };
    const [isDragActive, setIsDragActive] = useState(false);

    const handleDrop = (event) => {
        event.preventDefault();
        event.stopPropagation();
        setIsDragActive(false);  // Reset drag active when dropping
        const files = Array.from(event.dataTransfer.files).filter(file =>
            file.type.startsWith('image/') ||
            file.type === 'application/pdf' ||
            file.type === 'text/csv' ||
            file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
            file.type === 'application/vnd.openxmlformats-officedocument.presentationml.presentation' ||
            file.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ||
            file.type === 'text/plain'
        );
        if (files.length > 0) {
            handleFileChange(files);
        }
    };

    const handleDragOver = (event) => {
        event.preventDefault();
        event.stopPropagation();
        setIsDragActive(true);  // Set drag active when dragging over
    };

    const handleDragLeave = (event) => {
        event.preventDefault();
        event.stopPropagation();
        setIsDragActive(false);  // Reset drag active when dragging leaves
    };
    const removeFile = (index) => {
        setFiles((currentFiles) => currentFiles.filter((_, i) => i !== index));
    };

    const handleKeyPress = (event) => {
        if (event.key === "Enter" && !event.shiftKey) {
            event.preventDefault(); // Prevent default Enter behavior
            if (query.trim()) {
                triggerSendAnimation();
                let queryToSubmit = query
                if (mentionedResources.array.length > 0) {
                    queryToSubmit = `${query}\n\n Mentioned Resources: ${JSON.stringify(mentionedResources.array, null, 2)}`
                }
                handleSubmit(queryToSubmit);
                // Reset height after submission
                setTimeout(resetTextAreaHeight, 0);
            }
        }
    };

    // Trigger send animation
    const triggerSendAnimation = () => {
        setSendAnimation(true);
        setTimeout(() => setSendAnimation(false), 500);
    };

    // Modify the button click handler too for consistency
    const handleButtonSubmit = () => {
        if (query.trim()) {
            triggerSendAnimation();
            let queryToSubmit = query
            if (mentionedResources.array.length > 0) {
                queryToSubmit = `${query}\n\n Mentioned Resources: ${JSON.stringify(mentionedResources.array, null, 2)}`
            }
            handleSubmit(queryToSubmit);
            // Reset height after submission
            setTimeout(resetTextAreaHeight, 0);
        }
    };

    // Use ref for the container to position the mentions display above it
    const containerRef = useRef(null);

    // Handle sending a prompt - adds prompt to end of current query
    const handleSendPrompt = (prompt) => {
        // Add the prompt to the end of the current query with appropriate spacing
        const currentQuery = query.trim();
        const newQuery = currentQuery ? `${currentQuery}\n\n${prompt}` : prompt;

        // Apply the new query value
        setQuery(newQuery);

        // Focus the textarea and add visual feedback
        if (textAreaRef.current) {
            // Focus the textarea
            textAreaRef.current.focus();

            // Highlight the textbox to show something was added
            setTextboxFocused(true);

            // Schedule height adjustment and animation after state update
            setTimeout(() => {
                // Adjust height to fit content
                const textAreaNode = textAreaRef.current;
                textAreaNode.style.height = "auto";
                textAreaNode.style.height = `${textAreaNode.scrollHeight}px`;

                // Add a subtle animation effect for visual feedback
                setSendAnimation(true);
                setTimeout(() => setSendAnimation(false), 300);

                // Position cursor at the end of the text
                textAreaNode.selectionStart = textAreaNode.value.length;
                textAreaNode.selectionEnd = textAreaNode.value.length;
            }, 0);
        }
    };

    return (
        <div
            ref={containerRef}
            onDragOver={handleDragOver}
            onDrop={handleDrop}
            onDragLeave={handleDragLeave}
            className={classNames(
                "absolute bottom-0 left-1/2 -translate-x-1/2 -translate-y-4 w-11/12 sm:w-3/4",
                "bg-white border rounded-2xl mb-2 py-2 mx-auto px-4",
                "transition-all duration-200 ease-in-out",
                textboxFocused ? "border-gray-300 shadow-2xl" : "hover:border-gray-300 border-gray-200 shadow-sm",
                sendAnimation ? "scale-[0.98] opacity-95" : ""
            )}>
            <div className="absolute left-0 -top-10 flex items-center space-x-2">
                <PromptList handleSendPrompt={handleSendPrompt} />
                <AtMentionSystem
                    mentionedResources={mentionedResources}
                    query={query}
                    setQuery={setQuery}
                    textAreaRef={textAreaRef}
                />
                <LoadingIndicator isVisible={loading} />
            </div>

            {isDragActive && (
                <div className='flex ml-2 items-center'>
                    <UploadIcon className='text-gray-500 h-4 w-4' />
                    <p className='ml-2 text-gray-500'>
                        Drop files to upload
                    </p>
                </div>
            )}

            <div className={classNames(files?.length > 0 && 'pt-2', "md:flex space-x-2 hidden w-full px-2")}>
                <ImageGallery openExpandedImage={openExpandedImage} files={files} removeFile={removeFile} />
            </div>

            <div className="flex items-center space-x-2 relative">
                <div className="flex items-center space-x-1">
                    <FileUploadButton handleFileChange={handleFileChange} uploading={uploading} />
                    <ThinkingModeMenu
                        thinkingMode={thinkingMode}
                        setThinkingMode={setThinkingMode}
                    />
                </div>

                <div className="max-h-52 overflow-auto w-full custom-scrollbar-thick custom-scrollbar-gray relative">
                    <textarea
                        value={query}
                        onKeyDown={handleKeyPress}
                        ref={textAreaRef}
                        onInput={handleInput}
                        rows={1}
                        onPaste={handlePaste}
                        onFocus={() => setTextboxFocused(true)}
                        onBlur={() => setTextboxFocused(false)}
                        className="w-full border-none ring-0 focus:ring-0 rounded-lg resize-none overflow-hidden py-2"
                        placeholder="Message Parabot..."
                        onChange={(e) => setQuery(e.target.value)}
                    />
                </div>

                <div>
                    {loading ? (
                        <button
                            onClick={() => handleStopGenerating()}
                            className="p-1.5 rounded-full border-2 border-gray-400 hover:border-red-400 hover:bg-red-50 group transition-colors duration-150"
                        >
                            <HiOutlineStop className='h-5 w-5 text-gray-400 group-hover:text-red-500 transition-colors duration-150' />
                        </button>
                    ) : (
                        <button
                            disabled={uploading || !query.trim()}
                            onClick={handleButtonSubmit}
                            className={classNames(
                                'rounded-full p-1.5 transition-all duration-150',
                                query.trim() ? 'bg-blue-500 hover:bg-blue-600 shadow-sm' : 'bg-gray-100 cursor-not-allowed'
                            )}
                        >
                            <IoMdSend
                                className={classNames(
                                    "h-5 w-5 transition-all duration-150",
                                    query.trim() ? "text-white" : "text-gray-400",
                                    sendAnimation ? "scale-75 opacity-0" : ""
                                )}
                            />
                        </button>
                    )}
                </div>
            </div>
        </div>
    )
}

export default TextBox