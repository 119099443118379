import React from 'react';
import { HiLockClosed } from "react-icons/hi2";
import SideNavBar from '../../../components/nav/side-navbar';
import DashboardPanel from '../../Client-Dashboard/components/DashboardPanel';
import DocumentManager from '../../../components/fileManagement/components/DocumentManager';
import { API_AUTH_TYPES } from '../../../api';

const Documents = () => {
    return (
        <SideNavBar useContainer={false} bgColor="bg-gray-100">
            <div className="bg-white relative">
                {/* Hero Section */}
                <div className="relative overflow-hidden bg-gray-50 pt-16 pb-32">
                    {/* Background Pattern */}
                    <div className="absolute inset-0 bg-grid-gray-100 opacity-[0.2]" />

                    <div className="relative mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                        <div className="text-center flex flex-col items-center">
                            <div className="flex justify-center mb-8">
                                <div className="relative">
                                    <div className="absolute -inset-1 rounded-full bg-gradient-to-r from-blue-600 to-indigo-600 opacity-25 blur" />
                                    <div className="relative rounded-full bg-white p-4">
                                        <HiLockClosed className="h-12 w-12 text-blue-600" />
                                    </div>
                                </div>
                            </div>
                            <h1 className="uppercase mx-auto mb-4 max-w-2xl text-xl leading-8 text-gray-600">
                                Abundo Vault
                            </h1>
                            <p className="text-xl max-w-3xl text-center font-semibold tracking-tight text-gray-900 sm:text-2xl md:text-4xl">
                                Securely manage and share documents with your Abundo advisor.
                            </p>
                        </div>
                    </div>
                </div>

                {/* Document Manager Section */}
                <div className="relative z-20 mx-auto max-w-7xl sm:px-6 lg:px-8 -mt-16 pb-16">
                    <div className="bg-white sm:rounded-2xl border border-gray-200 sm:shadow-lg">
                        <div className="px-6 py-8">
                            <DocumentManager authType={API_AUTH_TYPES.USER} />
                        </div>
                    </div>
                </div>
            </div>
        </SideNavBar>
    );
};

export default Documents;