import React, { useEffect, useState } from 'react'
import { classNames } from '../../../../misc/styling'
import { API_AUTH_TYPES, API_ROUTES, ApiRequest } from '../../../../../api'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { PencilIcon, TrashIcon, ChatAlt2Icon } from '@heroicons/react/solid'
import ItemMenu from '../../../../menus/ItemMenu'
import DoubleClickEdit from '../../../../DoubleClickEdit'

const parabotApi = new ApiRequest(API_ROUTES.PARABOT, API_AUTH_TYPES.ADVISOR)
const ConversationItem = ({ id, setConversationId, conversationId, preloadedDetails, shouldFetch = false, hasUnread = false }) => {
    const queryClient = useQueryClient()
    const [showRename, setShowRename] = useState(false)
    const [editValue, setEditValue] = useState('')
    const [conversation, setConversation] = useState(preloadedDetails)
    const [isLoading, setIsLoading] = useState(!preloadedDetails && shouldFetch)
    const [isHovered, setIsHovered] = useState(false)

    // Only fetch details if they're not preloaded and shouldFetch is true
    const { data: fetchedConversation } = useQuery({
        queryFn: parabotApi.getFn({
            endpoint: 'conversation_details',
            params: [id],
        }),
        queryKey: [id],
        enabled: shouldFetch && !preloadedDetails,
        // Store conversation details in cache for 5 minutes
        staleTime: 5 * 60 * 1000,
    })

    // Update conversation state when preloaded details or fetched data changes
    useEffect(() => {
        if (preloadedDetails) {
            setConversation(preloadedDetails)
            setIsLoading(false)
        } else if (fetchedConversation) {
            setConversation(fetchedConversation)
            setIsLoading(false)
        } else if (shouldFetch && !fetchedConversation) {
            setIsLoading(true)
        }
    }, [preloadedDetails, fetchedConversation, shouldFetch])

    useEffect(() => {
        setEditValue(conversation?.name)
    }, [conversation])

    const { mutate: archiveConversation } = useMutation(() => parabotApi.patch({
        endpoint: 'archive_conversation',
        params: [id],
    }), {
        onSuccess: () => {
            if (id === conversationId) setConversationId(null)
            queryClient.invalidateQueries('parabot_conversation_ids')
        },
    })
    const { mutate: updateName } = useMutation(() => parabotApi.patch({
        endpoint: 'update_name',
        params: [id],
        body: {
            name: editValue,
        },
    }), {
        onSuccess: () => {
            queryClient.invalidateQueries(id)
        },
    })

    const menuOptions = [
        {
            title: 'Rename',
            action: () => setShowRename(true),
            icon: PencilIcon
        },
        {
            title: 'Delete',
            action: () => archiveConversation(),
            icon: TrashIcon
        },
    ]

    // Only render a placeholder while loading and not showing all conversations
    if (isLoading && conversationId !== id)
        return <div className="px-3 py-2 w-full h-10 animate-pulse bg-gray-200 rounded-lg mb-1"></div>

    // Don't render anything if we don't have data yet for non-active conversations
    if (!conversation && conversationId !== id) return null

    return (
        <button
            onClick={() => setConversationId(id)}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            className={classNames(
                'w-full px-3 py-2 relative flex items-center justify-between gap-2 rounded-lg transition-all duration-200',
                conversationId === id
                    ? 'bg-blue-100 text-blue-800 shadow-sm'
                    : 'text-gray-700 hover:bg-gray-100',
                hasUnread && conversationId !== id && 'bg-gray-50'
            )}
        >
            <div className="flex items-center space-x-2 min-w-0">
                {/* Chat icon */}
                <div className={classNames(
                    'flex-shrink-0 rounded-full p-1.5',
                    conversationId === id
                        ? 'bg-blue-200 text-blue-700'
                        : 'bg-gray-200 text-gray-600'
                )}>
                    <ChatAlt2Icon className="h-3 w-3" />
                </div>

                {/* Unread indicator + Name */}
                <div className="flex flex-col min-w-0 text-left">
                    <div className="flex items-center space-x-1">
                        {hasUnread && (
                            <span className="h-2 w-2 rounded-full bg-red-500 flex-shrink-0" aria-label="Unread messages"></span>
                        )}
                        <DoubleClickEdit
                            showEdit={showRename}
                            setShowEdit={setShowRename}
                            defaultComponent={
                                <p className={classNames(
                                    'text-sm font-medium truncate max-w-[150px] transition-colors duration-200',
                                    hasUnread && 'font-semibold'
                                )}>
                                    {conversation?.name}
                                </p>
                            }
                            editComponent={
                                <input
                                    type="text"
                                    className="input-primary text-sm py-1 px-2"
                                    value={editValue}
                                    onChange={(e) => setEditValue(e.target.value)}
                                />
                            }
                            handleSubmitEdit={() => updateName()}
                        />
                    </div>

                    {/* Optional timestamp - add if available */}
                    {conversation?.last_interaction && (
                        <p className="text-xs text-gray-500 truncate">
                            {new Date(conversation.last_interaction).toLocaleTimeString([], {
                                hour: '2-digit',
                                minute: '2-digit'
                            })}
                        </p>
                    )}
                </div>
            </div>

            <div className={classNames(
                'transition-opacity duration-200',
                (isHovered || conversationId === id) ? 'opacity-100' : 'opacity-0'
            )}>
                <ItemMenu options={menuOptions} />
            </div>
        </button>
    )
}

export default ConversationItem