import { API_ROUTES } from '../../../../../api';
import ApiRequest from '../../../../../api/ApiRequest';
import { API_AUTH_TYPES } from '../../../../../api';

// Define mention categories
export const MENTION_CATEGORIES = {
    HOUSEHOLD: 'households',
    RESOURCE: 'advisor_resources',
    PROMPT: 'parabot_prompt_templates',
    EMAIL: 'email_templates',
    CHAPTER: 'chapter_templates',
    TOOLS: 'tools',
    // You can add more categories here in the future
    // Example: USER: 'user',
    // Example: TASK: 'task',
};

// Category display information
export const CATEGORY_INFO = {
    [MENTION_CATEGORIES.HOUSEHOLD]: {
        label: 'Households',
        icon: 'H', // You could use actual icon components instead
        iconBgColor: 'bg-blue-100',
        iconTextColor: 'text-blue-800',
    },
    [MENTION_CATEGORIES.RESOURCE]: {
        label: 'Advisor Resources',
        icon: 'R',
        iconBgColor: 'bg-green-100',
        iconTextColor: 'text-green-800',
    },
    [MENTION_CATEGORIES.PROMPT]: {
        label: 'Parabot Prompt Templates',
        icon: 'P',
        iconBgColor: 'bg-purple-100',
        iconTextColor: 'text-purple-800',
    },
    [MENTION_CATEGORIES.EMAIL]: {
        label: 'Email Templates',
        icon: 'E',
        iconBgColor: 'bg-yellow-100',
        iconTextColor: 'text-yellow-800',
    },
    [MENTION_CATEGORIES.CHAPTER]: {
        label: 'Chapter Templates',
        icon: 'C',
        iconBgColor: 'bg-indigo-100',
        iconTextColor: 'text-indigo-800',
    },
    [MENTION_CATEGORIES.TOOLS]: {
        label: 'Tools',
        icon: 'T',
        iconBgColor: 'bg-red-100',
        iconTextColor: 'text-red-800',
    },
    // Add more categories with their display info here
    // Example:
    // [MENTION_CATEGORIES.USER]: {
    //   label: 'Users',
    //   icon: 'U',
    //   iconBgColor: 'bg-green-100',
    //   iconTextColor: 'text-green-800',
    //   fetchFunction: fetchUsers,
    // },
};

/**
 * Filter mention data by query and category
 * @param {Object} allData - All mention data organized by category
 * @param {string} query - Search query
 * @param {string|null} categoryType - Selected category type or null for all
 * @returns {Array} Filtered and sorted mention results
 */
export const filterMentionData = (allData, query, categoryType) => {
    let filteredResults = [];
    const trimmedQuery = query.trim().toLowerCase();

    if (categoryType) {
        // Only filter the selected category
        const categoryData = allData[categoryType] || [];

        // If there's no query and we have a category selected, 
        // just return all items from that category (up to a reasonable limit)
        if (!trimmedQuery) {
            return categoryData.slice(0, 50); // Return up to 50 items from the selected category
        }

        // Otherwise filter by search query
        filteredResults = categoryData.filter(item =>
            item.name.toLowerCase().includes(trimmedQuery)
        );
    } else {
        // Filter across all categories with the search query
        Object.values(allData).forEach(categoryItems => {
            const filtered = categoryItems.filter(item =>
                item.name.toLowerCase().includes(trimmedQuery)
            );
            filteredResults = [...filteredResults, ...filtered];
        });
    }

    // Sort results by relevance
    const sortedResults = filteredResults.sort((a, b) => {
        // Lower index = higher in the results
        const aStartsWithQuery = trimmedQuery && a.name.toLowerCase().startsWith(trimmedQuery) ? -1 : 0;
        const bStartsWithQuery = trimmedQuery && b.name.toLowerCase().startsWith(trimmedQuery) ? -1 : 0;

        return aStartsWithQuery - bStartsWithQuery || a.name.localeCompare(b.name);
    });

    // If empty query and no specific category selected, caller should handle this
    // by showing the category selection view instead. Return empty array.
    if (!trimmedQuery && !categoryType) {
        return [];
    }

    // If there's no specific query but we're showing all categories,
    // return a sample of items from each category
    if (!trimmedQuery) {
        const limitedResults = [];
        Object.values(allData).forEach(categoryItems => {
            limitedResults.push(...categoryItems.slice(0, 5));
        });
        return limitedResults;
    }

    return sortedResults;
};

/**
 * Create API request for @ component data
 * @returns {ApiRequest} API request object for @ component
 */
export const createAtComponentRequest = () => {
    return new ApiRequest(API_ROUTES.PARABOT, API_AUTH_TYPES.ADVISOR);
};

// Helper function to get all category fetch functions
export const getAllCategoryFetchFunctions = () => {
    return Object.values(CATEGORY_INFO).map(category => category.fetchFunction);
};

// Execute all category fetches
export const fetchAllCategoryResults = async (searchQuery, accessToken) => {
    const fetchFunctions = getAllCategoryFetchFunctions();
    const results = await Promise.all(
        fetchFunctions.map(fetchFn => fetchFn(searchQuery, accessToken))
    );

    // Flatten the results array
    return results.flat();
}; 