import { useEffect, useState } from "react";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { useMutation, useQueryClient } from "react-query";
import { BanIcon, ChevronRightIcon } from "@heroicons/react/outline";
import { classNames } from "../../../../helpers/classNames";
import { dateFormaterWithTime } from "../../../../helpers/formatters";
import ItemMenu from "../../../../components/menus/ItemMenu";
import { API_AUTH_TYPES, API_ROUTES, ApiRequest } from "../../../../api";
import MeetingDetails from "./MeetingDetails";
import Toggle from "../../../../components/toggle";

const meetingsApi = new ApiRequest(API_ROUTES.CLIENT_MEETINGS, API_AUTH_TYPES.ADVISOR);

export default function Row({ meeting }) {
    const { household_id: householdId } = useParams();

    // Hooks, state, and functions
    const [showDetails, setShowDetails] = useState(false);
    const [prepCompleted, setPrepCompleted] = useState(meeting.prep_completed || false);
    const queryClient = useQueryClient();

    const mutate = useMutation(
        (body) => {
            meetingsApi.patch({
                endpoint: "update_meeting_status",
                params: [meeting.meeting_id],
                body,
            });
        },
        {
            onSuccess: (data) => {
                setTimeout(() => {
                    queryClient.invalidateQueries(`meetings-${householdId}`);
                    queryClient.invalidateQueries(`meeting-${meeting.meeting_id}`);
                }, 1000);
            },
        }
    );

    const updatePrepStatus = useMutation(
        (isCompleted) => {
            return meetingsApi.patch({
                endpoint: "update_prep_completed_auth_advisor",
                params: [meeting.meeting_id],
                body: { prep_completed: isCompleted },
            });
        },
        {
            onSuccess: () => {
                setTimeout(() => {
                    queryClient.invalidateQueries(`meetings-${householdId}`);
                    queryClient.invalidateQueries(`meeting-${meeting.meeting_id}`);
                }, 1000);
            },
        }
    );

    useEffect(() => {
        setPrepCompleted(meeting.prep_completed || false);
    }, [meeting.prep_completed]);

    const handlePrepToggle = (isCompleted) => {
        setPrepCompleted(isCompleted);
        updatePrepStatus.mutate(isCompleted);
    };

    const meetingTypeColors = {
        active: "bg-green-100 text-green-700 ring-green-400",
        "no-show": "bg-yellow-100 text-yellow-700 ring-yellow-400",
        canceled: "bg-red-100 text-red-700 ring-red-400",
    };
    const colorClasses = meetingTypeColors[meeting?.status] || "bg-gray-100 text-gray-700 ring-gray-600";

    const options = [
        {
            title: "No Show",
            icon: BanIcon,
            action: () => mutate.mutate({ status: "no-show" }),
        },
    ];

    const handleRowClick = (e) => {
        // Make sure we don't trigger row click when clicking on menu or toggle
        if (e.target.closest('.menu-button') || e.target.closest('.toggle-container')) {
            return;
        }
        setShowDetails(true);
    };

    return (
        <>
            {/* Date column */}
            <td
                className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0 cursor-pointer hover:bg-gray-100 group transition-colors duration-150"
                onClick={handleRowClick}
            >
                <div className="flex items-center">
                    {dateFormaterWithTime(new Date(meeting.start_time))}
                    <ChevronRightIcon className="h-4 w-4 ml-1 text-gray-400 group-hover:text-gray-600 group-hover:translate-x-0.5 transition-transform duration-150" />
                </div>
            </td>

            {/* Meeting type column */}
            <td
                className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 hidden md:table-cell cursor-pointer hover:bg-gray-100 group transition-colors duration-150"
                onClick={handleRowClick}
            >
                {meeting.name}
            </td>

            {/* Meeting reason column */}
            <td
                className="px-3 py-4 text-sm text-gray-500 hidden lg:table-cell cursor-pointer hover:bg-gray-100 group transition-colors duration-150"
                onClick={handleRowClick}
            >
                {meeting.questions_and_answers ? meeting.questions_and_answers[0]?.answer : ""}
            </td>

            {/* Status column */}
            <td
                className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 cursor-pointer hover:bg-gray-100 group transition-colors duration-150"
                onClick={handleRowClick}
            >
                <span
                    className={classNames(
                        `inline-flex items-center rounded-full px-2 py-1 text-xs font-medium`,
                        colorClasses
                    )}
                >
                    {meeting.status === 'active' && 'Active'}
                    {meeting.status === 'no-show' && 'No Show'}
                    {meeting.status === 'canceled' && 'Canceled'}
                </span>
            </td>

            {/* Prep completed toggle column */}
            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                <div className="toggle-container flex items-center" onClick={e => e.stopPropagation()}>
                    <Toggle
                        enabled={prepCompleted}
                        setEnabled={handlePrepToggle}
                        color={prepCompleted ? "green" : "gray"}
                    />
                </div>
            </td>

            {/* Actions menu column */}
            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                <div className="menu-button">
                    <ItemMenu options={options} />
                </div>
            </td>

            {/* Meeting details modal */}
            {showDetails && (
                <MeetingDetails
                    meetingId={meeting.meeting_id}
                    onClose={() => setShowDetails(false)}
                />
            )}
        </>
    );
} 