import React, { useState } from 'react';
import { PaperClipIcon } from '@heroicons/react/outline';
import cloudinaryImageUpload from '../../../../../helpers/cloudinaryImageUpload';
import SmallLoadingSpinner from '../../../../loading/SmallLoadingSpinner';

function FileUploadButton({ handleFileChange, uploading }) {

    const handleFileChangeInput = (event) => {
        const files = Array.from(event.target.files);
        handleFileChange(files);
    };



    return (
        <div >
            <input
                type="file"
                multiple // Enable multiple file selection
                accept="image/*,application/pdf,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,text/csv"
                onChange={handleFileChangeInput}
                id="file-input"
                className="hidden"
            />

            <label htmlFor="file-input">
                <div className="cursor-pointer border border-gray-300 rounded-full p-2" disabled={uploading}>
                    {uploading ? <SmallLoadingSpinner textColor='text-gray-500' /> : <PaperClipIcon className='text-gray-500 h-6 w-6 ' />}
                </div>
            </label>
        </div>
    );
}

export default FileUploadButton;
