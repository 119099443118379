import React, { createContext, useContext, useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { authenticateAdvisorAdmin } from '../helpers/jwtAuth';

const AuthCheckContext = createContext(null);

export function useAuthCheck() {
    const context = useContext(AuthCheckContext);
    if (!context) {
        throw new Error('useAuthCheck must be used within an AuthCheckProvider');
    }
    return context;
}

export function AuthCheckProvider({ children }) {
    const [authState, setAuthState] = useState({
        isAdmin: false,
        isAdvisor: false,
        isManager: false,
        loading: true
    });

    const accessToken = useSelector((state) => state.user.accessToken);

    const authenticate = useCallback(async () => {
        if (!accessToken) {
            setAuthState(prev => ({ ...prev, loading: false }));
            return;
        }

        try {
            const auth = await authenticateAdvisorAdmin(accessToken);
            setAuthState({
                isAdmin: auth.is_admin,
                isAdvisor: auth.is_advisor,
                isManager: auth.is_manager,
                loading: false
            });
        } catch (error) {
            setAuthState({
                isAdmin: false,
                isAdvisor: false,
                isManager: false,
                loading: false
            });
        }
    }, [accessToken]);

    useEffect(() => {
        authenticate();
    }, [authenticate]);

    return (
        <AuthCheckContext.Provider value={authState}>
            {children}
        </AuthCheckContext.Provider>
    );
} 