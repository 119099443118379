import React from 'react';
import { AiOutlineLoading3Quarters } from 'react-icons/ai';
import { classNames } from '../../helpers/classNames';

const RouteLoadingSpinner = () => {
    return (
        <div className="flex h-screen w-full items-center justify-center bg-white">
            <div className="flex flex-col items-center space-y-4">
                <AiOutlineLoading3Quarters className="h-12 w-12 text-gray-300 animate-spin" />
                <span className="text-gray-600 font-medium">Loading...</span>
            </div>
        </div>
    );
};

export default RouteLoadingSpinner; 