import { Transition } from '@headlessui/react';
import React, { Fragment, useState, useEffect, useContext, useMemo, useCallback, useRef } from 'react';
import { classNames } from '../../../../helpers/classNames';
import ImageGallery from './ImageGallery';
import Markdown from 'markdown-to-jsx';
import ThinkingDisplay from './ThinkingDisplay';
import ToolUseDisplay from './ToolUseDisplay';

// Create a context for the socket to avoid prop drilling and unnecessary re-renders
export const SocketContext = React.createContext(null);

const Message = ({ messageId, index, openExpandedImage, initialData = null, currentAssistantMessageIdRef, isGrouped = false, isLastInGroup = false }) => {
    // Use a ref to track if the component is still mounted
    const isMounted = useRef(true);

    // Preserve initialData in a ref to compare against new values
    const initialDataRef = useRef(initialData);

    // Store our own copy of the message data to prevent losing it
    const messageDataRef = useRef(initialData || {
        role: "assistant",
        message: {
            content: [{ type: 'text', text: '' }]
        },
        text: '',
        thinking: null,
        toolUse: null,
        modelId: ''
    });

    // Local state for this specific message with stable initialization
    const [messageData, setMessageData] = useState(() => messageDataRef.current);

    // Track initialData changes and update state if needed
    useEffect(() => {
        // Only update if component is mounted and initialData has changed
        if (
            isMounted.current &&
            initialData &&
            JSON.stringify(initialData) !== JSON.stringify(initialDataRef.current)
        ) {
            console.log(`Message ${messageId}: Updating from initialData`, initialData);
            initialDataRef.current = initialData;
            messageDataRef.current = initialData;
            setMessageData(initialData);
        }
    }, [initialData, messageId]);

    // Track component mount/unmount
    useEffect(() => {
        // Set mounted flag to true
        isMounted.current = true;

        return () => {
            console.log(`Message ${messageId}: Unmounting`);
            isMounted.current = false;
        };
    }, [messageId]);

    const socket = useContext(SocketContext);
    const [downloading, setDownloading] = useState(false);

    // Extract data from the message state
    const { role } = messageData;
    console.log('messageData', messageData);

    // Get text content directly from messageData or extract from content if available
    const textContent = useMemo(() => {
        // First try to get directly from text property (new format)
        if (messageData.text) {
            return messageData.text;
        }

        // Fall back to content array if needed (old format)
        if (messageData.message?.content) {
            const content = messageData.message.content;
            if (Array.isArray(content)) {
                return content.find(item => item && item.type === 'text')?.text || '';
            }
        }

        return '';
    }, [messageData]);

    // Extract content array for further processing
    const content = useMemo(() => {
        if (messageData.message?.content) {
            return messageData.message.content;
        }
        return [];
    }, [messageData]);

    // Extract images from content if they exist
    const contentImages = useMemo(() => {
        if (!Array.isArray(content)) {
            return [];
        }

        const images = content.filter(item =>
            item && (item.type === "image" || item.type === "image_url" || item.type === "document")
        ).map(item => {
            // Handle both direct image objects and image_url objects
            if (item.type === "image") {
                return { url: item.source.url, type: 'image' };
            }

            return item;
        });

        console.log(`Message ${messageId}: Found ${images.length} images in content`);
        return images;
    }, [content, messageId]);

    // Get thinking data directly or from content
    const thinkingData = useMemo(() => {
        if (messageData.thinking) {
            return { raw: messageData.thinking };
        }

        if (Array.isArray(content)) {
            const thinkingContent = content.find(item => item && item.type === 'thinking');
            if (thinkingContent) {
                return thinkingContent;
            }
        }

        return null;
    }, [messageData, content]);

    // Get tool use data directly or from content
    const toolUses = useMemo(() => {
        // First check if we have the array of tool uses in the new format
        if (messageData.tool_uses && Array.isArray(messageData.tool_uses)) {
            return messageData.tool_uses;
        }

        // If no array but we have a single tool_use, create an array with it
        // Check if we have both tool_use and tool_result as direct properties (old format)
        if (messageData.tool_use) {
            const singleToolUse = {
                ...messageData.tool_use
            };

            // If there's a matching tool_result, add it to the tool_use object
            if (messageData.tool_result &&
                messageData.tool_result.tool_use_id === (singleToolUse.id || singleToolUse.tool_use_id)) {
                singleToolUse.result = messageData.tool_result;
            }

            return [singleToolUse];
        }

        // Handle legacy format
        if (messageData.toolUse) {
            return [messageData.toolUse];
        }

        // Try to find tool_use in content
        if (Array.isArray(content)) {
            const toolUseItems = content.filter(item => item && item.type === 'tool_use');

            // If no tool uses, return empty array
            if (!toolUseItems || toolUseItems.length === 0) return [];

            // Process each tool use and try to find matching results
            return toolUseItems.map(toolUseItem => {
                // Look for matching tool_result by tool_use_id
                const toolResultItem = content.find(
                    item => item &&
                        item.type === 'tool_result' &&
                        item.tool_use_id === toolUseItem.tool_use_id
                );

                // If we have both, combine them
                if (toolResultItem) {
                    return {
                        ...toolUseItem,
                        result: toolResultItem
                    };
                }

                return toolUseItem;
            });
        }

        return [];
    }, [messageData, content]);

    // For backward compatibility, extract the first tool use if any
    const toolUse = useMemo(() => {
        return toolUses.length > 0 ? toolUses[0] : null;
    }, [toolUses]);


    // Determine model ID from message data
    const modelId = messageData.modelId || '';

    console.log(`Message ${messageId}: Processed content:`, {
        textContent: textContent?.substring(0, 50) + (textContent?.length > 50 ? '...' : ''),
        hasToolUse: !!toolUse,
        thinkingData: thinkingData ? 'present' : 'absent',
        modelId
    });

    // Handle thinking data updates with useCallback for stability
    const handleThinking = useCallback((data) => {
        if (!currentAssistantMessageIdRef || currentAssistantMessageIdRef.current !== messageId) {
            console.log(`Message ${messageId}: Ignoring thinking data - not current message`);
            return;
        }

        // Only update if still mounted
        if (!isMounted.current) {
            console.log(`Message ${messageId}: Ignoring thinking data - component unmounted`);
            return;
        }


        // Use a function to update our state to ensure we always have the latest version
        setMessageData(prev => {
            // With new format, thinking is stored directly on the message
            const existingThinking = prev.thinking || "";

            const updatedThinking = existingThinking + (data.thinking?.raw || "");

            // Log updates during development
            console.log(`Message ${messageId}: Received thinking data`);

            // Create updated message
            const updatedMessage = {
                ...prev,
                thinking: updatedThinking,
                modelId: data.model || prev.modelId
            };

            // Update our ref too to ensure we don't lose data
            messageDataRef.current = updatedMessage;

            return updatedMessage;
        });
    }, [messageId, currentAssistantMessageIdRef]);

    // Handle completion data updates with useCallback for stability
    const handleCompletion = useCallback((newData) => {
        if (!currentAssistantMessageIdRef || currentAssistantMessageIdRef.current !== messageId) return;

        // Only update if still mounted
        if (!isMounted.current) return;

        // Use a function to update our state to ensure we always have the latest version
        setMessageData(prev => {
            // Only update if we are the current assistant message being streamed to
            if (prev.role !== 'assistant') return prev;
            console.log('prev', prev);

            // Get current text content - directly from text property in new format
            const currentText = prev.text || '';
            const updatedText = currentText + newData;

            // Log updates during development
            console.log(`Message ${messageId}: Received completion data, new length: ${updatedText.length}`);

            // Create updated message with new structure - text is directly on the message
            const updatedMessage = {
                ...prev,
                text: updatedText
            };

            // Update our ref too to ensure we don't lose data
            messageDataRef.current = updatedMessage;

            return updatedMessage;
        });
    }, [messageId, currentAssistantMessageIdRef]);

    // Add handleToolUse callback
    const handleToolUse = useCallback((data) => {
        if (!currentAssistantMessageIdRef || currentAssistantMessageIdRef.current !== messageId) return;

        // Only update if still mounted
        if (!isMounted.current) return;

        // Use a function to update our state to ensure we always have the latest version
        setMessageData(prev => {
            // Create new tool use object
            const newToolUse = {
                name: data.tool,
                input: data.arguments,
                id: data.id || `tool_${Date.now()}`,
                tool_use_id: data.id || `tool_${Date.now()}`
            };

            // Get existing tool uses or create empty array
            const existingToolUses = prev.tool_uses || [];

            // Create updated message with tool use data
            const updatedMessage = {
                ...prev,
                // Keep single tool_use for backward compatibility
                toolUse: newToolUse,
                // Add to array of tool uses
                tool_uses: [...existingToolUses, newToolUse]
            };

            // Update our ref too to ensure we don't lose data
            messageDataRef.current = updatedMessage;

            return updatedMessage;
        });
    }, [messageId, currentAssistantMessageIdRef]);

    // Add handler for tool result events
    const handleToolResult = useCallback((data) => {
        if (!currentAssistantMessageIdRef || currentAssistantMessageIdRef.current !== messageId) return;

        // Only update if still mounted
        if (!isMounted.current) return;

        // Use a function to update our state to ensure we always have the latest version
        setMessageData(prev => {
            // Get the tool use ID from the data
            const toolUseId = data.tool_use_id || data.id;
            const content = data.result || data.content || '';

            // Create a properly formatted tool_result object
            const toolResult = {
                content,
                type: "tool_result",
                tool_use_id: toolUseId
            };

            // If we have tool_uses array
            if (prev.tool_uses && Array.isArray(prev.tool_uses)) {
                // Find the matching tool use
                const updatedToolUses = [...prev.tool_uses];
                const toolUseIndex = updatedToolUses.findIndex(
                    tu => (tu.id === toolUseId || tu.tool_use_id === toolUseId)
                );

                if (toolUseIndex !== -1) {
                    // Update the specific tool use
                    updatedToolUses[toolUseIndex] = {
                        ...updatedToolUses[toolUseIndex],
                        result: toolResult
                    };

                    // Create updated message
                    const updatedMessage = {
                        ...prev,
                        tool_uses: updatedToolUses,
                        // Update single tool_use if it matches
                        tool_use: prev.tool_use?.id === toolUseId
                            ? { ...prev.tool_use, result: toolResult }
                            : prev.tool_use,
                        // Update tool_result if it matches
                        tool_result: prev.tool_use?.id === toolUseId
                            ? toolResult
                            : prev.tool_result
                    };

                    // Update ref and return updated message
                    messageDataRef.current = updatedMessage;
                    return updatedMessage;
                }
            }

            // Fallback to single tool_use approach
            if (prev.tool_use && (prev.tool_use.id === toolUseId || prev.tool_use.tool_use_id === toolUseId)) {
                // Create updated message
                const updatedMessage = {
                    ...prev,
                    tool_use: { ...prev.tool_use, result: toolResult },
                    tool_result: toolResult
                };

                // Update ref and return updated message
                messageDataRef.current = updatedMessage;
                return updatedMessage;
            }

            // No matching tool use found, return unchanged
            return prev;
        });
    }, [messageId, currentAssistantMessageIdRef]);

    // Listen for updates to this specific message
    useEffect(() => {
        if (!socket) return;

        console.log(`Message ${messageId}: Attaching socket listeners`);

        // Set up event listeners
        socket.on("completion_data", handleCompletion);
        socket.on("ai_thinking", handleThinking);
        socket.on("tool_call", handleToolUse);
        socket.on("tool_result", handleToolResult);

        // Clean up event listeners
        return () => {
            console.log(`Message ${messageId}: Cleaning up socket listeners`);
            socket.off("completion_data", handleCompletion);
            socket.off("ai_thinking", handleThinking);
            socket.off("tool_call", handleToolUse);
            socket.off("tool_result", handleToolResult);
        };
    }, [socket, handleCompletion, handleThinking, handleToolUse, handleToolResult, messageId]);


    // If message is from the currently streaming assistant, add a class
    const isCurrentStreamingMessage = messageId === currentAssistantMessageIdRef?.current;

    // Function to handle thinking display toggle
    const handleThinkingToggle = useCallback((isExpanded) => {
        // If thinking is expanded, we might need to adjust scroll
        if (isExpanded) {
            // Schedule a scroll check after content expands
            setTimeout(() => {
                try {
                    const scrollable = document.querySelector('[data-scroll-container]');
                    if (scrollable) {
                        // Check if user is already near bottom
                        const { scrollTop, scrollHeight, clientHeight } = scrollable;
                        const nearBottom = scrollHeight - scrollTop - clientHeight < 200;

                        if (nearBottom) {
                            // Force scroll to bottom
                            scrollable.scrollTop = scrollable.scrollHeight;

                            // Additional delayed scroll for safety
                            setTimeout(() => {
                                if (scrollable) {
                                    scrollable.scrollTop = scrollable.scrollHeight;
                                }
                            }, 50);
                        }
                    }
                } catch (error) {
                    console.log('Scroll error in Message thinking toggle:', error);
                }
            }, 150);
        }
    }, []);

    // Track content changes and automatically scroll when appropriate
    useEffect(() => {
        if (isMounted.current && role === 'assistant' && isCurrentStreamingMessage) {
            // This is a streaming message that updated, trigger a scroll
            try {
                const scrollable = document.querySelector('[data-scroll-container]');
                if (scrollable) {
                    // Check if user is already near bottom
                    const { scrollTop, scrollHeight, clientHeight } = scrollable;
                    const nearBottom = scrollHeight - scrollTop - clientHeight < 100;

                    if (nearBottom) {
                        // User is near bottom, scroll to bottom
                        scrollable.scrollTop = scrollable.scrollHeight;
                    }
                }
            } catch (error) {
                console.log('Scroll error:', error);
            }
        }
    }, [textContent, isCurrentStreamingMessage, role]);

    return (
        <Transition
            as={Fragment}
            show={true}
            enter="transition ease-out duration-300"
            enterFrom="transform opacity-0 translate-y-4"
            enterTo="transform opacity-100 translate-y-0"
            leave="transition ease-in duration-200"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
        >
            <li
                className={classNames(
                    "flex flex-col",
                    // Only apply the role-based alignment if not in a group
                    !isGrouped && role === "user" && "items-end",
                    !isGrouped && role === "assistant" && "items-start",
                    isCurrentStreamingMessage && "message-streaming",
                    // Added space for grouped messages
                    isGrouped && "mb-1"
                )}
            >
                {contentImages.length > 0 && (
                    <div className="p-2 flex justify-end">
                        <ImageGallery
                            imageSize='h-16 w-16'
                            files={contentImages}
                            removable={false}
                            openExpandedImage={openExpandedImage}
                        />
                    </div>
                )}



                {/* Only display thinking data if it has actual content */}
                {role === "assistant" && thinkingData &&
                    (typeof thinkingData === 'string' ? thinkingData.trim() : thinkingData.raw?.trim()) && (
                        <ThinkingDisplay
                            thinking={thinkingData}
                            modelId={modelId}
                            onToggle={handleThinkingToggle}
                        />
                    )}

                <div className="flex space-x-1 markdown-parabot">
                    <Transition
                        as="div"
                        appear={true}
                        show={true}
                        enter="transition-all ease-out duration-300"
                        enterFrom={role === "user" ? "opacity-0 translate-x-4" : "opacity-0 -translate-x-4"}
                        enterTo="opacity-100 translate-x-0"
                        className={classNames(
                            role === "user"
                                ? "bg-blue-600 text-white ml-20 px-4 py-2 shadow-md rounded-bl-3xl rounded-tr-3xl rounded-tl-xl rounded-br-xl"
                                : " text-gray-800",
                            "max-w-3xl custom-scrollbar-thick custom-scrollbar-gray overflow-x-auto markdown-chat text-lg  font-[400]"
                        )}
                    >
                        {textContent ? (
                            <Markdown options={{ wrapper: "" }}>
                                {textContent}
                            </Markdown>
                        ) : (
                            <></>
                        )}
                    </Transition>


                </div>
                {role === "assistant" && toolUses.length > 0 && (
                    <div className="space-y-2 mt-1">
                        {toolUses.map((toolUse, index) => (
                            <ToolUseDisplay
                                key={toolUse.id || `tool-use-${index}`}
                                toolUse={toolUse}
                                onToggle={handleThinkingToggle}
                            />
                        ))}
                    </div>
                )}
            </li>
        </Transition>
    );
};

// Use React.memo to avoid unnecessary re-renders
export default React.memo(Message, (prevProps, nextProps) => {
    // Only re-render if messageId or initialData changed
    return (
        prevProps.messageId === nextProps.messageId &&
        JSON.stringify(prevProps.initialData) === JSON.stringify(nextProps.initialData) &&
        prevProps.isGrouped === nextProps.isGrouped &&
        prevProps.isLastInGroup === nextProps.isLastInGroup
    );
});
