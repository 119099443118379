import React, { useState, useEffect, useRef } from 'react'
import { classNames } from '../../../../misc/styling'
import { API_AUTH_TYPES, API_ROUTES, ApiRequest } from '../../../../../api'
import { useQuery, useQueries } from 'react-query'
import NewConversation from './NewConversation'
import ConversationItem from './ConversationItem'
import FadeIn from '../../../../transitions/FadeIn'
import { AiOutlineLoading3Quarters } from 'react-icons/ai';
import { ChevronDoubleRightIcon, ChevronDoubleLeftIcon } from '@heroicons/react/outline';
import { ChatAlt2Icon } from '@heroicons/react/outline';

const INITIAL_CONVERSATIONS_COUNT = 7;

const parabotApi = new ApiRequest(API_ROUTES.PARABOT, API_AUTH_TYPES.ADVISOR)
const Conversations = ({ conversationId, setConversationId, handleClearConversation, conversationIds, unreadConversations = [] }) => {
    const [expanded, setExpanded] = useState(false)  // Initialize with false for default collapsed state
    const [visibleConversations, setVisibleConversations] = useState([])
    const [displayLimit, setDisplayLimit] = useState(INITIAL_CONVERSATIONS_COUNT);
    const [isLoadingMore, setIsLoadingMore] = useState(false);

    // Reference to the conversation list container for detecting visibility
    const listContainerRef = useRef(null)

    // Update visible conversations whenever the conversation IDs or display limit changes
    useEffect(() => {
        if (conversationIds && conversationIds.length > 0) {
            // First ensure the active conversation is included
            let visibleIds = [];

            // Include all conversations up to the display limit
            visibleIds = conversationIds.slice(0, displayLimit);

            // If active conversation exists and isn't already included, add it
            if (conversationId && !visibleIds.includes(conversationId)) {
                visibleIds.push(conversationId);
            }

            // Also ensure unread conversations are visible (up to a reasonable limit)
            const additionalUnreadToShow = unreadConversations
                .filter(id => !visibleIds.includes(id))
                .slice(0, 3); // Show up to 3 additional unread conversations

            setVisibleConversations([...visibleIds, ...additionalUnreadToShow]);
        } else {
            setVisibleConversations([]);
        }

        // Turn off loading state once conversations are updated
        setIsLoadingMore(false);
    }, [conversationIds, displayLimit, conversationId, unreadConversations]);

    // Batch fetch conversation details only when expanded
    const conversationQueries = useQueries(
        expanded ? (visibleConversations || []).map((id) => ({
            queryKey: ['conversation_details', id],
            queryFn: parabotApi.getFn({
                endpoint: 'conversation_details',
                params: [id],
            }),
            enabled: true,
        })) : []
    )

    // Create a map of fetched conversation details for easy access
    const conversationDetailsMap = {}
    if (expanded && conversationQueries) {
        conversationQueries.forEach((query, index) => {
            if (query.data && visibleConversations && index < visibleConversations.length) {
                conversationDetailsMap[visibleConversations[index]] = query.data
            }
        })
    }

    const handleMouseEnter = () => {
        setExpanded(true)
    }

    const handleMouseLeave = () => {
        setExpanded(false)
    }

    const loadMoreConversations = () => {
        setIsLoadingMore(true);
        setDisplayLimit(prevLimit => prevLimit + INITIAL_CONVERSATIONS_COUNT);
    }

    // Check if there are more conversations to load
    const hasMoreConversations = conversationIds.length > visibleConversations.length;

    // Group conversations by date (placeholder function - actual implementation would parse dates)
    const groupConversationsByDate = () => {
        // For demonstration, just splitting them into two groups
        const today = visibleConversations.slice(0, Math.ceil(visibleConversations.length / 2));
        const earlier = visibleConversations.slice(Math.ceil(visibleConversations.length / 2));

        return {
            'Today': today,
            'Earlier': earlier
        };
    };

    const groupedConversations = groupConversationsByDate();

    // Handle showing collapsed conversations
    const renderCollapsedConversations = () => {
        // Limit to first 8 when collapsed
        const maxCollapsedConversations = visibleConversations.slice(0, 8);

        return (
            <div className="mt-4 flex flex-col items-center space-y-2">
                {maxCollapsedConversations.map((id) => (
                    <button
                        key={id}
                        onClick={() => setConversationId(id)}
                        className={classNames(
                            'w-10 h-10 rounded-full flex items-center justify-center relative transition-all duration-200',
                            conversationId === id
                                ? 'bg-blue-100 text-blue-700'
                                : 'bg-gray-100 hover:bg-gray-200 text-gray-700'
                        )}
                    >
                        <ChatAlt2Icon className="h-4 w-4" />

                        {/* Unread indicator */}
                        {hasUnread(id) && (
                            <span
                                className="absolute top-0 right-0 h-2.5 w-2.5 rounded-full bg-red-500"
                                aria-label="Unread messages"
                            />
                        )}
                    </button>
                ))}
            </div>
        );
    }

    // Helper to check if a conversation has unread messages
    const hasUnread = (id) => unreadConversations.includes(id);

    return (
        <div
            className={classNames(
                'transition-all duration-300 ease-in-out bg-gray-50 rounded-bl-xl shadow-inner border-r border-gray-200',
                expanded ? 'w-64' : 'w-16'
            )}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
        >
            <div className="sticky top-0 z-10 bg-gray-50 rounded-tl-xl border-b border-gray-200 p-2 flex items-center justify-between">
                <h3 className={classNames(
                    "text-sm font-semibold text-gray-700 truncate transition-opacity duration-300",
                    expanded ? "opacity-100" : "opacity-0"
                )}>
                    Conversations
                </h3>
                <button
                    className="text-gray-500 hover:text-gray-700 transition-all duration-200 p-1 rounded-full hover:bg-gray-200"
                    onClick={() => setExpanded(!expanded)}
                >
                    {expanded ?
                        <ChevronDoubleLeftIcon className="h-4 w-4" /> :
                        <ChevronDoubleRightIcon className="h-4 w-4" />
                    }
                </button>
            </div>

            <div className="p-2">
                <NewConversation expanded={expanded} setConversationId={setConversationId} handleClearConversation={handleClearConversation} />
            </div>

            <div
                ref={listContainerRef}
                className="overflow-y-auto overflow-x-hidden h-[calc(70vh-4rem)] custom-scrollbar-thick custom-scrollbar-gray px-2"
            >
                {expanded ? (
                    <FadeIn durationExit='duration-0' isShowing={expanded}>
                        {Object.entries(groupedConversations).map(([dateGroup, ids]) => (
                            ids.length > 0 && (
                                <div key={dateGroup} className="mb-4">
                                    <h4 className="text-xs font-medium uppercase text-gray-500 mb-2 px-2">{dateGroup}</h4>
                                    <ul className="space-y-1">
                                        {ids.map((id) => (
                                            <li key={id}>
                                                <ConversationItem
                                                    id={id}
                                                    conversationId={conversationId}
                                                    setConversationId={setConversationId}
                                                    preloadedDetails={expanded ? conversationDetailsMap[id] : null}
                                                    shouldFetch={expanded || id === conversationId}
                                                    hasUnread={unreadConversations.includes(id)}
                                                />
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            )
                        ))}

                        {hasMoreConversations && expanded && (
                            <div className="py-3 px-2">
                                <button
                                    onClick={loadMoreConversations}
                                    disabled={isLoadingMore}
                                    className="w-full px-2 py-2 text-sm text-center font-medium text-blue-600 bg-blue-50 hover:bg-blue-100 rounded-lg transition-colors flex items-center justify-center shadow-sm"
                                >
                                    {isLoadingMore ? (
                                        <>
                                            <AiOutlineLoading3Quarters className="animate-spin mr-2 h-3 w-3" />
                                            Loading...
                                        </>
                                    ) : (
                                        'Load More'
                                    )}
                                </button>
                            </div>
                        )}
                    </FadeIn>
                ) : (
                    /* Collapsed view - just show icons */
                    renderCollapsedConversations()
                )}
            </div>
        </div>
    )
}

export default Conversations
